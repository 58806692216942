
export const ScheduleSummary: TableColumn[] = [
    {
        Header: "Area",
        accessor: "Area",
      },
      {
        Header: "Location",
        accessor: "Location",
      },
      {
        Header: "Activity Type",
        accessor: "Activity Type",
      },
      {
        Header: "Operator",
        accessor: "Operator",
      },
      {
        Header: "Microbiologist",
        accessor: "Microbiologist",
      },
      {
        Header: "Date & Time",
        accessor: "Date & Time",
      },
      {
        Header: "Actual Schedule",
        accessor: "Actual Schedule",
      },
      {
        Header: "Expected Schedule",
        accessor: "Expected Schedule",
      }
]



export const DiscrepancySummary: TableColumn[] = [
      {
        Header: "Area",
        accessor: "Area",
      },
      {
        Header: "Location",
        accessor: "Location",
      },
      {
        Header: "Activity Type",
        accessor: "Activity Type",
      },
      {
        Header: "Operator",
        accessor: "Operator",
      },
      {
        Header: "Microbiologist",
        accessor: "Microbiologist",
      },
      {
        Header: "Date & Time",
        accessor: "Date & Time",
      },
      {
        Header: "Discrepancy Type",
        accessor: "Discrepancy Type",
      },
      {
        Header: "Discrepancy Reason",
        accessor: "Discrepancy Reason",
      },
      {
        Header: "Corrective Action",
        accessor: "Corrective Action",
      }
]


export const ExceptionSummary: TableColumn[] = [
      {
        Header: "Area",
        accessor: "Area",
      },
      {
        Header: "Location",
        accessor: "Location",
      },
      {
        Header: "Activity Type",
        accessor: "Activity Type",
      },
      {
        Header: "Operator",
        accessor: "Operator",
      },
      {
        Header: "Microbiologist",
        accessor: "Microbiologist",
      },
      {
        Header: "Date & Time",
        accessor: "Date & Time",
      },
      {
        Header: "Exception Type",
        accessor: "Exception Type",
      },
      {
        Header: "Exception Reasons",
        accessor: "Exception Reasons",
      },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }
  