
//column heads for audit log list
export const columns: TableColumn[] = [
    {
        Header: "auditTrialReport.tableColumns.user",
        accessor: "user",
      },
      {
        Header: "auditTrialReport.tableColumns.dateAndTime",
        accessor: "dateAndTime",
      },
      {
        Header: "auditTrialReport.tableColumns.event",
        accessor: "typeOfOperation",
      },
      {
        Header: "auditTrialReport.tableColumns.guiName",
        accessor: "apiGuiName",
      },
      {
        Header: "auditTrialReport.tableColumns.reason",
        accessor: "reason",
      },
      
]


interface TableColumn {
    Header: string;
    accessor: string;
  }