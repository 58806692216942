import React, { useEffect, useState } from 'react'
import { IncidentOrDeviationApi } from '../../../service/deviation';
import { areaService } from '../../../service/areaServices';
import HeaderSection from '../../common/headerSection';
import PageHeader from '../../common/pageHeader';
import CustomTable from '../../../widgets/table';
import Pagination from '../../../widgets/pagination';
import { useTranslation } from 'react-i18next';
import baseUrl from '../../../service';
import {userService as userRoleServive} from '../../../service/userService';
import { equipments } from '../../../service/equipments';

interface TableColumn {
    Header: string;
    accessor: string;
}

export const columns: TableColumn[] = [
    {
        Header: "Deviation Id",
        accessor: "slno",
      },
      {
        Header: "Area Code ",
        accessor: "area_code",
      },
      {
        Header: "Area / Equipment Name",
        accessor: "equipment_name",
      },
      {
        Header: "Activity / Process ",
        accessor: "process",
      },
      {
        Header: "Operator Name",
        accessor: "operator_name",
      },
      {
        Header: "Operator ID",
        accessor: "operator_id",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Reason ",
        accessor: "reason",
      },
      {
        Header: "exceptionHandling.tableColumns.status",
        accessor: "status",
      },
     
  ]
const DeviationReport = () => {

    const { t } = useTranslation()
    const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(5)
    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");
    const [reasonId, setReasonId] = useState("");
    const [locationType, setLocationType] = useState("");
    const [keyWord, setkeyWord] = useState("")
    const [useEffectRequired, setUseEffectRequired] = useState(false);

    const [showDownloadCSV, setShowDownloadCSV] = useState(true);
    const [isApplyClicked, setIsApplyClicked] = useState(true);
    const [addFilterData, setAddFilterData] = useState({
        area: "",     
        equipment : "",
        operator:"",
        fromDate: "",
        toDate: "",
      });
    const [areas, setAreas] = useState<Array<any>>([]);
    const [userData , setUserData] = useState<Array<any>>([]); 
    const [equipmentList, setEquipmentList] = useState<Array<any>>([]); 

//api call to list exception plate detail in request tab 
const getExceptionPlates = async () => {
try {
    const response = await IncidentOrDeviationApi.getIncidentList(page, pageSize,sort, order, locationType, reasonId, keyWord, "requested,proceeded,stopped");
    if(response.status && response.statusCode === 200){
    try {
        if ( Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {

            const statusNumber = parseInt(element.location_type, 10); 
            const area_type = areaService.getLocationStatus(statusNumber);
            return { 
                slno: "V" + element.id, 
                area_code: element.area_code,
                equipment_name: element.area_name,
                process: area_type,
                
                operator_name : element.operator_name,
                operator_id : element.operator_id,
                date : element.created_at,
                reason: element.reason,
                status: element.deviation_status,
                id : element.id
            };
            });
            setPlateDetails(rowData);
        }
        } catch (error) {
        console.log(error); 
        }
    } else {
    setPlateDetails([])
    }
} catch (error) {
    console.error('Plate response', error);
}
};

const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

    useEffect(() => {
        getExceptionPlates();
        setUseEffectRequired(false)
    }, [useEffectRequired])

    const handleApplyClick =() =>{
        setShowDownloadCSV(false)
        setIsApplyClicked(true)
        // listAuditTrialReport();
    }

    const handleFilterDataChange = (e : any) => {
        setShowDownloadCSV(true)
        setIsApplyClicked(false)
        const { name, value } = e.target;
        setAddFilterData((prevFormData : any) => ({
            ...prevFormData, 
            [name]: value,
        }));
    };

    const handleCancel = () => {
        const initialFormData = {
        area: "",
        equipment : "",
        operator:"",
        fromDate: "",
        toDate: "",
        };
        setAddFilterData(initialFormData);
        setUseEffectRequired(true)
        // listAuditTrialReport();
    }
    
    const downloadAuditCSV = async () => {
        const link = `${baseUrl}/summary/deviation?area_code=${addFilterData.area}&equipment_id=${addFilterData.equipment}&operator_id=${addFilterData.operator}&from_date=${addFilterData.fromDate}&to_date=${addFilterData.toDate}`
        window.location.href = link;
    };


    const geAreaTypeList = async () => {
      try {  
        const response = await areaService.getParentAreaList([11,10,13,15]);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {
    
                  return { 
                      slno: index + 1, 
                      policy_id : element.policy_id,
                      id : element.id,
                      title: element.name,
                  };
                });
                setAreas(rowData);
              }
            } catch (error) {
              console.log(error);
            }
        }
      } catch (error) {
        console.error('user response', error);
      }
    }
      // function to get operator api list
    const getOperatorList = async () => {
      try {
        // const userRole = parseInt(addFilterData.role, 10);  
        const response = await userRoleServive.getUserNameList(1);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                // setTotalPages(response?.totalPages)
                // setTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      slno: index + 1, 
                      id: element.id,
                      name: `${element.first_name} ${element.last_name}`,
                  };
                });
                setUserData(rowData);
              }
            } catch (error) {
              console.log(error);
            }
        } else {
          setUserData([]);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    const listEquipments = async () => {
      try {
        const response = await equipments.listEquipmentFilter("");
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages)
                setTotalElements(response?.totalElements)
                console.log(response.data, "data---")
                let rowData: any = response.data.map((element: any, index: number) => {
  
                  return { 
                      slno: index + 1, 
                      id : element.id,
                      name: element.name,
                  };
                });
                setEquipmentList(rowData);
              }
            } catch (error) {
              console.log(error);
            }
        }else{
          setEquipmentList([]);
        }
      } catch (error) {
        console.error('user response', error);
      }
    }
   
    useEffect(() =>{
      geAreaTypeList();
      getOperatorList();
      listEquipments();
    },[])

  return (
    <div>
       <HeaderSection />
       <PageHeader pageHeader="Deviation / Incident Reporting" />

       <div className="d-flex justify-content-around my-4">

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Location:</label>
        <select name="area" className='areaTypeSelect' value={addFilterData.area}  onChange={handleFilterDataChange}>
        <option value="">Select Location</option>
              {areas?.map((obj, index) => (
                <option key={index} value={obj?.id}>
                  {obj?.title}
                </option>
              ))}
        </select>
        </div>


        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Equipment:</label>
        <select className='areaTypeSelect' name="equipment" value={addFilterData.equipment}  onChange={handleFilterDataChange} >
            <option value="">Select</option>
            {equipmentList.map((type) => (
            <option key={type.id} value={type.id}>
                {type.name}
            </option>
            ))}
        </select>
        </div>

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Operator:</label>
        <select name="operator" className='areaTypeSelect' value={addFilterData.operator}  onChange={handleFilterDataChange}  >
            <option value="">Select</option>
            {userData.map((type, index) => (
                        <option key={index} value={type?.id}>
                          {type?.name}
                        </option>
            ))}
        </select>
        </div>

        <div className="d-flex" style={{marginRight: "4%"}} >

        <div className="select-container auditTrialReportDate " 
            style={{display: "flex", flexDirection: "column", marginRight:"15%"}}>
            <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t("common.from_date")}:</label>
            <input
            id="selectedDate"
            className="form-control"
            name="fromDate"
            type="datetime-local"
            value={addFilterData.fromDate}  
            onChange={handleFilterDataChange} 
        />
        </div>

        <div className="select-container auditTrialReportDate" 
            style={{display: "flex", flexDirection: "column"}}>
            <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t("common.to_date")}:</label>
            <input
            id="selectedDate"
            className="form-control"
            name="toDate"
            type="datetime-local"
            value={addFilterData.toDate}  
            onChange={handleFilterDataChange}
            />
        </div>
        </div>
        </div>

        <div className="mt-3 mx-2 d-flex justify-content-end" >
            <button className="analyticalFilterClose" title="Click here to Reset Filter"  onClick={handleCancel}>{t("buttons.cancel")}</button>
            <button 
            className={isApplyClicked ? "analyticalFilterApplyDisabledDownload" : "analyticalFilterApply"}
            disabled={isApplyClicked} 
            onClick={handleApplyClick} 
            title="Click here to Apply Filter" >
                {t("buttons.apply")}
            </button>
            <button 
            className={showDownloadCSV ? "analyticalFilterDisabledDownload" : "analyticalFilterDownload"}
            disabled={showDownloadCSV} 
            onClick={downloadAuditCSV}
            title="Click here to Download CSV" >
            {t("buttons.download_pdf")}
            </button>

        </div>



       <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
        <CustomTable
        data={plateDetails}
        columns={columns}
        isEditable={false}
        totalElements={totalElements}
        isActionVisible={true}
        isViewVisible={false}
        isSeachable={false}
        tableHead={'Incident / Deviation'}
        showBatchFilter={false}
      />
    </div>
    <div>
      <Pagination
        page={page}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
    </div>
  )
}

export default DeviationReport
