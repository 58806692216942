export const columns : any = [
    {
      Header: 'tableCommon.sl_no',
      accessor: 'slno',
    },
    {
      Header: 'SOP Name',
      accessor: 'caption',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
]