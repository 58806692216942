import React,{useState, useEffect} from 'react'
import Select from 'react-select';
import { Column } from 'react-table';
import {userService as userRelatedService}  from '../../../service/userService';
import { userService as plateRelatedService } from '../../../service/plateServices';
import { color } from 'html2canvas/dist/types/css/types/color';
import { rawMaterials } from '../../../service/rawMaterials';
import { equipments } from '../../../service/equipments';




interface FilterSecProps {
    columns: Column[]; // Define the expected type of columns
    handleRoleChange: (columnName: string, value: any) => void;
    plateBatch?: any[];
    areaList?: any[];
    ReasonList?:any[];
    plateSerialNumber?: any[];
    lotCodeNumber?: any[];
    areaListNumber?:any[];
    showBatchFilter?: boolean;
    showSerielNoFilter?: boolean;
    showLotCodeFilter?:boolean;
    showPlateStatus?: boolean;
    showPlateTypeFilter?: boolean
}


const FilterSec: React.FC<FilterSecProps> = ({ 
      columns, 
      areaList,
      ReasonList,
      handleRoleChange, 
      plateBatch, 
      plateSerialNumber, 
      lotCodeNumber,
      areaListNumber,
      showBatchFilter = true,
      showSerielNoFilter = true,
      showLotCodeFilter = true,
      showPlateStatus,
      showPlateTypeFilter
    }) => {



    const [departmentList, setDepartmentList] = useState<Array<any>>([]);
    const [materialType, setMaterialType] = useState<Array<any>>([]);
    const [userRoleList, setUserRoleList] = useState<Array<any>>([]);
    const [areaTypeList, setAreaTypeList] = useState<Array<any>>([]);
    const [exceptionReasons, setExceptionReasons] = useState<Array<any>>([]);
    const [authToken , setAuthToken] = useState("")
    const [authUserID , setAuthUserID] = useState("")
    const [userId, setUserId] = useState("");
    const [equipmetTypesList, setEquipmentTypesList] = useState<Array<any>>([]);
    useEffect(() => {

      var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id)
      setAuthToken(storedObject[0].x_auth_token)
      setAuthUserID(storedObject[0].x_userid)
    }
        listDropDownType()
        getDepartmentList()
        listEquipmentTypes()
        getUserRoleList()
        geAreaTypeList()
        getExceptionReasons()
    }, [])

      const handleSelectChange = (selectedOption : any) => {
        if (selectedOption) {
          handleRoleChange("search", selectedOption?.value)
          handleRoleChange("batch_code", selectedOption?.value);
        } else {
            handleRoleChange("search", "")
            handleRoleChange("batch_code", ""); 
        }
      };

      const handleLotCodeChange = (selectedOption : any) => {
        if (selectedOption) {
          handleRoleChange("lot_code", selectedOption?.value)
        } else {
            handleRoleChange("lot_code", "") 
        }
      };

      const handleAreaType = (selectedOption : any) => {
        if (selectedOption) {
          handleRoleChange("Area Type", selectedOption?.value)
        } else {
          handleRoleChange("Area Type", "")
        }
      };

      const getDepartmentList = async () => {
        try {
          const response = await userRelatedService.getUserDepartmentList();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  console.log(response.data, "respose--->getUserDepartmentList")
                  let rowData: any = response.data.map((element: any, index: number) => {
  
                    return { 
                        slno: index + 1, 
                        department_name: element.department_name,
                        id : element.id,
                        status : element.status,
                    };
                  });
                  setDepartmentList(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('user response', error);
        }
      }

      const listDropDownType = async () => {
        try {
          const response = await rawMaterials.listDropDownType(authToken ,authUserID);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
    
                    return { 
                        slno: index + 1, 
                        material_type: element.material_type,
                        material_type_code: element.material_type_code,
                        id : element.id,
                        status : element.status,
                    };
                  });
                  setMaterialType(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('user response', error);
        }
      }

      const listEquipmentTypes = async () => {
        try {
          const response = await equipments.listEquipmentTypes();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
    
                    return { 
                        slno: index + 1, 
                        id : element.id,
                        type_name: element.type_name,
                    };
                  });
                  setEquipmentTypesList(rowData)
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('user response', error);
        }
      }
  
      const getUserRoleList = async () => {
        try {
          const response = await userRelatedService.getUserRoleList();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  console.log(response.data, "respose--->getUserRoleList")
                  let rowData: any = response.data.map((element: any, index: number) => {
  
                    return { 
                      slno: index + 1, 
                      title: element.title,
                      id : element.id,
                      status : element.status,
                    };
                  });
                  setUserRoleList(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('user response', error);
        }
      }

      const geAreaTypeList = async () => {
        try {
          const response = await userRelatedService.geAreaTypeList();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  console.log(response.data, "respose--->geAreaTypeList")
                  let rowData: any = response.data.map((element: any, index: number) => {
  
                    return { 
                      slno: index + 1, 
                      title: element.type_name,
                      id : element.type_code,
                      status : element.status,
                    };
                  });
                  setAreaTypeList(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('user response', error);
        }
      }

      const getExceptionReasons = async () => {
        try {
          const response = await plateRelatedService.getExceptionReasons();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
                    if(element.status === "active"){
                      return { 
                          id: element.id ,
                          reason: element.reason ,
                          status: element.status ,
                      };
                    }});
                  setExceptionReasons(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      }


  return (
    <>
             {columns.some((column : any) => column.Header === 'Batch ID') && (
                <select className='areaTypeSelect mx-3 ' >
                    <option>Batch ID</option>
                </select>
            )}
           {columns.some((column : any) => column.Header === 'tableCommon.area_type' || column.Header === 'tableCommon.location_name' || column.Header === 'Activity / Process' ) && (
            <div>
            <div>
                <label htmlFor="areaTypeSelect" className='filterLabel'>Area Type</label> 
            </div>
            <div>
                <select id="areaTypeSelect" className='areaTypeSelect mx-3' onChange={(e) => handleRoleChange("AreaType", e.target.value)}>
                    <option value={0}>Area Type</option>
                    {areaList?.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                            {obj?.title}
                        </option>
                    ))}
                </select>
            </div>
            </div>
            )}
            {columns.some((column : any) => column.Header === 'Reason' ) && (
            <div>
            <div>
                <label htmlFor="areaTypeSelect" className='filterLabel'>Reason</label> 
            </div>
            <div>
                <select id="areaTypeSelect" className='areaTypeSelect mx-3' onChange={(e) => handleRoleChange("Reason", e.target.value)}>
                    <option value={0}>Reason</option>
                    {ReasonList?.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                            {obj?.reason}
                        </option>
                    ))}
                </select>
            </div>
            </div>
            )}
            {columns.some((column : any) => column.Header === 'Incubated by' || column.Header === 'Autoclaved by') && (
              <div>
              <div>
                <label htmlFor="areaTypeSelect" className='filterLabel'>Activity Type</label> 
              </div>
              <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("Activity Type", e.target.value)} >
                    <option>Activity Type</option>
                    <option value={9}>Exposure Completed</option>
                    <option value={5}>Finger Dab</option>
                    <option value={25}>Raw Material Test</option>
                    <option value={24}>Plate Sampling</option>
                    <option value={28}>Surface Monitoring Plate</option>
                    <option value={31}>Surface Monitoring - SWAB Collection</option>
                </select>
                </div>
                </div>
            )}

            {showPlateTypeFilter && (
              <div className='mx-3'>
              <div>
                <label htmlFor="areaTypeSelect" className='filterLabel'>Plate Type</label> 
              </div>
              <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("Plate Type", e.target.value)} >
                    <option value={0}>Plate Type</option>
                    <option value={2}>Exposure Plate</option>
                    <option value={3}>Finger DAB Plate</option>
                    <option value={4}>Sampling Plate</option>
                    <option value={5}>Material Test Plate</option>
                    <option value={6}>Surface Monitoring Plate</option>
                    <option value={7}>Surface Monitoring - SWAB Collection</option>
                </select>
                </div>
                </div>
            )}

            {columns.some((column : any) => column.Header === 'Incubated by' || column.Header === 'Autoclaved by') && (
            <div>
            <div>
                <label htmlFor="areaTypeSelect" className='filterLabel'>Status Filter</label> 
            </div>
            <div>
                <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("Area Name", e.target.value)} >
                    <option>Status Filter</option>
                    <option value={9}>Pending</option>
                    <option value={12}>Inprogress</option>
                    <option value={13}>Completed</option>
                </select>
              </div>
            </div>
            )}
            {columns.some((column : any) => column.Header === 'Incubated by') && (
                // <select className='areaTypeSelect mx-3 ' onChange={(e) => handleRoleChange("Area Type", e.target.value)}>
                //     <option value={0}>Area Type</option>
                //     <option value={6}>Exposure Area</option>
                //     <option value={10}>FingerDab Area</option>
                // </select>
                <div style={{"zIndex":"10"}}>
                <div>
                    <label htmlFor="areaTypeSelect" className='filterLabel'>Area Type</label> 
                </div>
                <div>
                <div style={{"zIndex":"10"}}>
                <Select
                   options={areaListNumber}
                   onChange={handleAreaType}
                   isClearable
                   placeholder="Area Type"
                   isSearchable={true} 
                   className='areaTypeSelect2 me-3'
                 />
              </div>
             </div>
              </div>
            )}
             {columns.some((column : any) => column.Header === 'Plate Serial Num') && (
                <select className='areaTypeSelect mx-3 '>
                    <option>Plate Serial</option>
                </select>
            )}
            {columns.some((column : any) => column.Header === 'tableCommon.role') && (
            <div>
            <div>
                <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Role</label> 
            </div>
            <div>
                <select className='areaTypeSelect me-3' onChange={(e) => handleRoleChange("Role", e.target.value)} >
                    <option value={0}>Select Role</option>
                    {userRoleList.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                          {obj?.title}
                        </option>
                    ))}
                </select>
            </div>
            </div>
            )}

            {columns.some((column : any) => column.Header === 'tableCommon.department') && (
             <div>
             <div>
                 <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Department</label> 
             </div>
             <div>  
                <select className='areaTypeSelect me-3'  onChange={(e) => handleRoleChange("Department", e.target.value)} >
                    <option value={0}>Select Department</option>
                    {departmentList.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                          {obj?.department_name}
                        </option>
                    ))}
                </select>
              </div>
              </div>
            )}

            {columns.some((column : any) => column.Header === 'tableCommon.material_type') && (
             <div>
             <div>
                 <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Material Type</label> 
             </div>
             <div>  
                <select className='areaTypeSelect me-3'  onChange={(e) => handleRoleChange("MaterialId", e.target.value)} >
                    <option value={0}>Select MaterialType</option>
                    {materialType.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                          {obj?.material_type}
                        </option>
                    ))}
                </select>
              </div>
              </div>
            )}

            {columns.some((column : any) => column.Header === 'tableCommon.equipment_type') && (
             <div>
             <div>
                 <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Equipment Type</label> 
             </div>
             <div>  
                <select className='areaTypeSelect me-3'  onChange={(e) => handleRoleChange("EquipmentType", e.target.value)} >
                    <option value={0}>Select Equipment Type</option>
                    {equipmetTypesList.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                          {obj?.type_name}
                        </option>
                    ))}
                </select>
              </div>
              </div>
            )}

            {columns.some((column : any) => column.Header === 'Between Duration') && (
                <select className='areaTypeSelect mx-3 '>
                    <option>Duration Time</option>
                </select>
            )}
             {columns.some((column : any) => column.Header === 'Duration Name') && (
                <select className='areaTypeSelect mx-3 '>
                    <option>Duration Name</option>
                </select>
            )}
             {columns.some((column : any) => column.Header === 'Schedule') && (
                <select className='areaTypeSelect mx-3 '>
                    <option>Schedule</option>
                </select>
            )}
            {columns.some((column : any) => column.Header === 'tableCommon.batch_code') && showBatchFilter && (
               <div style={{"zIndex":"10"}}>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Batch Code</label> 
               </div>
               <div>
              <div style={{"zIndex":"10"}}>
                    <Select
                      options={plateBatch}
                      onChange={handleSelectChange}
                      placeholder="Batch code"
                      isSearchable={true} 
                      isClearable
                      className='areaTypeSelect2 me-3'
                    />
              </div>
              </div>
              </div>
            )}
            {columns.some((column : any) => column.Header === 'tableCommon.seriel_number') && showSerielNoFilter && (
               <div style={{"zIndex":"10"}}>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Serial Number</label> 
               </div>
               <div>
               <div style={{"zIndex":"10"}}>
                     <Select
                        options={plateSerialNumber}
                        onChange={handleSelectChange}
                        isClearable
                        placeholder="Serial Number"
                        isSearchable={true} 
                        className='areaTypeSelect2 me-3'
                      />
              </div>
              </div>
              </div>
            )}

            {columns.some((column : any) => column.Header === 'Lot Code') && showLotCodeFilter && (
                          <div style={{"zIndex":"10"}}>
                          <div>
                              <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Lot Code</label> 
                          </div>
                          <div>
                          <div style={{"zIndex":"10"}}>
                                <Select
                                    options={lotCodeNumber}
                                    onChange={handleLotCodeChange}
                                    isClearable
                                    placeholder="Lot Code"
                                    isSearchable={true} 
                                    className='areaTypeSelect2 me-3'
                                  />
                          </div>
                          </div>
                          </div>
            )}


            {showPlateStatus && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabel'>Status</label> 
               </div>
               <div>
                <select className='areaTypeSelect mx-3' onChange={(e) => handleRoleChange("status", e.target.value)}>
                    {/* <option value={0}>Status</option>
                    <option value={1}>New</option>
                    <option value={2}>Qa Inprogress</option>
                    <option value={3}>Qa Approved</option>
                    <option value={4}>Qa Rejected</option>
                    <option value={5}>Finger Dab Inprogress</option>
                    <option value={6}>Finger Dab Approved</option>
                    <option value={7}>Finger Dab Rejected</option>
                    <option value={8}>Ep Inprogress</option>
                    <option value={9}>Ep Approved</option>
                    <option value={10}>Ep Rejected</option>
                    <option value={12}>Incubation Inprogress</option>
                    <option value={13}>Incubation Completed</option>
                    <option value={14}>Autoclave Inprogress</option>
                    <option value={15}>Autoclave Completed</option>
                    <option value={16}>Exception Requested</option>
                    <option value={17}>Damaged</option>
                    <option value={18}>Discarded</option>
                    <option value={19}>Exception Rejected</option>
                    <option value={20}>Lab Inprogress</option>
                    <option value={21}>Lab Completed</option>
                    <option value={22}>Lab Approved</option>
                    <option value={23}>Lab Rejected</option> */}
                    <option value={""}>Status</option>
                    <option value={0}>Quarantine</option>
                    <option value={5}>Waiting for QA Approval</option>  
                    <option value={1}>QA Approved </option>
                    <option value={4}>QA Rejected</option>
                    <option value={3}>Activity Inprogress</option> 
                    <option value={6}>Discard</option>                                                                               
                </select>
                </div>
                </div>
            )}
            {/* {columns.some((column : any) => column.Header === 'Date') && (
                <input
                    type="date"
                    id="dateInput"
                    name="dateInput"
                    pattern="\d{4}-\d{2}-\d{2}"
                    className='areaTypeSelect me-3'
                    onChange={(e) => handleRoleChange("search", e.target.value)}
              />
            )} */}
            {columns.some((column : any) => column.Header === 'tableCommon.exception_reason') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Exception Reason</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("ExceptionReason", e.target.value)} >
                  <option value={0}>Exception Reason</option>
                  {exceptionReasons?.map((obj, index) => (
                      <option key={index} value={obj?.id}>
                        {obj?.reason}
                      </option>
                  ))}
              </select>
              </div>
              </div>
          )}
    </>
  )
}

export default FilterSec
