export const columns: TableColumn[] = [
    {
        Header: "Sl no",
        accessor: "slno",
      },
      {
        Header: "Device ID",
        accessor: "device_code",
      },
      {
        Header: "Device Name",
        accessor: "device_name",
      },
      {
        Header: "Remarks",
        accessor: "remarks",
      },
      
]


interface TableColumn {
    Header: string;
    accessor: string;
  }