import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );


const listRoles= async (page : number , pageSize : number, keyWord: any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/roles/list?page=${page}&size=${pageSize}&sort=&order=desc&status=active,inactive&keyword=${keyWord}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const getModuleName = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/permissions/list_modules?&sort=sort_order&order=asc&status=active&keyword=`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const permissionTable = async (module_id : number) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/permissions/list?sort=sort_order&order=asc&status=active&keyword=&module_id=${module_id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const addRole = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/roles/save`, 
       dataToSend,
       {
         headers: {
           'x-auth-token': authToken,
           'x-userid': authUserID
         }
       }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const editRolesandPermission = async (role_id : string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/roles/get_permissions_by_role?page=1&size=10&sort=sort_order&order=asc&status=active&keyword=&role_id=${role_id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const changeRoleStatus = async (id: string, status: string, interface_name : string) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/roles/change_status`, {
        id: id,
        status: status,
        interface_name: interface_name
      },
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

const rolesAndPermission = {
    listRoles,
    getModuleName,
    permissionTable,
    addRole,
    editRolesandPermission,
    changeRoleStatus
};
  
  
export { rolesAndPermission };