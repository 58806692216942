
export const ExposureTableColumns : TableColumn[]  = [
    {
        Header: "Sl.No",
        accessor: "slno",
      },
      {
        Header: 'dashboard.exposureTableColumns.exposurePointName',
        accessor: "name",
      },
      {
        Header: 'dashboard.exposureTableColumns.platesInExposure',
        accessor: "plate_in_exposure",
      },
      {
        Header: 'dashboard.exposureTableColumns.platesReadyForCollection',
        accessor: "ready_for_collection",
      },
      {
        Header: 'dashboard.exposureTableColumns.overduePlates',
        accessor: "over_due_plates",
      },
      {
        Header: 'dashboard.exposureTableColumns.upcomingSchedules',
        accessor: "upcoming_schedules",
      },
      {
        Header: 'dashboard.exposureTableColumns.missedSchedules',
        accessor: "missed_schedules",
      },
      {
        Header: 'dashboard.exposureTableColumns.exceptionsAdded',
        accessor: "exception_added",
      },
      {
        Header: 'dashboard.exposureTableColumns.approvedExceptions',
        accessor: "approved_exceptions",
      },
      {
        Header: 'dashboard.exposureTableColumns.deviationsCount',
        accessor: "escalations",
      },
]

//Incubator columns under each incubation area
export const IncubatorTableColumns : TableColumn[]  = [
    {
        Header: "Sl.No",
        accessor: "slno",
      },
      {
        Header: "dashboard.IncubatorTableColumns.incubatorName",
        accessor: "name",
      },
      {
        Header: "dashboard.IncubatorTableColumns.incubationCapacity",
        accessor: "capacity",
      },
      {
        Header: "dashboard.IncubatorTableColumns.platesInIncubation",
        accessor: "plates_in_incubation",
      },
      {
        Header: "dashboard.IncubatorTableColumns.readyForCollection",
        accessor: "ready_for_collection",
      },
      {
        Header: "dashboard.IncubatorTableColumns.overduePlates",
        accessor: "over_due_plates",
      },
      {
        Header: "dashboard.IncubatorTableColumns.exceptionsAdded",
        accessor: "exception_added",
      },
      {
        Header: "dashboard.IncubatorTableColumns.approvedExceptions",
        accessor: "approved_exceptions",
      },
      {
        Header: "dashboard.IncubatorTableColumns.deviations",
        accessor: "escalations",
      },
]

//plate details columns under each incubator 
export const IncubatorPlateDetailsTableColumns : TableColumn[]  = [
    {
        Header: "Sl.No",
        accessor: "slno",
      },
      {
        Header: "dashboard.IncubatorPlateDetailsTableColumns.plateSerialId",
        accessor: "plate_serial_number",
      },
      {
        Header: "dashboard.IncubatorPlateDetailsTableColumns.plateStatus",
        accessor: "plate_status",
      },
      {
        Header: "dashboard.IncubatorPlateDetailsTableColumns.plateType",
        accessor: "plate_type",
      },
      {
        Header: "dashboard.IncubatorPlateDetailsTableColumns.incubationStartTime",
        accessor: "incubation_start_time",
      },
      {
        Header: "dashboard.IncubatorPlateDetailsTableColumns.incubationStartBy",
        accessor: "incubated_start_by",
      },
      {
        Header: "dashboard.IncubatorPlateDetailsTableColumns.incubationEndTime",
        accessor: "incubation_end_time",
      },
      {
        Header: "dashboard.IncubatorPlateDetailsTableColumns.incubationEndBy",
        accessor: "incubated_end_by",
      },
  ]


//Column name for labtest table 
export const labtestColumns : TableColumn[]  = [
  {
      Header: "Sl.No",
      accessor: "slno",
    },
    {
      Header: "dashboard.LabtestTableColumns.plateSerialId",
      accessor: "plate_serial_number",
    },
    {
      Header: "dashboard.LabtestTableColumns.plateStatus",
      accessor: "plate_status",
    },
    {
      Header: "dashboard.LabtestTableColumns.plateType",
      accessor: "plate_type",
    },
    {
      Header: "dashboard.LabtestTableColumns.colonyCount",
      accessor: "colony_count",
    },
    {
      Header: "dashboard.LabtestTableColumns.collectedBy",
      accessor: "lab_start_by",
    },
    {
      Header: "dashboard.LabtestTableColumns.collectedTime",
      accessor: "lab_start_time",
    },
    {
      Header: "dashboard.LabtestTableColumns.completedBy",
      accessor: "lab_end_by",
    },
    {
      Header: "dashboard.LabtestTableColumns.completedTime",
      accessor: "lab_end_time",
    },
]


//define table columns here 
export const autoClavecolumns : TableColumn[]  = [
  {
      Header: "Sl.No",
      accessor: "slno",
    },
    {
      Header: "dashboard.autoclaveTablecolumns.plateSerialId",
      accessor: "plate_serial_number",
    },
    {
      Header: "dashboard.autoclaveTablecolumns.plateStatus",
      accessor: "plate_status",
    },
    {
      Header: "dashboard.autoclaveTablecolumns.plateType",
      accessor: "plate_type",
    },
    {
      Header: "dashboard.autoclaveTablecolumns.colonyCount",
      accessor: "colony_count",
    },
    {
      Header: "dashboard.autoclaveTablecolumns.collectedBy",
      accessor: "lab_start_by",
    },
    {
      Header: "dashboard.autoclaveTablecolumns.collectedTime",
      accessor: "lab_start_time",
    },
    {
      Header: "dashboard.autoclaveTablecolumns.completedBy",
      accessor: "lab_end_by",
    },
    {
      Header: "dashboard.autoclaveTablecolumns.completedTime",
      accessor: "lab_end_time",
    },
]
  




interface TableColumn {
    Header: string;
    accessor: string;
}