import React ,{ useEffect, useState } from 'react'
import CustomTable from '../../../widgets/table/index'
import HeaderSection from '../../common/headerSection';
import PageHeader from '../../common/pageHeader';
import { areaService } from '../../../service/areaServices';
import Pagination from '../../../widgets/pagination';
import { userService } from '../../../service/userService';
import AlertModal from '../../../widgets/alertModal';
import { policyService } from '../../../service/policyServices';
import CustomToast from '../../../widgets/toaster';
import AddPolicyModal from './modals/addPolicyModal';
import AddScheduleModal from './modals/addScheduleModal';
import { useTranslation } from 'react-i18next';
import LocationPolicy from './partials/locationPolicy';
import { AddExceptionModel } from '../../common/addException/models';
import { columns, columnsSchedule } from './partials/columns';


const PolicyManagement = () => {

    const { t } = useTranslation();
    const [tab, setTab] = useState("1")
    const [useEffectRequired, setUseEffectRequired] = useState(false)
    const [policyData, setPolicyData] = useState<Array<any>>([]);
    const [scheduleData, setScheduleData] = useState<Array<any>>([]);
    //pagination for policy
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(5)
    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const [keyWord, setkeyWord] = useState("")
    const [areaType, setAreaType] = useState("8,12,9,6")
    //pagination for schedule
    const [schedulePage, setSchedulePage] = useState(1)
    const [schedulePageSize, setScedulePageSize] = useState(5)
    const [scheduleTotalPages, setScheduleTotalPages] = useState(0)
    const [scheduletotalElements, setScheduleTotalElements] = useState(0)
    const [scheduleKeyWord, setSchedulekeyWord] = useState("")
    const [scheduleAreaType, setScheduleAreaType] = useState(0)
    
    const [areas, setAreas] = useState<Array<any>>([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showAddUserAlert, setShowAddUserAlert] = useState(false);
    const [showChangePolicyStatusAlert, setShowChangePolicyStatusAlert] = useState(false);
    const [showChangeScheduleStatusAlert, setShowChangeScheduleStatusAlert] = useState(false);
    const [showAddScheduleAlert, setShowAddScheduleAlert] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [addPolicyData, setAddPolicyData] = useState({
      id: "",
      policyName: "",
      areaType: "",
      policyDuration: "0",
      isEquipmentCalReq: "0",
      lastCalDate: "",
      nextCalDate: "",
      incubationDurFromExp: "",
      incubationCount: "",
      colCountMinRange: "0",
      colCountMaxRange: "0",
      isColCountManual: "0",
      labTestDurFromInc: "0",
      AutoclaveDurFromLab: "0",
      isAutoclaveStopMandatory: "0",
      fingerDabType: "",
      fingerDabDepartment: "0",
      incubationTemparature: "",
      incubationTemparatureMax: "",
      equipmentCalibrationDays: "",
      plateType:"",
      reuseUnUsedPlates:"",
      incubationOrder:"",
      reason: "",
    });
    const [departmentList, setDepartmentList] = useState<Array<any>>([]);
    const [userId, setUserId] = useState<number>(0);
    const [selectedPolicyId, setSelectedPolicyId] = useState<string>("");
    const [selectedScheduleId, setSelectedScheduleId] = useState<string>("");

    const [modalName , setModalName] = useState("");
    const [scheduleModalName , setScheduleModalName] = useState("");


    const [showAddScheduleModal, setShowAddScheduleModal] = useState(false);
    const daysOfWeek = [
      { name: 'Mon', id: '1'},
      { name: 'Tue', id: '2' },
      { name: 'Wed', id: '3' },
      { name: 'Thu', id: '4' },
      { name: 'Fri', id: '5' },
      { name: 'Sat', id: '6' },
      { name: 'Sun', id: '7' },
    ];
    interface Incubator {
      id: string;
      name: string;
    }

    const [addScheduleData, setAddScheduleData] = useState({
      policyScheduleName: "",
      exposureArea: "",
      exposurePoint: "",
      fromTime: "",
      fromDate: "",
      toDate: "",
      id: "",
      reason: ""
     
    });
    const [selectedDays, setSelectedDays] = useState("");
    const [endTime, setEndTime] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [policyId, setPolicyId] = useState("");
    const [updatedStatus, setUpdatedStatus] = useState("");
    const [scheduleId, setScheduleId] = useState("");
    const [updatedScheduleStatus, setUpdatedScheduleStatus] = useState("");
    const [showAddLocationModal, setShowAddLocationModal] = useState(false);
    const [selectedIncubators, setSelectedIncubators] = useState<Incubator[]>([]);
    const [policySubmitted, setPolicySubmitted] = useState(false);
    const [statusChanged, setStatusChanged] = useState(false);
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");
    const [permission, setPermission] = useState<any>([]);
    const [selectedLocPolicyId, setSelectedLocPolicyId] = useState<string>("");





    //get user details
    useEffect(() => {
      var storedData = localStorage.getItem('userDetails');

      if(storedData) {
        var storedObject = JSON.parse(storedData);
        setUserId(storedObject[0].id)
        getPermissionByRole(storedObject[0].role_master_id)
      }

      getLocationPolicy();
      getSchedulePolicy();
      getDepartmentList();
      geAreaTypeList();
      setUseEffectRequired(false)
    }, [useEffectRequired])

    //api call for area type drop down 
    const geAreaTypeList = async () => {
      try {
        const response = await areaService.geAreaDropDownPolicyList();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                console.log(response.data, "respose--->geAreaTypeList--")
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                    slno: index + 1, 
                    title: element.type_name,
                    id : element.type_code,
                    status : element.status,
                  };
                });
                setAreas(rowData);
              }
            } catch (error) {
              console.log(error);
            }
        }
      } catch (error) {
        console.error('area response', error);
      }
    }

    //api call to list policy table data
    const getLocationPolicy = async () => {
        try {
          const response = await areaService.getLocationPolicy(page, pageSize, sort, order , keyWord, areaType);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  setTotalPages(response?.totalPages)
                  setTotalElements(response?.totalElements)
                  let rowData: any = response.data.map((element: any, index: number) => {

                    
                     const statusNumber = parseInt(element.policy_area_type, 10); 
                     const policy_area_type = areaService.getLocationStatus(statusNumber);

                    const duration = element.duration > 0  ? element.duration : "-" 
                    const caliberation = element.equipment_calibration == 0 ? "No" : "Yes"
                    const colony_count = element.lab_colony_count_entry_flag == 1 ? `${element.lab_colony_count_min_range} - ${element.lab_colony_count_max_range}` : '-'
                    return { 
                        slno: index + 1, 
                        id: element.id,
                        activity_name: element.activity_name,
                        duration: duration,
                        policy_area_type: policy_area_type,
                        caliberation: caliberation,
                        colony_count: colony_count,
                        status : element.status
                    };
                  });
                  setPolicyData(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          } else {
            setPolicyData([]);
          }
        } catch (error) {
          console.error('user response', error);
        }
      };

    //api call to list schedule table data
    const getSchedulePolicy = async () => {
          try {
            const response = await areaService.getSchedulePolicy(schedulePage, schedulePageSize, sort, order , scheduleKeyWord, scheduleAreaType);
            if(response.status && response.statusCode === 200){
              try {
                  if ( Array.isArray(response.data) && response.data?.length > 0) {
                    setScheduleTotalPages(response?.totalPages)
                    setScheduleTotalElements(response?.totalElements)
                    let rowData: any = response.data.map((element: any, index: number) => {
  
                      
                       const statusNumber = parseInt(element.location_type, 10); 
                       const location_type = areaService.getLocationStatus(statusNumber);
  
                       const fromTime = new Date(`1970-01-01T${element.from_time}`);
                       const toTime = new Date(`1970-01-01T${element.to_time}`);
                       const duration = new Date(toTime.getTime() - fromTime.getTime());
                       const hours = duration.getUTCHours();
                       const minutes = duration.getUTCMinutes();
                       const seconds = duration.getUTCSeconds();

                       const selectedIds = element.repeat_days.split(',');
                       const selectedNames = daysOfWeek
                          .filter(day => selectedIds.includes(day.id))
                          .map(selectedDay => selectedDay.name).join(",");


                      return { 
                          slno: index + 1, 
                          id: element.id,
                          schedule_name: element.schedule_name,
                          location_type : location_type,
                          location_name : element.location_name,
                          duration: `${hours} : ${minutes} : ${seconds} `,
                          from_time : element.from_time,
                          to_time : element.to_time,
                          repeat_days : selectedNames,
                          status : element.status
                      };
                    });
                    setScheduleData(rowData);
                  }
                } catch (error) {
                  console.log(error);
                }
            } else {
              setScheduleData([]);
            }
          } catch (error) {
            console.error('user response', error);
          }
      };

    //handle policy page change 
    const handlePageChange = (newPage : any, pageSize?: number) => {
        setPage(newPage)
        if(pageSize) {
          setPageSize(pageSize)
        }
        setUseEffectRequired(true)
      };

    //handle schedule page change 
    const handleSchedulePageChange = (newPage : any, pageSize?: number) => {   
        setSchedulePage(newPage)
        if(pageSize) {
          setScedulePageSize(pageSize)
        }
        setUseEffectRequired(true)
      };
      
    //handle policy table filter 
    const handleFilter = (key: string, value: any) => {
      if(key === "AreaType") {
        if(value === "0") {
          setAreaType("8,12,9,6")
        } else {
          setAreaType(value)
        }
      }else if (key === "search") {
        setkeyWord(value)
      }
      setUseEffectRequired(true)
      }

    //handle schedule table filter 
    const handleScheduleFilter = (key: string, value: any) => {
      if(key === "AreaType") {
        setScheduleAreaType(value)
      }else if (key === "search") {
        setSchedulekeyWord(value)
      }
      setUseEffectRequired(true)
      }

    const handleStatusChange = (policy_id:any , status: string) => {
      setShowChangePolicyStatusAlert(true)
      setPolicyId(policy_id)
        if (status === "active") {
          setUpdatedStatus("inactive")
        } else {
          setUpdatedStatus("active")
        }
    }
    //handle policy status change 
    const handleStatusChangeSubmit = async () => {
        try {
          const response = await areaService.changePolicyStatus(policyId, updatedStatus, "Policy Management Status");
          
          if (response.status && response.statusCode === 200) {
            setToastType("success")
            setToastMessage(response.message)
          }
          else {
            setToastType("error")
            setToastMessage(response.message)
          }
          setShowToast(true)
          setUseEffectRequired(true)
          setStatusChanged(true)
          setUpdatedStatus("")
          setPolicyId("")
          setShowChangePolicyStatusAlert(false)
        } catch (error) {
          console.error(error);
        }
      }

    const handleScheduleStatus = async (id:any , status: string) => {
      setShowChangeScheduleStatusAlert(true)
      setScheduleId(id)
        if (status === "active") {
          setUpdatedScheduleStatus("inactive")
        } else {
          setUpdatedScheduleStatus("active")
        }
      }

    //handle schedule status change 
    const handleScheduleStatusChangeSunbmit = async () => {
      try {
        const response = await areaService.changeScheduleStatus(scheduleId, updatedScheduleStatus, "Schedule Management Status");
        
        if (response.status && response.statusCode === 200) {
          setToastType("success")
          setToastMessage(response.message)
        }
        else {
          setToastType("error")
          setToastMessage(response.message)
        }
        setShowToast(true)
        setUseEffectRequired(true)
        setUpdatedScheduleStatus("")
        setScheduleId("")
        setShowChangeScheduleStatusAlert(false)
      } catch (error) {
        console.error(error);
      }
    }

    //on Add button clicked 
    const handleAddClick = () => {
      setShowAddModal(true)
      setModalName("Add Policy")
      }

    //get department list 
    const getDepartmentList = async () => {
      try {
        const response = await userService.getUserDepartmentList();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      slno: index + 1, 
                      department_name: element.department_name,
                      id : element.id,
                      status : element.status,
                  };
                });
                setDepartmentList(rowData);
              }
            } catch (error) {
              console.log(error);
            }
        }
      } catch (error) {
        console.error('user response', error);
      }
      }

    //api call on add policy submit button clicked 
    const handleAddPolicySubmit = async () => {
        const dataToSend = {
          user_id : userId,
          id: addPolicyData.id,
          area_type : addPolicyData.areaType,
          activity_name : addPolicyData.policyName,
          duration_mins : addPolicyData.policyDuration,
          equipment_calibration_flag :addPolicyData.isEquipmentCalReq,
          last_calibration_date : addPolicyData.lastCalDate,
          next_calibration_date : "",
          inc_duration_from_ep : addPolicyData.incubationDurFromExp,
          inc_activity_count : addPolicyData.incubationCount,
          fin_test_type : addPolicyData.fingerDabType,
          fin_dipartment_id : addPolicyData.fingerDabDepartment,
          lab_min_range : addPolicyData.colCountMinRange,
          lab_max_range : addPolicyData.colCountMaxRange,
          lab_duration_from_inc : addPolicyData.labTestDurFromInc,
          lab_colony_count_entry_type : addPolicyData.isColCountManual,
          lab_check_duration : "0",
          aut_duration_from_lab : addPolicyData.AutoclaveDurFromLab,
          aut_check_duration : "0",
          aut_end_flag : addPolicyData.isAutoclaveStopMandatory,
          inc_temparature : addPolicyData.incubationTemparature,
          inc_temparature_max : addPolicyData.incubationTemparatureMax,
          equipment_calibration_days : addPolicyData.equipmentCalibrationDays,
          plate_types: addPolicyData.plateType,
          plate_grades: addPolicyData.reuseUnUsedPlates,
          incubation_order: addPolicyData.incubationOrder,
          "interface_name" : "Policy Management",
          reason: addPolicyData.reason
        }

        try {
          const response = await policyService.createPolicy(dataToSend);
          if (response.status && response.statusCode === 200) {
            setToastType("success")
            setToastMessage(response.message)
            getLocationPolicy();
            setShowAddModal(false)
            setShowAddLocationModal(false)
            setAddPolicyData({
              id: "",
              policyName: "",
              areaType: "",
              policyDuration: "0",
              isEquipmentCalReq: "0",
              lastCalDate: "",
              nextCalDate: "",
              incubationDurFromExp: "",
              incubationCount: "",
              colCountMinRange: "0",
              colCountMaxRange: "0",
              isColCountManual: "0",
              labTestDurFromInc: "0",
              AutoclaveDurFromLab: "0",
              isAutoclaveStopMandatory: "0",
              fingerDabType: "",
              fingerDabDepartment: "0",
              equipmentCalibrationDays: "",
              incubationTemparature: "",
              plateType:"",
              reuseUnUsedPlates:"",
              incubationOrder:"",    
              reason: "" ,
              incubationTemparatureMax:""
            });
            setSelectedIncubators([])
          } else {
            setToastType("error")
            setToastMessage(response.message)
          }
          setShowAddUserAlert(false)
          // setShowAddModal(false)
          // setShowAddLocationModal(false)
          // setSelectedIncubators([])
          setPolicySubmitted(true)
          setShowToast(true)
          setSelectedPolicyId("")
          setSelectedLocPolicyId("")
          // setAddPolicyData({
          //   id: "",
          //   policyName: "",
          //   areaType: "",
          //   policyDuration: "0",
          //   isEquipmentCalReq: "0",
          //   lastCalDate: "",
          //   nextCalDate: "",
          //   incubationDurFromExp: "",
          //   incubationCount: "",
          //   colCountMinRange: "0",
          //   colCountMaxRange: "0",
          //   isColCountManual: "0",
          //   labTestDurFromInc: "0",
          //   AutoclaveDurFromLab: "0",
          //   isAutoclaveStopMandatory: "0",
          //   fingerDabType: "",
          //   fingerDabDepartment: "0",
          //   equipmentCalibrationDays: "",
          //   incubationTemparature: "",
          //   plateType:"",
          //   reuseUnUsedPlates:"",
          //   incubationOrder:"",    
          //   reason: "" ,
          //   incubationTemparatureMax:""
          // });
          getLocationPolicy();

        } catch (error) {
          console.error(error);
        }
      

      }

    //on Edit button clicked 
    const handleEditClick = (id: string) => {
        const filteredUserDetails = policyData.filter((item) => item.slno === id);
        const selectedIds = filteredUserDetails.map((item) => item.id).join(',');
        setSelectedPolicyId(selectedIds)
        setShowAddModal(true)
        setModalName("Edit Policy")
      }
    
    //on Add button clicked 
    const handleScheduleAddClick = () => {
      setShowAddScheduleModal(true)
      setScheduleModalName("Add Schedule")
      }

    //api call on submit click event
    const handleAddScheduleSubmit = async () => {
      const dataToSend = {
        user_id : userId,
        id: addScheduleData.id,
        start_date : addScheduleData.fromDate,
        end_date : addScheduleData.toDate,
        name: addScheduleData.policyScheduleName,
        exposure_area_id : addScheduleData.exposureArea,
        location_id : addScheduleData.exposurePoint,
        from_time : addScheduleData.fromTime,
        to_time : endTime,
        repeat_days : selectedDays,
        "interface_name" : "Policy Schedule Management",
        reason: addScheduleData.reason
      }
      try {
        const response = await policyService.createSchedule(dataToSend);
        if (response.status && response.statusCode === 200) {
          setToastType("success")
          setToastMessage(response.message)
          getLocationPolicy();
          setShowAddScheduleModal(false)
          setAddScheduleData({
            policyScheduleName: "",
            fromDate: "",
            toDate: "",
            exposureArea: "",
            exposurePoint: "",
            fromTime: "",
            id:"",
            reason: ""
          });;
        } else {
          setToastType("error")
          setToastMessage(response.message)
        }
        setShowAddScheduleAlert(false)
        // setShowAddScheduleModal(false)
        setShowToast(true)
        getSchedulePolicy();
        // setAddScheduleData({
        //   policyScheduleName: "",
        //   fromDate: "",
        //   toDate: "",
        //   exposureArea: "",
        //   exposurePoint: "",
        //   fromTime: "",
        //   id:"",
        //   reason: ""
        // });

      } catch (error) {
        console.error(error);
      }
      }
    
    //handle edit click 
    const handleScheduleEditClick = (id: string) => {
      setScheduleModalName("Edit Schedule")
      const filteredUserDetails = scheduleData.filter((item) => item.slno === id);
      const selectedIds = filteredUserDetails.map((item) => item.id).join(',');
      setSelectedScheduleId(selectedIds)
      setShowAddScheduleModal(true)
    }

    // function to sort table
    const handleSort = (key: string, value: boolean) => {
      setSort(key)
      if (value) {
        setOrder("asc")
      } else {
        setOrder("desc")
      }
      setUseEffectRequired(true)
    }

    // function to sort table
    const handleSortSchedule = (key: string, value: boolean) => {
      setSort(key)
      if (value) {
        setOrder("asc")
      } else {
        setOrder("desc")
      }
      setUseEffectRequired(true)
    }

    //get permission by role 
    const getPermissionByRole = async (role : number) => {
      try {
        const response = await userService.getPermissionByRole(role);
        if(response.status && response.statusCode === 200){
          setPermission(response.data.permissions)
          // try {
          //     if ( Array.isArray(response.data) && response.data?.length > 0) {
          //       let rowData: any = response.data.map((element: any, index: number) => {

          //         return { 
          //           // slno: index + 1, 
          //           label: element.title,
          //           value : element.id,
          //           // status : element.status,
          //         };
          //       });
          //       // setUserRoleList(rowData);
          //     }
          //   } catch (error) {
          //     console.log(error);
          //   }
        }
      } catch (error) {
        console.error(error);
      }
    }


  return (
    <div>
        <HeaderSection />
        <PageHeader pageHeader='Policy Management' />
        <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 

        <div className='exceptionHandlingTab'>
              <button onClick={() => setTab("1")} className={tab === "1" ? 'colTabBtnSel': 'colTabBtn'}>{t('policyManagement.title')}</button>
              <button onClick={() => setTab("2")} className={tab === "2" ? 'colTabBtnSel': 'colTabBtn'}>{t('policyManagement.locationPolicy')}</button>
              <button onClick={() => setTab("3")} className={tab === "3" ? 'colTabBtnSel': 'colTabBtn'}>{t('scheduleManagement.title')}</button>

        </div>
        {tab === "1" && (
        <div className='me-3' style={{marginTop : "10px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                  tableHead={t('policyManagement.title')}
                  data={policyData} 
                  columns={columns} 
                  isEditable={permission?.includes("edit_policy")} 
                  totalElements={totalElements}  
                  isActionVisible={false} 
                  isViewVisible={false} 
                  isSeachable={true}  
                  onFilterClick={handleFilter}  
                  onUseEffectRequired={() => 
                  setUseEffectRequired(true)} 
                  isToggleStatus={permission?.includes("edit_policy")} 
                  onStatusChange={handleStatusChange}  
                  addButton={permission?.includes("add_policy")} 
                  addButtonText={t('policyManagement.add_area')}  
                  onAddClick={handleAddClick}
                  onEditClick={handleEditClick}
                  areaList={areas}
                  onSortClick={handleSort}
                />
            </div>
            <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
        </div>
        )}
        {tab === "2" && (
          <LocationPolicy
            columns={columns}
            handleStatusChange={handleStatusChange}
            handleAddPolicySubmit={handleAddPolicySubmit}
            setAddPolicyData={setAddPolicyData}
            addPolicyData={addPolicyData}
            showAddUserAlert={showAddUserAlert}
            setShowAddUserAlert={setShowAddUserAlert}
            setModalName={setModalName}
            modalName={modalName}
            setShowAddLocationModal={setShowAddLocationModal}
            showAddLocationModal={showAddLocationModal}
            setSelectedIncubators={setSelectedIncubators}
            selectedIncubators={selectedIncubators}
            policySubmitted={policySubmitted}
            statusChanged={statusChanged}
            setStatusChanged={setStatusChanged}
            setPolicySubmitted={setPolicySubmitted}
            setSelectedPolicyId={setSelectedLocPolicyId}
            selectedPolicyId={selectedLocPolicyId}
          />
        )}
        {tab === "3" && (
        <div className='me-3' style={{marginTop : "10px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                  tableHead={t('scheduleManagement.title')}
                  data={scheduleData} 
                  columns={columnsSchedule} 
                  isEditable={permission?.includes("edit_policy")} 
                  totalElements={scheduletotalElements} 
                  isActionVisible={false} 
                  isViewVisible={false} 
                  isSeachable={true} 
                  isToggleStatus={permission?.includes("edit_policy")} 
                  onUseEffectRequired={() => setUseEffectRequired(true)} 
                  onFilterClick={handleScheduleFilter} 
                  addButton={permission?.includes("add_policy")} 
                  addButtonText={t('scheduleManagement.add_schedule')}
                  onAddClick={handleScheduleAddClick} 
                  onStatusChange={handleScheduleStatus}
                  onEditClick={handleScheduleEditClick}
                  onSortClick={handleSortSchedule}
                />
            </div>
            <div>
              <Pagination
                page={schedulePage}
                totalPages={scheduleTotalPages}
                handlePageChange={handleSchedulePageChange}
              />
            </div>
        </div>
        )}   

      <AddPolicyModal 
          showAddModal={showAddModal} 
          setShowAddUserAlert={() => setShowAddUserAlert(true)} 
          setShowAddModal={() => setShowAddModal(false)}
          departmentList={departmentList} 
          areas={areas} 
          policyId={selectedPolicyId}
          setSelectedPolicyId={setSelectedPolicyId}
          setAddPolicyData={setAddPolicyData}
          addPolicyData={addPolicyData}
          modalName={modalName}
      />

      <AddScheduleModal
        showAddModal={showAddScheduleModal}
        setShowAddScheduleAlert={() => setShowAddScheduleAlert(true)} 
        setShowAddModal={() => setShowAddScheduleModal(false)}
        modalName={scheduleModalName}
        setAddScheduleData={setAddScheduleData}
        addScheduleData={addScheduleData}
        endTime={endTime}
        setEndTime={setEndTime}
        selectedDays={selectedDays}
        setSelectedDays={setSelectedDays}
        scheduleId={selectedScheduleId}
        setSelectedScheduleId={setSelectedScheduleId}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
      />

      {/* Alert modal for Add policy */}
      <AlertModal 
          show={showAddUserAlert}
          onHide={() => setShowAddUserAlert(false)}
          title='Alert'
          message={`${modalName}`}
          onConfirm={handleAddPolicySubmit}
        />

      {/* Alert modal for policy status change*/}
      <AlertModal 
        show={showChangePolicyStatusAlert}
        onHide={() => setShowChangePolicyStatusAlert(false)}
        title='Alert'
        message={`change policy status`}
        onConfirm={handleStatusChangeSubmit}
      />

      {/* Alert modal for Add schedule */}
      <AlertModal 
          show={showAddScheduleAlert}
          onHide={() => setShowAddScheduleAlert(false)}
          title='Alert'
          message={`${scheduleModalName}`}
          onConfirm={handleAddScheduleSubmit}
        />
      
      {/* Alert modal for schedule status change*/}
      <AlertModal 
        show={showChangeScheduleStatusAlert}
        onHide={() => setShowChangeScheduleStatusAlert(false)}
        title='Alert'
        message={`change schedule status`}
        onConfirm={handleScheduleStatusChangeSunbmit}
      />


    </div>
  )
}

export default PolicyManagement