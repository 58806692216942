import React,{useState} from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';


  interface ChartProps {
    chartType: string;
    data: any;
    selectedOption: string;
    selectedGraph: string;
    selectedGraphName?: string;
    activitySelect?:string;
    xaxisRange?:string;
  }

const Chart: React.FC<ChartProps> = ({
  chartType,
  data,
  selectedOption,
  selectedGraph,
  selectedGraphName,
  activitySelect,
  xaxisRange,
}) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
      );

      // const [chartType, setChartType] = useState('bar');
      // const [timeRange, setTimeRange] = useState('monthly');

      interface ChartType {
        label: string;
        component: any;
        backgroundColors?: string[];
        borderColors?: string[];
        stack?: boolean;
        fill?: boolean;
        selectedOption?: string;
        selectedGraph?: string;
        graphNameType?:string[];
        selectedGraphName?: string;
        activitySelect?:string;
        xaxisRange?:string;
        xAxisSelector?:string[];
      }

      interface ChartTypes {
        [key: string]: ChartType;
      }

      interface ChartOptions {
        responsive: boolean;
        aspectRatio: any
        plugins: {
          legend: {
            position: string;
          };
          title: {
            display: boolean;
            text: string;
          };
        };
        scales: { 
          x: { 
              stacked: boolean, 
          }, 
          y: { 
              stacked: boolean 
              title: {
                display: true,
                text: string
              },
          } 
        }
      }
      
      interface ChartData {
        labels: string[];
        datasets: {
          label: string;
          data: number[];
          backgroundColor: string;
          borderColor: string;
          borderWidth: number;
          fill?: boolean; // Adjust as needed
        }[];
      }

      const chartTypes: ChartTypes = {
        bar: {
          label: 'Bar Chart',
          component: Bar,
          backgroundColors: ['rgba(53, 162, 235, 0.5)', 'rgba(23, 152, 225, 0.5)'],
          borderColors: ['rgb(53, 162, 235)', 'rgb(23, 152, 225)'],
        },
        line: {
          label: 'Line Chart',
          component: Line,
          backgroundColors: ['rgba(53, 162, 235, 0)', 'rgba(23, 152, 225, 0)'],
          borderColors: ['rgb(53, 162, 235)', 'rgb(23, 152, 225)'],
        },
        area: {
          label: 'Area Chart',
          component: Line,
          backgroundColors: ['rgba(75, 192, 192, 0.5)', 'rgba(54, 162, 235, 0.5)'],
          borderColors: ['rgb(75, 192, 192)', 'rgb(54, 162, 235)'],
        },
        stackedBar: {
          label: 'Stacked Bar Chart',
          component: Bar,
          backgroundColors: ['rgba(53, 162, 235, 0.5)', 'rgba(23, 152, 225, 0.5)'],
          borderColors: ['rgb(53, 162, 235)', 'rgb(23, 152, 225)'],
          stack: true,
        },
        pie: {
          label: 'Pie Chart',
          component: Pie,
          backgroundColors: ['#ccd5ae', '#e9edc9', '#fefae0', '#faedcd', '#d4a373'],
          borderColors: ['rgb(53, 162, 235)', 'rgb(23, 152, 225)', 'rgb(75, 192, 192)', 'rgb(255, 205, 86)', 'rgb(255, 99, 132)'],
        },
        // Add more chart types as needed
      };

      const options: ChartOptions = {
        responsive: true,
        aspectRatio: chartType === "pie" ? 2 : null,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: false,
            text: `Chart.js ${chartTypes[chartType].label}`,
          },
        },
        scales: { 
          x: { 
              stacked: chartType === "stackedBar" || chartType === "area" ? true : false, 
          }, 
          y: { 
              stacked: chartType === "stackedBar" || chartType === "area" ? true : false,
              title: {
                display: true,
                text: selectedGraphName === 'organism_type' ? 'Organism Count' :
                selectedGraphName === 'genus' ? 'Genus Count' :
                selectedGraphName === 'species' ? 'Species Count' :
                selectedGraphName === 'strain' ? 'Strain Count' :
                selectedGraphName === 'total_count' ? 'Total Count' :
                'Colony Counts'
              }, 
          } 
      }
      };

  return (
    // <Bar options={options} data={data} />
    <div>
      <div className='m-3 px-2' style={{display: "flex", justifyContent: "center"}}>
          <p className='smallBoldText1' style={{fontSize: "15px"}}>{selectedGraph} 
          {selectedGraphName && <span> | By {selectedGraphName}</span>}
          {xaxisRange && <span> | By {xaxisRange}</span>}
          {activitySelect && <span> | By {activitySelect}</span>}</p>
      </div>
      <div className='d-flex justify-content-between m-2'>

      {/* <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Select Graph Name:</label>
        <select className='areaTypeSelect' onChange={(e) => setSelectedGraphName(e.target.value)} >
          <option value="">Select</option>
          {graphNameType?.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div> */}

        {/* <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Select Name:</label>
        <select className='areaTypeSelect' >
          <option value="">Select</option>
          {graphNameList?.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
        </div> */}

        {/* <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Select X Axis:</label>
        <select className='areaTypeSelect' value={axisRange} onChange={handleXaxisValueChange} >
          {xAxisSelector?.map((type) => (
            <option key={type}>
              {type}
            </option>
          ))}
        </select>
        </div> */}

        {/* <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Select Chart Type:</label>
        <select className='areaTypeSelect' value={chartType} onChange={handleChartTypeChange}>
          {Object.keys(chartTypes).map((type) => (
            <option key={type} value={type}>
              {chartTypes[type].label}
            </option>
          ))}
        </select>
        </div> */}

        {/* <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Select Duration:</label>
        <select className='areaTypeSelect' value={timeRange} onChange={handleChangeTimeRange}>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
          <option value="seasonal">Seasonal</option>
        </select>
        </div> */}

      </div>
      <div className='graphWrap'>
        {React.createElement(chartTypes[chartType].component, { data, options })}
      </div>
    </div>
  )
}

export default Chart