//table columns for user management table listing 
export const columns: TableColumn[] = [
    {
        Header: 'tableCommon.sl_no',
        accessor: 'slno',
      },
      {
        Header: 'addRawMaterial.tableColumns.material_name',
        accessor: 'material_type',
      },
      {
        Header: 'addRawMaterial.tableColumns.material_code',
        accessor: 'material_type_code',
      },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }


export const columnsSubType: TableColumn[] = [
    {
        Header: 'tableCommon.sl_no',
        accessor: 'slno',
      },
      {
        Header: 'addRawMaterial.tableColumns.name',
        accessor: 'name',
      },
      {
        Header: 'tableCommon.material_type',
        accessor: 'material_type',
      },
      {
        Header: 'addRawMaterial.tableColumns.material_type_code',
        accessor: 'material_type_code',
      },
]