import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );

const listRawMaterialType = async (page : any, pageSize: any, x_auth_token: string, x_user_id: string , keyword:string) => {
    try {
      const headers = {
        'x-auth-token': authToken,
        'x-userid': authUserID,
      };
      const response = await axiosInstance.get(`${baseUrl}/master/list_all_raw_material_type?page=${page}&size=${pageSize}&sort=material_type&status=&order=desc&keyword=${keyword}`, { headers });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listDropDownType = async (x_auth_token: string, x_user_id: string) => {
    try {
      const headers = {
        'x-auth-token': authToken,
        'x-userid': authUserID,
      };
      const response = await axiosInstance.get(`${baseUrl}/master/list_all_raw_material_type?sort=material_type&status=active&order=asc`, { headers });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

const listRawMaterialSubType = async (page : any, pageSize: any, x_auth_token: string, x_user_id: string, keyword:string, material_id:string) => {
    try {
      const headers = {
        'x-auth-token': authToken,
        'x-userid': authUserID,
      };
      const response = await axiosInstance.get(`${baseUrl}/master/list_all_raw_material_sub_type?page=${page}&size=${pageSize}&sort=id&order=desc&status=&material_type_id=${material_id}&keyword=${keyword}`, { headers });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

const createRawMaterialType = async (dataToSend: any, x_auth_token: string, x_user_id: string) => {
    try {
      const headers = {
        'x-auth-token': x_auth_token,
        'x-userid': x_user_id,
      };
      const response = await axiosInstance.post(`${baseUrl}/raw_material/create_material_type`, dataToSend, { headers });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


const createRawMaterialSubType = async (dataToSend: any, x_auth_token: string, x_user_id: string) => {
    try {
      const headers = {
        'x-auth-token': x_auth_token,
        'x-userid': x_user_id,
      };
      const response = await axiosInstance.post(`${baseUrl}/raw_material/create_material_sub_type`, dataToSend, { headers });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

const changeTypeStatus = async (id: string, status: string, x_auth_token: string, x_user_id: string , interface_name : string) => {
    try {
        const headers = {
          'x-auth-token': x_auth_token,
          'x-userid': x_user_id,
        };
        const response = await axiosInstance.post(`${baseUrl}/raw_material/change_type_status`, {
            id: id,
            status: status,
            interface_name: interface_name
        }, { headers });
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return error.response?.data
        }
    }
}

const changeSubTypeStatus = async (id: string, status: string, x_auth_token: string, x_user_id: string, interface_name : string) => {
  try {
      const headers = {
        'x-auth-token': x_auth_token,
        'x-userid': x_user_id,
      };
      const response = await axiosInstance.post(`${baseUrl}/raw_material/change_sub_type_status`, {
          id: id,
          status: status,
          interface_name: interface_name
      }, { headers });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
  }
}




const rawMaterials = {
    listRawMaterialType,
    createRawMaterialType,
    createRawMaterialSubType,
    changeTypeStatus,
    listRawMaterialSubType,
    changeSubTypeStatus,
    listDropDownType
  };
  
  
export { rawMaterials };