import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import { RootState, setGraphType } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface HistoricalDashboardProps {
  showFilters?: boolean;
}

const AnalyticDashboard: React.FC<HistoricalDashboardProps> = ({
  showFilters,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedBulletId, setSelectedBulletId] = useState<string | null>(null);

  const { t } = useTranslation();


  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  let columnNames: any = [
    "Area",
    "Location",
    "Operator",
    "Microbiologist",
    "Daily",
    "Weekly",
    "Monthly",
    "Quarterly",
    "Yearly",
  ];

  const handleViolationOptionClick = (bulletId: string, name: string) => {
    setSelectedBulletId(bulletId);
    if (bulletId < "4") {
      setShow(true);
      setModalName(name);
    } else {
      navigate(`/violation-home/${bulletId}`);
    }
  };

  // handle Trend Section
  const toggleFlip = (section: any) => {
    const imageContainer = document.querySelector(
      `.tab-image-${section} .tab-image-container`
    );
    if (imageContainer) {
      imageContainer.classList.toggle("flipped");
    } else {
      console.error(`Image container for ${section} not found.`);
    }
  };

  // const handleTrendOptionClick = (bulletId: string) => {
  //   setSelectedBulletId(bulletId);
  //   navigate(`/trend-analytics/${bulletId}`);
  // };

  // handle Excursion Section
  // const handleExcursionOptionClick = (bulletId: string) => {
  //   setSelectedBulletId(bulletId);
  //   navigate(`/excursion-report/${bulletId}`);
  // };


  const handleSummaryOptionClick = (bulletId: string) => {
    setSelectedBulletId(bulletId);
    navigate(`/summary-report/${bulletId}`);
  };

  const OnSeemoreClicked = (e : any) => {
    dispatch(setGraphType(e));
    navigate(`/inner-landing/${e}`);
  }

  return (
    <div className="container-fluid">
      <HeaderSection />
      <PageHeader pageHeader="Analytics Dashboard" />
      <nav aria-label="breadcrumb" className="breadcrumb-container row">
        <div className="col-10 d-flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item dashboard-hover">
              <Link to="/">{t("dashboard.title")}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {t("dashboard.analyticalDashboard")}
            </li>
          </ol>
        </div>
        <div className="col-2 d-flex"></div>
      </nav>

      <div className="row mt-4">
        {/* Trend Section starts here */}
        <div className="col-lg-5 d-flex left-text-head mx-5">
          <div className="col-lg-6">
            <div className="tab-container">
              <div className="tab-heading">
                <h5>{t("common.trends")}</h5>
                <p>{t("analyticalDashboard.about_trends")}</p>
                <button
                  className="btn-round"
                  id="seeMoreBtnTrend"
                  onClick={() => OnSeemoreClicked("Trends")}
                >{t("buttons.see_more")}</button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="tab-container">
              <div className="tab-image-trend">
                <div className="tab-image-container">
                  <div className="tab-image-front">{/* trend image */}</div>
                  <div className="tab-image-back">
                    {/* List items on the backside */}
                    {/* <ul>
                      <li onClick={() => handleTrendOptionClick("area")}>
                        By Area
                      </li>
                      <li onClick={() => handleTrendOptionClick("location")}>
                        By Location
                      </li>
                      <li onClick={() => handleTrendOptionClick("operator")}>
                        {" "}
                        By Operator
                      </li>
                      <li onClick={() => handleTrendOptionClick("daily")}>
                        {" "}
                        Daily
                      </li>
                      <li onClick={() => handleTrendOptionClick("weekly")}>
                        Weekly
                      </li>
                      <li onClick={() => handleTrendOptionClick("monthly")}>
                        Monthly
                      </li>
                      <li onClick={() => handleTrendOptionClick("quarterly")}>
                        Quarterly
                      </li>
                      <li onClick={() => handleTrendOptionClick("yearly")}>
                        Yearly
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Escursion Section starts here */}
        <div className="col-lg-5 d-flex left-text-head mx-5">
          <div className="col-lg-6">
            <div className="tab-container">
              <div className="tab-heading">
                <h5>{t("common.excursions")}</h5>
                <p>{t("analyticalDashboard.about_excursions")}</p>
                <button
                  className="btn-round"
                  id="seeMoreBtnExcursion"
                  onClick={() => OnSeemoreClicked("Excursion")}
                >{t("buttons.see_more")}</button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="tab-container">
              <div className="tab-image-excursion">
                <div className="tab-image-container">
                  <div className="tab-image-front">{/* trend image */}</div>
                  <div className="tab-image-back">
                    {/* List items on the backside */}
                    {/* <ul>
                      <li onClick={() => handleExcursionOptionClick("daily")}>
                        Daily
                      </li>
                      <li onClick={() => handleExcursionOptionClick("weekly")}>
                        Weekly
                      </li>
                      <li onClick={() => handleExcursionOptionClick("monthly")}>
                        Monthly
                      </li>
                      <li
                        onClick={() => handleExcursionOptionClick("quarterly")}
                      >
                        Quarterly
                      </li>
                      <li onClick={() => handleExcursionOptionClick("yearly")}>
                        Yearly
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row  mt-4">
        <div className="col-lg-5 d-flex left-text-head mx-5">
          <div className="col-lg-6">
            <div className="tab-container">
              <div className="tab-heading">
                <h5>{t("common.violations")}</h5>
                <p>{t("analyticalDashboard.about_violations")}</p>
                <button
                  className="btn-round"
                  id="seeMoreBtn"
                  onClick={() => OnSeemoreClicked("Violations")}
                >{t("buttons.see_more")}</button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="tab-container">
              <div className="tab-image-violation">
                <div className="tab-image-container">
                  <div className="tab-image-front">{/* trend image */}</div>
                  <div className="tab-image-back">
                    {/* List items on the backside */}
                    <ul>
                      <li
                        onClick={() =>
                          handleViolationOptionClick("1", "Schedule Trends")
                        }
                      >
                        Schedule Violations
                      </li>
                      <li
                        onClick={() =>
                          handleViolationOptionClick("2", "Exception Trends")
                        }
                      >
                        Exception Violations
                      </li>
                      {/* <li onClick={() => handleViolationOptionClick("3", "Discrepency Trends")}>Discrepency Violations</li> */}
                      {/* <li onClick={() => handleViolationOptionClick("4", "Schedule Summary")}>Schedule Summary</li>
                  <li onClick={() => handleViolationOptionClick("5", "Discrepency Summary")}>Discrepency Summary</li>
                  <li onClick={() => handleViolationOptionClick("6", "Exception Summary")}>Exception Summary{" "}</li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-5 d-flex left-text-head mx-5">
          <div className="col-lg-6">
            <div className="tab-container">
              <div className="tab-heading">
                <h5>{t("common.summary")}</h5>
                <p>{t("analyticalDashboard.about_trends")}</p>
                <button
                  className="btn-round"
                  id="seeMoreBtnSummary"
                  onClick={() => OnSeemoreClicked("Summary")}
                >
                  {t("buttons.see_more")}
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="tab-container">
              <div className="tab-image-summary">
                <div className="tab-image-container">
                  <div className="tab-image-front">{/* trend image */}</div>
                  <div className="tab-image-back ">
                    <ul>
                      <li onClick={() => handleSummaryOptionClick("1")}>
                        By Area--{" "}
                      </li>
                      <li onClick={() => handleSummaryOptionClick("2")}>
                        By Location{" "}
                      </li>
                      <li onClick={() => handleSummaryOptionClick("3")}>
                        By Operator
                      </li>
                      <li onClick={() => handleSummaryOptionClick("4")}>
                        By Microbiologist
                      </li>
                      <li onClick={() => handleSummaryOptionClick("5")}>
                        Schedule Summary
                      </li>
                      <li onClick={() => handleSummaryOptionClick("6")}>
                        Discrepency Summary
                      </li>
                      <li onClick={() => handleSummaryOptionClick("7")}>
                        Exception Summary{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} centered size="xl">
        <Modal.Header className="modalHead">
          <p className="modalHeadText pt-3">Select {modalName} By</p>
          <div onClick={() => setShow(false)} className="p-2 mx-1 ">
            <TfiClose fill="#FFFFFF" fontSize={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          {[...Array(3)].map((_, rowIndex) => (
            <div
              key={rowIndex}
              className="row"
              style={{ background: "#F8F8F8" }}
            >
              {[...Array(3)].map((_, colIndex) => (
                <div key={colIndex} className="col">
                  <div
                    className="card"
                    onClick={() =>
                      navigate(
                        `/violation-home/${selectedBulletId}/${
                          columnNames[rowIndex * 3 + colIndex]
                        }`
                      )
                    }
                  >
                    <div className="card-body">
                      {columnNames[rowIndex * 3 + colIndex]}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AnalyticDashboard;
