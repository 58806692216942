import React from 'react'
import { Column } from 'react-table';
import CustomTable from '../../../../widgets/table'
import Pagination from '../../../../widgets/pagination'
import { useTranslation } from 'react-i18next';

//History table section under Exception Handling area
interface HistoryProps {
  plateDetailsHistory: string[];
  columnsHistory: Column[];
  totalElementsHistory: number;
  pageHistory: number;
  totalPagesHistory: number;
  handleFilter: (key: string, value: any) => void;
  handleHistoryPageChange: (newPage: any) => void;
  handleSort : (key: string, value: boolean) => void;
}

const History: React.FC<HistoryProps> = ({
  plateDetailsHistory,
  columnsHistory,
  totalElementsHistory,
  pageHistory,
  totalPagesHistory,
  handleHistoryPageChange,
  handleFilter,
  handleSort
}) => {
  const { t } = useTranslation();

  return (
    <div>
          <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
            <CustomTable
              data={plateDetailsHistory}
              columns={columnsHistory}
              isEditable={false}
              totalElements={totalElementsHistory}
              isActionVisible={false}
              isViewVisible={false}
              isSeachable={true}
              tableHead={t('Exception.history')}
              showBatchFilter={false}
              showSerielNoFilter={false}
              onFilterClick={handleFilter}
              onSortClick={handleSort}
            />
          </div>
          <div>
            <Pagination
              page={pageHistory}
              totalPages={totalPagesHistory}
              handlePageChange={handleHistoryPageChange}
            />
          </div>
        </div>
  )
}

export default History