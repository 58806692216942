// store.ts
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';


// Define the initial state
interface AppState {
  activeNotifi: boolean;
  showNavbar: boolean;
  loading: boolean;
  areaName?:string;
  areaId?:string;
  GraphType?:string;
}


const initialState: AppState = {
  activeNotifi: false,
  showNavbar: true,
  loading: false,
  areaName: "",
  areaId: "",
  GraphType:""
};


// Define a slice using createSlice from Redux Toolkit

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    toggleNotifi: (state) => {
      state.activeNotifi = !state.activeNotifi;
    },
  },
});

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    toggleNavbar: (state) => {
      state.showNavbar = !state.showNavbar;
    },
  },
});

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  }
})

const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    setAreaName: (state, action) => {
      state.areaName = action.payload;
    },
    setAreaId: (state, action) => {
      state.areaId = action.payload;
    },
  },
});

const AnalyticsGraphSlice = createSlice({
   name: 'analyticsGraph',
   initialState,
   reducers: {
    setGraphType : (state, action) => {
      state.GraphType = action.payload;
    }
   },
})

// Create the Redux store
// const store = configureStore({
//   reducer: notificationSlice.reducer,
//   reducer: areaSlice.reducer,
// });

const rootReducer = combineReducers({
  notification: notificationSlice.reducer,
  navbar: navbarSlice.reducer,
  area: areaSlice.reducer,
  loader: loaderSlice.reducer,
  graphType: AnalyticsGraphSlice.reducer
});

const store = configureStore({
  reducer: rootReducer,
});

export const { toggleNotifi } = notificationSlice.actions;
export const { toggleNavbar } = navbarSlice.actions;
export const { setAreaName, setAreaId } = areaSlice.actions;
export const { setLoading } = loaderSlice.actions;
export const {setGraphType} = AnalyticsGraphSlice.actions;

export default store;

export type RootState = ReturnType<typeof store.getState>;
