
//table columns for user management table listing 
export const columns: TableColumn[] = [
    {
        Header: 'tableCommon.sl_no',
        accessor: 'slno',
      },
      {
        Header: 'userManagement.tableColumns.user_id',
        accessor: 'user_id',
      },
      {
        Header: 'userManagement.tableColumns.first_name',
        accessor: 'first_name',
      },
      {
        Header: 'userManagement.tableColumns.last_name',
        accessor: 'last_name',
      },
      {
        Header: 'tableCommon.department',
        accessor: 'department_name',
      },
      {
        Header: 'tableCommon.role',
        accessor: 'role',
      },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }
  