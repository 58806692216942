import React, { useEffect, useState } from 'react'
import HeaderSection from '../../../common/headerSection'
import PageHeader from '../../../common/pageHeader'
import { areaService } from '../../../../service/areaServices';
import { userService } from '../../../../service/plateServices';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { visualInspection } from '../../../../service/visualinspection';
import baseUrl from '../../../../service';
import { equipments } from '../../../../service/equipments';

const EquipmentConfiguration = () => {
  

  type Filters = {
    equipment: string;
    batchCode: string;
    lotCode: string;
    plateSerialNumber: string;
    fromDate: string;
    toDate: string;
  };
  type OptionType = {
    value: string;
    label: string;
  };

  
 const [filters, setFilters] = useState({
    equipment: '',
    batchCode: '',
    lotCode: '',
    plateSerialNumber: '',
    fromDate: '',
    toDate: '',
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [equipmentList, setEquipmentList] = useState<Array<any>>([]); 

  const handleChange = (
    eventOrValue: React.ChangeEvent<HTMLSelectElement | HTMLInputElement> | SingleValue<OptionType>,
    actionOrUndefined?: ActionMeta<OptionType>
  ) => {
    let newFilters: Filters;
  
    if (actionOrUndefined) {
      // For react-select
      const { name } = actionOrUndefined as { name: string };
      newFilters = { ...filters, [name]: (eventOrValue as SingleValue<OptionType>)?.value || '' };
    } else {
      // For native select and input elements
      const { name, value } = (eventOrValue as React.ChangeEvent<HTMLSelectElement | HTMLInputElement>).target;
      newFilters = { ...filters, [name]: value };
    }
  
    setFilters(newFilters);
  
    // Enable button if any filter is changed
    const anyFilterChanged = Object.values(newFilters).some(val => val !== '');
    setIsButtonDisabled(!anyFilterChanged);
  };
  
const downloadAuditCSV = async () => {
    const link = `${baseUrl}/summary/equipment?equipment_id=${filters.equipment}&from_date=${filters.fromDate}&to_date=${filters.toDate}`
    window.location.href = link;
};

  const listEquipments = async () => {
    try {
      const response = await equipments.listEquipmentFilter("");
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              console.log(response.data, "data---")
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    id : element.id,
                    name: element.name,
                };
              });
              setEquipmentList(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }else{
        setEquipmentList([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  useEffect(() =>{
    listEquipments();
  }, [])

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Equipment Configuration Report" />
      <div className="filter-component">
        <div className="filter-row">
          <label className="filter-label">
            Equipment:
            <select name="location" value={filters.equipment} onChange={handleChange} className="filter-select">
              <option value="">Select Equipment</option>
              {equipmentList.map((type) => (
                <option key={type.id} value={type.id}>
                    {type.name}
                </option>
              ))}
            </select>
          </label>
        </div>

        {/* <div className="filter-row">
        <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Batch Code</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={plateBatches}
            onChange={(selectedOption, actionMeta) => handleChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Batch code"
            isSearchable={true}
            isClearable
            // value={plateBatches.find(option => option.value === filters.batchCode) || null}
            name="batchCode"
          />
        </div>        
      </div> */}

      {/* <div className="filter-row">
      <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Lot Code</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={lotcode}
            onChange={(selectedOption, actionMeta) => handleChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Lot Code"
            isSearchable={true}
            isClearable
            // value={plateBatches.find(option => option.value === filters.batchCode) || null}
            name="lotCode"
          />
        </div>        
      </div> */}

      {/* <div className="filter-row">
      <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Plate Serial Number</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={plateSerielNum}
            onChange={(selectedOption, actionMeta) => handleChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Plate Serial Number"
            isSearchable={true}
            isClearable
            // value={plateBatches.find(option => option.value === filters.batchCode) || null}
            name="plateSerialNumber"
          />
        </div>        
      </div> */}

      <div className="filter-row">
        <label className="filter-label">
          From Date:
          <input type="date" name="fromDate" value={filters.fromDate} onChange={handleChange} className="filter-input" />
        </label>
      </div>

      <div className="filter-row">
        <label className="filter-label">
          To Date:
          <input type="date" name="toDate" value={filters.toDate} onChange={handleChange} className="filter-input" />
        </label>
      </div>

      <div className="filter-row">
        <button className="filter-button" disabled={isButtonDisabled} onClick={downloadAuditCSV}>Download Equipment Report</button>
      </div>
      </div>
      <div className="filter-row" style={{marginTop: "15%"}}></div>
    </div>
  )
}

export default EquipmentConfiguration;