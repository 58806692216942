import React, {useEffect, useState} from 'react'
import { Column } from 'react-table';
import CustomTable from '../../../../widgets/table';
import Pagination from '../../../../widgets/pagination';
import { useTranslation } from 'react-i18next';
import { userService } from '../../../../service/userService';

//Request table section under Exception Handling area
interface RequestProps {
    plateDetails: string[];
    columns: Column[];
    plateSerielNum: string[];
    totalElements: number;
    page: number;
    totalPages: number;

    handleApproveClick: (selectedRows: string[]) => void;
    handleRejectClick: (selectedRows: string[]) => void;
    handleAddClick: () => void;
    handlePageChange: (newPage: any) => void;
    handleFilter: (key: string, value: any) => void;
    handleSort : (key: string, value: boolean) => void;
}


const Requests: React.FC<RequestProps> = ({
    plateDetails,
    columns,
    plateSerielNum,
    totalElements,
    page,
    totalPages,
    handleApproveClick,
    handleRejectClick,
    handleAddClick,
    handlePageChange,
    handleFilter,
    handleSort
}) => {
  const { t } = useTranslation();
  const [permission, setPermission] = useState<any>([]);



  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
        // try {
        //     if ( Array.isArray(response.data) && response.data?.length > 0) {
        //       let rowData: any = response.data.map((element: any, index: number) => {

        //         return { 
        //           // slno: index + 1, 
        //           label: element.title,
        //           value : element.id,
        //           // status : element.status,
        //         };
        //       });
        //       // setUserRoleList(rowData);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
      }
    } catch (error) {
      console.error(error);
    }
  }



  useEffect (() => {
    var storedData = localStorage.getItem('userDetails');
  
    if(storedData) {
      var storedObject = JSON.parse(storedData);
      getPermissionByRole(storedObject[0].role_master_id);
    }
    }, [])

  return (
    <div>
    <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
      <CustomTable
        data={plateDetails}
        columns={columns}
        isEditable={false}
        totalElements={totalElements}
        isActionVisible={true}
        isViewVisible={false}
        isSeachable={true}
        tableHead={t('Exception.exception')}
        plateSerialNumber={plateSerielNum}
        approveButton={permission?.includes("approve_exceptions")}
        approveButtonText={t('buttons.approve')}
        rejectButton={permission?.includes("approve_exceptions")}
        rejectButtonText={t('buttons.reject')}
        addButton={permission?.includes("add_exceptions")}
        addButtonText={t('Exception.add_exception')}
        onStartClick={handleApproveClick}
        onEndClick={handleRejectClick}
        onAddClick={handleAddClick}
        showBatchFilter={false}
        onFilterClick={handleFilter}
        onSortClick={handleSort}
      />
    </div>
    <div>
      <Pagination
        page={page}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
  </div>
  )
}

export default Requests;