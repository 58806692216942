import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );


interface FormData {
  event: string,
  interface : string,
  role : string,
  employee: string,
  fromDate: string,
  toDate: string,
}

const listAuditReport = async (formData: FormData, page:number , size:number) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/audit_log/list?page=${page}&size=${size}&sort=&order=desc&keyword=` , {
        params: {
          action: formData.event || '',
          interface_type: formData.interface || '',
          user_id: formData.employee || '',
          to_date_time : formData.toDate||'',
          from_date_time: formData.fromDate|| '',         
        },
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}


interface UserFormData {
  role : string,
  employee: string,
  fromDate: string,
  toDate: string,
}

const listUserAccessReport = async (formData: UserFormData, page:number , size:number) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/logs/list_user_access_logs?page=${page}&size=${size}&sort=&order=desc&keyword=` , {
      params: {
        user_id: formData.employee || '',
        to_date_time : formData.toDate||'',
        from_date_time: formData.fromDate|| '',         
      },
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}


const auditTrialReport = {
    listAuditReport,
    listUserAccessReport
};
  
  
export { auditTrialReport };