    import axios from "axios";
    import baseUrl from "..";
    import { handleLogOut } from "../../utils/oathUtils";

    let authToken = ""
    let authUserID = ""

    const storedData = localStorage.getItem('userDetails');

    if (storedData) {
      try {
        const storedObject = JSON.parse(storedData);
        if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
          authToken = storedObject[0].x_auth_token
          authUserID = storedObject[0].x_userid
        }
      } catch (error) {
        console.error('Failed to parse user details:', error);
      }
    }

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );



    const listVisualInspectionPlates = async (page : any, pageSize: any, inspectionStatus: any, keyword: any, batchCode: any, lotCode:any) => {
        try {
        const response = await axiosInstance.get(`${baseUrl}/lots/list_lots?page=${page}&size=${pageSize}&sort=id&order=desc&inspection_status=${inspectionStatus}&keyword=${keyword}&batch_no=${batchCode}&lot_code=${lotCode}`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };


    const listLotCodeMainPage = async (batchCode: any) => {
      try {
      const response = await axiosInstance.get(`${baseUrl}/lots/list_lots?&sort=id&order=desc&inspection_status=failed,passed&batch_no=${batchCode}`,
      {
          headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
          }
      });
      return response.data;
      } catch (error) {
      if (axios.isAxiosError(error)) {
          return error.response?.data
      }
      }
    };


    const listInspectionReasons = async () => {
      try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_inspection_reasons?page=1&size=10&sort=id&order=desc`,
      {
          headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
          }
      });
      return response.data;
      } catch (error) {
      if (axios.isAxiosError(error)) {
          return error.response?.data
      }
      }
    };


    const saveVisualInspection = async (dataToSend:any) => {
        try {
          const response = await axiosInstance.post(`${baseUrl}/plates/save_visual_inspection`, dataToSend ,{
            headers: {
              'x-auth-token': authToken,
              'x-userid': authUserID
            }
          }
          );
          return response.data;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            return error.response?.data
          }
        }
      };

  
    const saveLotVisualInspection = async (dataToSend:any) => {
      try {
        const response = await axiosInstance.post(`${baseUrl}/plates/save_visual_inspection`, dataToSend ,{
          headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
          }
        }
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return error.response?.data
        }
      }
    };


    const listFilterLots = async () => {
        try {
        const response = await axiosInstance.get(`${baseUrl}/lots/list_lots?&sort=id&order=desc&inspection_status=&batch_no=`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };


    const getPlateDetailsByLotId = async (lotId:any) => {
      try {
        const response = await axiosInstance.get(`${baseUrl}/lots/view_lot?lot_id=${lotId}`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };


    const getPlateDetailsByLotCode = async (LotCode:any) => {
      try {
        const response = await axiosInstance.get(`${baseUrl}/lots/list_lots?page=1&size=10&sort=id&order=asc&status=active&keyword=&inspection_status=pending&batch_no=&lot_code=${LotCode}`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };


    const listLotByLotCode = async (lotCode: any) => {
      try {
      const response = await axiosInstance.get(`${baseUrl}/lots/list_lots?page=1&size=10&sort=id&order=asc&status=active&keyword=&inspection_status=pending&batch_no=&lot_code=${lotCode}`,
      {
          headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
          }
      });
      return response.data;
      } catch (error) {
      if (axios.isAxiosError(error)) {
          return error.response?.data
      }
      }
    };

    const ListActions = async () => {
      try {
      const response = await axiosInstance.get(`${baseUrl}/master_process/list?sort=id&status=active,inactive&order=asc&id=`,
      {
          headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
          }
      });
      return response.data;
      } catch (error) {
      if (axios.isAxiosError(error)) {
          return error.response?.data
      }
      }
    };





const visualInspection= {
    listVisualInspectionPlates,
    saveVisualInspection,
    listLotCodeMainPage,
    listFilterLots,
    saveLotVisualInspection,
    listInspectionReasons,
    getPlateDetailsByLotId,
    listLotByLotCode,
    getPlateDetailsByLotCode,
    ListActions
  };
  
  export { visualInspection };