import React from 'react';
import { Toast } from 'react-bootstrap';
import {MdVerified} from 'react-icons/md'
import {IoMdClose} from 'react-icons/io'

interface CustomToastProps {
  show: boolean;
  onClose: () => void;
  delay: number;
  message: string;
  toastType: string;
  toastPosition?: string;
}

const CustomToast: React.FC<CustomToastProps> = ({
  show,
  onClose,
  delay,
  message,
  toastType,
  toastPosition,
}) => {
  const toastClassName = `toasterClass 
    ${toastType === 'error' ? 'error' : ''} 
    ${toastPosition === 'topLeft' ? 'topLeft' : ''} 
  `;

  return (
    <Toast className={toastClassName} onClose={onClose} show={show} delay={delay * 8} autohide>
      <div className="d-flex justify-content-around text-center pt-1">
        <div>
          <MdVerified className='my-2' fontSize={20} />
        </div>
        <p className='toasterText my-2'>{message}</p>
        <div>
          <IoMdClose onClick={onClose} className='my-2' fontSize={25} />
        </div>
      </div>
    </Toast>
  );
};

export default CustomToast;
