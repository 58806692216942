import React, { useEffect, useState } from 'react'
import HeaderSection from '../../../common/headerSection'
import PageHeader from '../../../common/pageHeader'
import { areaService } from '../../../../service/areaServices';
import { userService } from '../../../../service/plateServices';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { visualInspection } from '../../../../service/visualinspection';
import baseUrl from '../../../../service';

const PersonnelMonitoring = () => {
  

  type Filters = {
    location: string;
    batchCode: string;
    lotCode: string;
    plateSerialNumber: string;
    fromDate: string;
    toDate: string;
  };
  type OptionType = {
    value: string;
    label: string;
  };

  
 const [filters, setFilters] = useState({
    location: '',
    batchCode: '',
    lotCode: '',
    plateSerialNumber: '',
    fromDate: '',
    toDate: '',
  });
  const [areas, setAreas] = useState<Array<any>>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [plateBatches, setPlateBatches] = useState([]);
  const [lotcode, setLotCode] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([])

  const handleChange = (
    eventOrValue: React.ChangeEvent<HTMLSelectElement | HTMLInputElement> | SingleValue<OptionType>,
    actionOrUndefined?: ActionMeta<OptionType>
  ) => {
    let newFilters: Filters;
  
    if (actionOrUndefined) {
      // For react-select
      const { name } = actionOrUndefined as { name: string };
      newFilters = { ...filters, [name]: (eventOrValue as SingleValue<OptionType>)?.value || '' };
    } else {
      // For native select and input elements
      const { name, value } = (eventOrValue as React.ChangeEvent<HTMLSelectElement | HTMLInputElement>).target;
      newFilters = { ...filters, [name]: value };
    }
  
    setFilters(newFilters);
  
    // Enable button if any filter is changed
    const anyFilterChanged = Object.values(newFilters).some(val => val !== '');
    setIsButtonDisabled(!anyFilterChanged);
  };
  
  const geAreaTypeList = async () => {
    try {  
      const response = await areaService.getParentAreaList([10]);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    slno: index + 1, 
                    policy_id : element.policy_id,
                    id : element.id,
                    title: element.name,
                };
              });
              setAreas(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  //batch list for filter dropdown 
  const listPlateBatch = async () => {
      try {
        const response = await userService.listPlateBatch();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.batch_number,
                      label: element.batch_number ,
                  };
                });
                setPlateBatches(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
  }

  const lotCode = async () => {
    try {
      const response = await visualInspection.listFilterLots();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    value: element.lot_code,
                    label: element.lot_code ,
                };
              });
              setLotCode(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  const listSerialNumber = async () => {
    try {
      const response = await userService.listSerialNumber();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.serial_number,
                    label: element.serial_number ,
                };
              });
              setPlateSerielNums(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  useEffect (() => {
    geAreaTypeList();
    listPlateBatch();
    listSerialNumber();
    lotCode();
  },[])
  

const downloadAuditCSV = async () => {
    const link = `${baseUrl}/summary/personal_monitoring?area_master_id=${filters.location}&batch_number=${filters.batchCode}&lot_id=${filters.lotCode}&serial_number=${filters.plateSerialNumber}&from_date=${filters.fromDate}&to_date=${filters.toDate}`
    window.location.href = link;
};


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Personnel Monitoring Report" />
      <div className="filter-component">
        <div className="filter-row">
          <label className="filter-label">
            Location:
            <select name="location" value={filters.location} onChange={handleChange} className="filter-select">
              <option value="">Select Location</option>
              {areas?.map((obj, index) => (
                <option key={index} value={obj?.id}>
                  {obj?.title}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="filter-row">
        <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Batch Code</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={plateBatches}
            onChange={(selectedOption, actionMeta) => handleChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Batch code"
            isSearchable={true}
            isClearable
            // value={plateBatches.find(option => option.value === filters.batchCode) || null}
            name="batchCode"
          />
        </div>        
      </div>

      <div className="filter-row">
      <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Lot Code</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={lotcode}
            onChange={(selectedOption, actionMeta) => handleChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Lot Code"
            isSearchable={true}
            isClearable
            // value={plateBatches.find(option => option.value === filters.batchCode) || null}
            name="lotCode"
          />
        </div>        
      </div>

      <div className="filter-row">
      <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Plate Serial Number</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={plateSerielNum}
            onChange={(selectedOption, actionMeta) => handleChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Plate Serial Number"
            isSearchable={true}
            isClearable
            // value={plateBatches.find(option => option.value === filters.batchCode) || null}
            name="plateSerialNumber"
          />
        </div>        
      </div>

      <div className="filter-row">
        <label className="filter-label">
          From Date:
          <input type="date" name="fromDate" value={filters.fromDate} onChange={handleChange} className="filter-input" />
        </label>
      </div>

      <div className="filter-row">
        <label className="filter-label">
          To Date:
          <input type="date" name="toDate" value={filters.toDate} onChange={handleChange} className="filter-input" />
        </label>
      </div>

      <div className="filter-row">
        <button className="filter-button" disabled={isButtonDisabled} onClick={downloadAuditCSV}>Download Personnel Monitoring Report</button>
      </div>
      </div>

    </div>
  )
}

export default PersonnelMonitoring;