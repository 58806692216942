import React, { useEffect, useRef, useState } from 'react'
import HeaderSection from '../../common/headerSection'
import PageHeader from '../../common/pageHeader'
import CustomToast from '../../../widgets/toaster'
import { useTranslation } from 'react-i18next'
import { BsQrCodeScan } from 'react-icons/bs'
import CustomTable from '../../../widgets/table'
import { columns, columnsModalTable } from './partials/columns'
import Pagination from '../../../widgets/pagination'
import { TfiClose } from 'react-icons/tfi'
import { Modal } from 'react-bootstrap'
import { visualInspection } from '../../../service/visualinspection'
import { userService as plateRelatedService } from '../../../service/plateServices';
import { changeLanguage } from 'i18next'
import AlertModal from '../../../widgets/alertModal'
import { CiCircleRemove } from 'react-icons/ci'
import PlateDetailsViewModel from '../../lab_operator/labReport/models/plateDetailsModel'
import { IoSearchSharp } from 'react-icons/io5'
import { userService } from '../../../service/userService'




const VisualInspectionTest = () => {

    const { t } = useTranslation();
    const scanAreaRef = useRef<HTMLInputElement>(null);


    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("success");
    const [toastType, setToastType] = useState("success");
    const [tab, setTab] = useState("1");
    const [scanCode, setScanCode] = useState("");
    const [serielScanShow, setSerielScanShow] = useState(false);
    const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
    const [useEffectRequired, setUseEffectRequired] = useState(false);
    const [plateBySerielNo, setPlateBySerielNo] = useState<Array<any>>([]);
    const [show, setShow] = useState(false);
    const [plateBatches, setPlateBatches] = useState([]);
    const [plateSerielNum, setPlateSerielNums] = useState([]);
    const [plateStatus, setPlateStatus] = useState<any | null>("");
    const [userId, setUserId] = useState<number>(0);
    const [keyWord, setkeyWord] = useState("");
    const [showVisualInspectionTestAlert, setShowVisualInspectionTestAlert] = useState(false);
    const [newResult, setResult] = useState("");
    const [batchCode, setBatchCode] = useState("");
    const [lotCode, setLotCode] = useState("");
    const [inspectionReasons, setInspectionReasons] = useState([]);
    const [selectedSerielNo, setSelectedSerielNo] = useState("");

    const [formData, setFormData] = useState({
      plate_inspection_status: '',
      reason: '',
      remarks: '',
    });

    // pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    interface Errors {
      reason?: string;
    }
    const [errors, setErrors] = useState<Errors>({});
    const [permission, setPermission] = useState<any>([]);



    //api call for visualinspection plate listing 
    const listVisualInspectionPlates = async () => {
      try {
        const response = await visualInspection.listVisualInspectionPlates(page, pageSize, "passed,failed", keyWord, batchCode, lotCode);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages)
                setTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {
                  return { 
                      id : element.id,
                      slno: index + 1,
                      batch_number: element.batch_no,
                      lot_code: element.lot_code,
                      total_plates : element.plate_count,
                      passed_plates : element.passed_plate_count,
                      operator_name : element.inspected_by_name,
                      operator_id : element.inspected_by,
                      operator_code : element.inspected_by_user_id,
                      date : element.inspected_on,
                      status : element.inspection_status,
                      reason : element.reason,
                  };
                });
                setPlateDetails(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        } else {
          setPlateDetails([])
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    };

    const getPermissionByRole = async (role : number) => {
      try {
        const response = await userService.getPermissionByRole(role);
        if(response.status && response.statusCode === 200){
          setPermission(response.data.permissions)
          // try {
          //     if ( Array.isArray(response.data) && response.data?.length > 0) {
          //       let rowData: any = response.data.map((element: any, index: number) => {
  
          //         return { 
          //           // slno: index + 1, 
          //           label: element.title,
          //           value : element.id,
          //           // status : element.status,
          //         };
          //       });
          //       // setUserRoleList(rowData);
          //     }
          //   } catch (error) {
          //     console.log(error);
          //   }
        }
      } catch (error) {
        console.error(error);
      }
    }

    //api call for visualinspection plate listing modal 
    const listModalVisualInspectionPlates = async (batchCode : any) => {
      try {
        const response = await visualInspection.listVisualInspectionPlates(1, 5, "", "", batchCode, "");
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages)
                setTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {
                  return { 
                      slno: index + 1,
                      plateSerielId: element.id,
                      batch_number: element.batch_no,
                      lot_code: element.lot_code,
                      plate_count: element.plate_count,
                      lot_pass_count: 0
                  };
                });
                setPlateBySerielNo(rowData);
                // setPlateDetails(rowData);

              }
            } catch (error) {
              console.log(error); 
            }
        } else {
          setPlateDetails([])
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    };

    //batch list for filter dropdown 
    const listPlateBatch = async () => {
      try {
        const response = await plateRelatedService.listPlateBatch();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.batch_number,
                      label: element.batch_number ,
                  };
                });
                setPlateBatches(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    //serial number listing for table drop down 
    const listSerialNumber = async () => {
      try {
        const response = await visualInspection.listFilterLots();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.lot_code,
                      label: element.lot_code ,
                  };
                });
                setPlateSerielNums(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    //serial number listing for table drop down 
    const listInspectionReasons = async () => {
      try {
        const response = await visualInspection.listInspectionReasons();
        console.log(response.data, "data-1212")
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      id: element.id,
                      reason: element.reasons,
                      status: element.status,
                  };
                });
                setInspectionReasons(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }


    // clear the scan area
    const handleScanCodeClear = () => {
        setScanCode("")
        // setPlateBySerielNo([])
    }


    //handle page change in pagination 
    const handlePageChange = (newPage : any, pageSize?: number) => {
        setPage(newPage);
        if(pageSize) {
          setPageSize(pageSize)
        }
        setUseEffectRequired(true)
    };

    //pass the obtained id to the api to get plate details lot id
    const handleScanSerielNo = (result: any) => {
        console.log('Original Result:', result);
        if (result.trim() !== "") {
          setScanCode(result);
          try {
            const parsedResult = JSON.parse(result);
            console.log('Parsed Result:', parsedResult);
      
            if (typeof parsedResult === 'object') {
              let lotId;
      
              if (parsedResult.hasOwnProperty('id')) {
                lotId = parsedResult.id;
              } else {
               
                console.log('No serial number property found in the parsed result.');
              }
              if (lotId) {
                // getPlateDetailsBySerielNo(lotId);
                getPlateDetailsByLotId(lotId)
                // setScanCode(lotId)
              }
            } else {
              console.log('Parsed result is not an object.');    
            }
          } catch (error) {
            // getPlateDetailsByLotId(result);
            console.error('Error parsing JSON:', error);
          }
        }
      };

    //get plate details by serial number on add exception 
    const getPlateDetailsBySerielNo = async (slNo : string) => {
        let process = "test"
        if(slNo !== ""){
          console.log("have slno")
          try {
            const response = await plateRelatedService.listAutoClavePlateBySerielNo(slNo, process);
            if(response.status && response.statusCode === 200){
              try {
                  // if ( Array.isArray(response.data) && response.data?.length > 0) {
                  //   let rowData: any = response.data.map((element: any, index: number) => {
                  //     return { 
                  //         plateSerielId: element.id ,
                  //         plateType: userService.getPlateStatus(parseInt(element.plate_status)) ,
                  //         batchId:element.batch_number ,
                  //         plateSerielNo: element.serial_number ,
                  //     };
                  //   });
                  if (response.data && typeof response.data === 'object') {
                    let rowData = [{
                        slno: 1,
                        plateSerielId: response.data.id,
                        plate_type: plateRelatedService.getPlateStatus(parseInt(response.data.plate_status)),
                        batch_number: response.data.batch_number,
                        serial_number: response.data.serial_number,
                        lot_pass_count: 0
                    }];
                    // setPlateBySerielNo(rowData);

                    const startingSlno = plateBySerielNo.length + 1;
                    rowData = rowData.map((item, index) => ({
                        ...item,
                        slno: startingSlno + index
                    }));

                    const uniqueUpdatedDataWithSlno = rowData.filter((newElement : any) => {
                      return !plateBySerielNo.some((existingElement) => existingElement.serial_number === newElement.serial_number);
                    });
  
                    setPlateBySerielNo((prevUserRoleList) => [...prevUserRoleList, ...uniqueUpdatedDataWithSlno]);
                  }
                } catch (error) {
                  console.log(error); 
                }
            } else {
              // setPlateBySerielNo([]);
              setToastMessage(response.message);
              setToastType("error")
              setShowToast(true)
            }
          } catch (error) {
            console.error('Plate response', error);
          }
        } else {
        console.log("no slno")
        }
      }

    //get plate details by lot id
    const getPlateDetailsByLotId = async (slNo : string) => {
      let process = "test"
      if(slNo !== ""){
        try {
          const response = await visualInspection.getPlateDetailsByLotId(slNo);
          if(response.status && response.statusCode === 200){
            try {
                // if ( Array.isArray(response.data) && response.data?.length > 0) {
                //   let rowData: any = response.data.map((element: any, index: number) => {
                //     return { 
                //         plateSerielId: element.id ,
                //         plateType: userService.getPlateStatus(parseInt(element.plate_status)) ,
                //         batchId:element.batch_number ,
                //         plateSerielNo: element.serial_number ,
                //     };
                //   });
                if (response.data && typeof response.data === 'object') {
                  let rowData = [{
                      slno: 1,
                      plateSerielId: response.data.id,
                      batch_number: response.data.batch_no,
                      lot_code: response.data.lot_code,
                      plate_count: response.data.plate_count,
                      lot_pass_count: 0
                  }];
                  // setPlateBySerielNo(rowData);

                  const startingSlno = plateBySerielNo.length + 1;
                  rowData = rowData.map((item, index) => ({
                      ...item,
                      slno: startingSlno + index
                  }));

                  const uniqueUpdatedDataWithSlno = rowData.filter((newElement : any) => {
                    return !plateBySerielNo.some((existingElement) => existingElement.plateSerielId === newElement.plateSerielId);
                  });

                  setPlateBySerielNo((prevUserRoleList) => [...prevUserRoleList, ...uniqueUpdatedDataWithSlno]);
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            // setPlateBySerielNo([]);
            setToastMessage(response.message);
            setToastType("error")
            setShowToast(true)
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      } else {
      console.log("no slno")
      }
    }

    //get plate details by lot id
    const getPlateDetailsByLotCode = async (loteCode : string) => {
      let process = "test"
      if(loteCode !== ""){
        try {
          const response = await visualInspection.getPlateDetailsByLotCode(loteCode);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
                    return { 
                      slno: 1,
                      plateSerielId: element.id,
                      batch_number: element.batch_no,
                      lot_code: element.lot_code,
                      plate_count: element.plate_count,
                      lot_pass_count: 0
                    };
                  });
                // if (response.data && typeof response.data === 'object') {
                //   let rowData = [{
                //       slno: 1,
                //       plateSerielId: response.data[0].id,
                //       batch_number: response.data[0].batch_no,
                //       lot_code: response.data[0].lot_code,
                //       plate_count: response[0].data.plate_count,
                //       lot_pass_count: 0
                //   }];
                  // setPlateBySerielNo(rowData);

                  const startingSlno = plateBySerielNo.length + 1;
                  rowData = rowData.map((item : any, index:number) => ({
                      ...item,
                      slno: startingSlno + index
                  }));

                  const uniqueUpdatedDataWithSlno = rowData.filter((newElement : any) => {
                    return !plateBySerielNo.some((existingElement) => existingElement.plateSerielId === newElement.plateSerielId);
                  });

                  setPlateBySerielNo((prevUserRoleList) => [...prevUserRoleList, ...uniqueUpdatedDataWithSlno]);
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            // setPlateBySerielNo([]);
            setToastMessage(response.message);
            setToastType("error")
            setShowToast(true)
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      } else {
      console.log("no slno")
      }
    }

    // visual inspection listing filter change
    const handleVisualInspectionFilter = (key: string, value: string) => {
        if(key === "status") {
          setPlateStatus(value)
        } else if (key === "batch_code") {
          setBatchCode(value)
        } else if (key === "lot_code") {
          setLotCode(value)
        }
        setUseEffectRequired(true)
    }

    // visual inspection test input changeLanguage
    const handleChange = (e : any) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };

    // visual inspection test submit api
    const handleVisualTestSubmit = async () => {
      setShowVisualInspectionTestAlert(false)

      const generateLotDetails = (plates : any) => {
        return plates.map((plate : any) => ({
            lot_id: plate.plateSerielId,
            passed_plates: plate.lot_pass_count.toString(),
          }));
      };

      const lot_details = generateLotDetails(plateBySerielNo);

      const dataToSend = {
        lot_details,
        "plate_inspection_status": formData.plate_inspection_status,
        "reason_id": formData.reason,  
        "remarks": formData.remarks,
      }

      try {
        const response = await visualInspection.saveLotVisualInspection(dataToSend);
        if (response.status && response.statusCode === 200) {
          setToastMessage(response.message)
          setToastType("success")
          setUseEffectRequired(true)
          visualInspectionClose()
        } else {
          setToastMessage(response.message)
          setToastType("error")
        }
        setShowToast(true)
        // visualInspectionClose()
      } catch (error) {
        console.error("user response", error);
      }
    }

    //handle plate alert 
    const handleVisualInspectionTestAlert = () => {
      const errors: Errors = {};
      if (formData.plate_inspection_status === "failed") {
        if (!formData.reason) {
          errors.reason = "Please enter reason";
        }
      }
      
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      setShowVisualInspectionTestAlert(true)
      setErrors({});
    }

    // visual inspection test model close
    const visualInspectionClose = () => {
      setShow(false)
      setScanCode("")
      setPlateBySerielNo([])
      setFormData({
        plate_inspection_status: '',
        reason: '',
        remarks: '',
      })
      setErrors({})
    }

    //function to remove scanned plate from visual inspection test table
    const handleRemoveClick = (rowId: string) => {
      // const startingSlno = plateBySerielNo.length + 1;
      // setPlateBySerielNo((prevState: any) => prevState.filter((plate: any )=> plate.slno !== rowId));

      setPlateBySerielNo((prevState: any) => {
        // Filter out the plate with the specified rowId
        const filteredPlates = prevState.filter((plate: any) => plate.slno !== rowId);
    
        // Update the slno of the remaining plates
        const updatedPlates = filteredPlates.map((plate: any, index: number) => ({
          ...plate,
          slno: index + 1
        }));
    
        return updatedPlates;
      });

    }

    const handleCountChange = (slno : any, value : any) => {
      const updatedPlateBySerielNo = plateBySerielNo.map(plate => {
        if (plate.slno === slno) {
            return { ...plate, lot_pass_count: value };
        }
        return plate;
    });

    setPlateBySerielNo(updatedPlateBySerielNo);
    }

    const handleVisualInspectionModalFilter = (key: string, value: string) => {
      if(key === "status") {
        // setPlateStatus(value)
      } else if (key === "batch_code") {
        listModalVisualInspectionPlates(value)
      } else if (key === "lot_code") {
        getPlateDetailsByLotCode(value)
      }
    }


    useEffect(() => {
        if (scanAreaRef.current) {
          scanAreaRef.current.focus();
        }
      }, [])

    useEffect(() => {
        // const data = generateSampleData(25);
        // setPlateDetails(data);
        var storedData = localStorage.getItem('userDetails');

        if(storedData) {
          var storedObject = JSON.parse(storedData);
          setUserId(storedObject[0].id)
          getPermissionByRole(storedObject[0].role_master_id);
        }

        listVisualInspectionPlates();
        listPlateBatch();
        listSerialNumber();
        listInspectionReasons();

        setUseEffectRequired(false);

      }, [useEffectRequired]);

  

  return (
    <div>
        <HeaderSection />
        <PageHeader pageHeader={t("visualInspectionTest.visualInspectionTest")} />
        <CustomToast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={5000}
                message={toastMessage}
                toastType={toastType}
            /> 

      {tab === "1" && (
            <div>
                <div className=' mx-3 pe-2' style={{"height":"35vh"}}>
                        <CustomTable  
                            data={plateDetails} 
                            columns={columns} 
                            isEditable={false} 
                            totalElements={totalElements}  
                            isActionVisible={false} 
                            isViewVisible={false} 
                            removable={false}
                            isSeachable={true}
                            tableHead={t("visualInspectionTest.visualInspectionTest")}
                            addButton={permission?.includes("add_visual_inspection")}
                            addButtonText={t("visualInspectionTest.visualInspectionTest")}
                            onAddClick={() => setShow(true)}
                            plateBatch={plateBatches}
                            plateSerialNumber={plateSerielNum}
                            showLotCodeFilter={true}
                            lotCodeNumber={plateSerielNum}
                            onFilterClick={handleVisualInspectionFilter}
                        />
                    </div>
                    <div>
                      <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>

            </div>
      )}

<Modal
      className="top-right-modal"
      centered={false}
      show={show}
      size="lg"
    >
      <div>
        <div className="modalHead">
          <p className="modalHeadText ms-3 pt-3 p-2" >{t("visualInspectionTest.visualInspectionTest")}</p>
          <div onClick={() => visualInspectionClose()} className="p-2 mx-3 my-2 ">
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>
        <div>

        <div className='d-flex'>
        <div className="scanWrap p-3" style={{"width": "45%"}}>
          <div className="scanInputWrap p-2">
            <input
              onChange={(e) => handleScanSerielNo(e.target.value)}
              placeholder="Scan Only"
              value={scanCode}
            />
            {scanCode && (
                <CiCircleRemove 
                  onClick={() => setScanCode("")} 
                  fontSize={30} 
                  color="red"
                  className="mx-2 my-1"
                  style={{"cursor": "pointer"}}
                />
              )}

            <div className="scanBg ">
              <BsQrCodeScan />
            </div>
          </div>
          {/* {errors.scan_plate && (
            <p className="errorText p-0 m-0">{errors.scan_plate}</p>
          )} */}
        </div>

        <div className="scanWrap p-3" style={{"width": "55%"}}>
                {/* <label style={{"display": "flex"}}>Search Area / Location / Equipment QR Code</label> */}
                <div className="scanInputWrap p-2">
                <input
                    onChange={(e) => setSelectedSerielNo(e.target.value)}
                    placeholder="Type & Search"
                />
                < IoSearchSharp 
                    fontSize={25} 
                    className='mt-1' 
                    color='grey'
                    style={{"cursor":"pointer"}} 
                    onClick={() => getPlateDetailsByLotCode(selectedSerielNo)}
                />
                </div>
            </div>
        </div>

        <div className=' mx-3 pe-2' style={{"height":"25vh"}}>
                        <CustomTable  
                            data={plateBySerielNo} 
                            columns={columnsModalTable} 
                            isEditable={false} 
                            totalElements={plateBySerielNo.length}  
                            isActionVisible={false} 
                            isViewVisible={false} 
                            removable={true}
                            isSeachable={false}
                            tableHead={t("visualInspectionTest.visualInspectionTest")}
                            plateBatch={plateBatches}
                            // plateSerialNumber={plateSerielNum}
                            lotCodeNumber={plateSerielNum}
                            // areaListNumber={areaTypeNum}
                            // onStartClick={handleStartClick}
                            // onEndClick={handleEndClick}
                            onFilterClick={handleVisualInspectionModalFilter}
                            onRemoveClick={handleRemoveClick}
                            onCountChange={handleCountChange}
                            // onSortClick={handleSort}
                        />
                    </div>

                    <div style={{visibility:"hidden"}}>
                      <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>

         <div className="selectWrap">
            <div className="form-group my-1 mx-3 px-1" style={{"visibility": "hidden"}}>
                    <label htmlFor="">
                      Test Result
                    </label>
                    <br />
                    <select
                      name="plate_inspection_status"
                      value={formData.plate_inspection_status}
                      onChange={handleChange}
                    >
                      <option value="">select</option>
                      <option value="passed">Passed</option>
                      <option value="failed">Failed</option>
                    </select>
              </div>         
            <div className="form-group my-1 mx-3">
            <label htmlFor="">
                      {t("Exception.reason")}
                    </label>
                    <br />
                    <select
                      name="reason"
                      value={formData.reason}
                      onChange={handleChange}
                    >
                      <option value="">select</option>
                      {inspectionReasons.map((obj : any, index) => (
                        <option key={index} value={obj?.id}>
                          {obj?.reason}
                        </option>
                      ))}
                    </select>
                    {errors.reason && (
                      <p className="errorText m-0 p-0">{errors.reason}</p>
                    )}
            </div>
            <div className="form-group ms-2 ps-1 ">
                    <label htmlFor="">
                      {t("Exception.remarks")}
                    </label>
                    <br />
                    <textarea
                      placeholder="Remarks"
                      className="otherReasonTextArea p-2"
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChange}
                    />
                  </div>   
          </div>

       
        <div className="bottomArea">
          <button
            onClick={() => visualInspectionClose()}
            className="modalCancelBtn"
          >
            {t("buttons.cancel")}
          </button>
          <button
            onClick={() => handleVisualInspectionTestAlert()}
            className="squareOrangeBtn3"
          >
            {t("buttons.submit")}
          </button>
          {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}
        </div>
      </div>
      </div>
    </Modal>

    {/* <PlateDetailsViewModel 
      showView={show} 
      setShowView={(value: boolean) => setShow(value)}
      plateId='52'
    /> */}


     {/*Alert for Bulk upload */}
     <AlertModal 
          show={showVisualInspectionTestAlert}
          onHide={() => setShowVisualInspectionTestAlert(false)}
          title='Alert'
          message={`Start visual inspection test`}
          onConfirm={handleVisualTestSubmit}
        />
    </div>
  )
}

export default VisualInspectionTest;