import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );



//get all plate list
const getAreaList = async (page :number, size :number, sort :string, order : string, keyWord:any, type:string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/location/list_all?page=${page}&size=${size}&sort=${sort}&order=${order}&status=active,inactive&keyword=${keyWord}&type=${type}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
};


const getAreaByScan = async (
  id: any,
  location_type: any,
) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/scan/location` ,{
      id: id,
      area_location_code: location_type,
    },{
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const listAreaType= async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/location/list_all?page=1&size=10&sort=id&order=asc&status=active&keyword=&type=6,10`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getLocationPolicy = async (page :number, size :number, sort :string, order : string, keyWord:any, location_type:string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/policies/list?page=${page}&size=${size}&sort=${sort}&order=${order}&status=active,inactive&keyword=${keyWord}&location_type=${location_type}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
};

const getLocationStatus = (status: number): string => {
  switch (status) {
    case 1:
      return "PLANT";
    case 2:
      return "UNIT";
    case 3:
      return "BUILDING / BLOCK";
    case 4:
      return "LINE";
    case 5:
      return "AREA";
    case 6:
      return "PRODUCTION AREA";
    case 7:
      return "INCUBATION AREA";
    case 8:
      return "LAB AREA";
    case 9:
      return "AUTOCLAVE AREA";
    case 10:
      return "FINGER DAB ";
    case 11:
      return "ACTIVE AIR SAMPLING";
    case 12:
      return "INCUBATOR";
    case 13:
      return "Surface Monitoring";
    case 14:
      return "PLATE ISSUE AREA";
    case 15:
      return "PASSIVE AIR SAMPLING";
    case 17:
      return "CHANGING ROOM AREA"
    default:
      return "UNKNOWN STATUS";
  }
};

const changePolicyStatus = async (policy_id: string, status: string , interface_name : string) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/policies/change_status`, {
      id: policy_id,
      status: status,
      interface_name: interface_name
    },{
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getSchedulePolicy = async (page :number, size :number, sort :string, order : string, keyWord:any, location_type:number) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/schedules/list?page=${page}&size=${size}&sort=${sort}&order=${order}&status=active,inactive&keyword=${keyWord}&location_type=${location_type}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
};

const changeScheduleStatus = async (id: string, status: string, interface_name : string) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/schedules/change_status`, {
      id: id,
      status: status,
      interface_name : interface_name
    },{
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getParentAreaList = async ( type:number[]) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/location/list_all?page=&size=&sort=&order=asc&status=active&keyword=&type=${type}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
};

const getPolicyNameList = async ( type:number) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/policies/list?page=1&size=10&sort=id&order=asc&status=active&keyword&location_type=${type}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
};

const createArea = async (dataToSend:any) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/location/create`, 
     dataToSend,
     {
       headers: {
         'x-auth-token': authToken,
         'x-userid': authUserID
       }
     }
    );
    return response.data;
  }catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
}
}

const getExposurePointList = async (parent: string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/location/list_all?page=1&size=10&sort=id&order=asc&status=active&keyword=&type=11&parent_area=${parent}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

const getAreaId = async (Id:string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/location/get_details_by_id?id=${Id}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const changeAreaStatus = async (area_id: string, status: string, interface_name : string) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/location/change_status`, {
      id: area_id,
      status: status,
      interface_name : interface_name
    },{
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getAddAreaType= async (type : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/location/list_all?page=1&size=10&sort=id&order=asc&status=active&keyword=&type=${type}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const geAreaDropDownList = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/master/list_all_location_types?status=active&category=0&type_code=6,8,17`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const geAreaDropDownPolicyList = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/master/list_all_location_types?status=active&category=0&type_code=6,8,9,12`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const geLocationDropDownList = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/master/list_all_location_types?status=active&category=1&type_code=`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getHeirarchyType= async (type : string, parent_area: string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/location/list_all?page=1&size=100&sort=id&order=asc&status=active&keyword=&type=${type}&parent_area=${parent_area}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getLocationPointList = async (parent: string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/location/list_all?page=1&size=10&sort=id&order=asc&status=active&keyword=&type=10,11,13,15&parent_area=${parent}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

const getIncubatorList = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/location/list_area_equipments?sort=id&order=asc&status=active&keyword=&type=12&parent_area=`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getPlateGradeList = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/master/list_all_plate_grades?status=active`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
};

const getAreaCategoryList = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/area_category/list_all_area_category?&sort=id&status=active,inactive&order=asc&id=`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}

const areaService = {
  getAreaList,
  // getAreaStatus,
  getAreaByScan,
  listAreaType,
  getLocationPolicy,
  getLocationStatus,
  changePolicyStatus,
  getSchedulePolicy,
  changeScheduleStatus,
  getParentAreaList,
  getPolicyNameList,
  createArea,
  getExposurePointList,
  getAreaId,
  changeAreaStatus,
  getAddAreaType,
  geAreaDropDownList,
  getHeirarchyType,
  geLocationDropDownList,
  getLocationPointList,
  getIncubatorList,
  getPlateGradeList,
  getAreaCategoryList,
  geAreaDropDownPolicyList
};

export { areaService };
