export const columns: TableColumn[] = [
  {
    Header: "tableCommon.sl_no",
    accessor: "slno",
  },
  {
    Header: "scheduleOperator.tableColumns.production_schedule_name",
    accessor: "production_schedule_name",
  },
  {
    Header: "scheduleOperator.tableColumns.operator_name",
    accessor: "operator_name",
  },
  {
    Header: "scheduleOperator.tableColumns.start_date_time",
    accessor: "start_date_time",
  },
  {
    Header: "scheduleOperator.tableColumns.end_date_time",
    accessor: "end_date_time",
  },
  {
    Header: "scheduleOperator.tableColumns.activity_type_name",
    accessor: "activity_type_name",
  },
  {
    Header: "scheduleOperator.tableColumns.process_name",
    accessor: "process_name",
  },
  {
    Header: "scheduleOperator.tableColumns.created_at",
    accessor: "created_at",
  },
];

interface TableColumn {
  Header: string;
  accessor: string;
}
