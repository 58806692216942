import React, {useState} from 'react'
import DownloadPDFTab from '../dashbord/partials/DownloadPDFTab';
import ExposureDetailsLayout from './partials/ExposureDetails';
import LabtestDetailsLayout from './partials/LabtestDetails';
import IncubationDetailsLayout from './partials/IncubationDetail';
import AutoclaveDetailsLayout from './partials/AutoclaveDetails';
import {useFilter} from '../dashbord/partials/FilterContext'
import { useTranslation } from 'react-i18next';


interface HistoricalDashboardProps {
  showFilters?: boolean;
}

const HistoricalDashboard: React.FC<HistoricalDashboardProps> = ({showFilters}) => {
    const [selectedDate, setSelectedDate] = useState<string>("");
    const { filters } = useFilter();

    const { t } = useTranslation();

  return (
    <div>
      {!showFilters && (
        <DownloadPDFTab
            printDivId='dashWrap'
            // setSelectedDate={setSelectedDate}
            showFilters={true}
          />
      )}
        
          <div className='row' id='dashWrap'>
            <div id='exposure-head' className='col-md-4 exposure-head'>
              <div 
                className='row mb-3 ms-3 mt-3'>
                  <ExposureDetailsLayout selectedDate={filters} heading={t("common.exposure")}/>
              </div>
              <div 
                className='row mb-3 ms-3 mt-3'>
                  <LabtestDetailsLayout selectedDate={filters} heading={t("common.lab_test")}/>
                </div>
            </div>
            <div className='col-md-4'>
              <div 
                className='row mb-3 ms-3 mt-3'>
                  <IncubationDetailsLayout selectedDate={filters} heading={t("common.incubation")}/>
                </div>
              <div 
                className='row mb-3 ms-3 mt-3'>
                  <AutoclaveDetailsLayout  selectedDate={filters} heading={t("common.auto_clave")}/>
                </div>
            </div>
 
          </div>
    </div>
  )
}

export default HistoricalDashboard;