import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import HeaderSection from '../../../common/headerSection';
import PageHeader from '../../../common/pageHeader';
import { useTranslation } from 'react-i18next';

const OperatorSchedule = () => {
  const { t } = useTranslation();


  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);


  const handleDateChange =( date : any )=> {
    setSelectedDate(date);
  };

  const handleTimeSlotClick = (time: any) => {
    console.log('Clicked time:', time);
    const currentTime = new Date();
    if (time > currentTime) {
      setSelectedTime(time);
      setShowModal(true);
    }
  };

  useEffect(() => {
      console.log('Modal state:', showModal);
  }, [showModal]);


    const renderTimeSlots = () => {
        const timeSlots = [];
        const currentHour = new Date().getHours(); // Get current hour
        const currentMinute = new Date().getMinutes(); // Get current minute
        for (let hour = 0; hour <= 23; hour++) {
          for (let minute = 0; minute < 60; minute += 60) {
            const time = new Date(selectedDate);
            time.setHours(hour);
            time.setMinutes(minute);
            const isCurrentHour = hour === currentHour; // Check if this time slot represents the current hour
            const isFutureTime = time > new Date(); // Check if the time slot is in the future
            timeSlots.push(
              <div
                key={`${hour}-${minute}`}
                className={`time-slot ${isCurrentHour ? 'current-hour' : ''} ${isFutureTime ? 'future-time' : ''}`}
                onClick={() => handleTimeSlotClick(time)}
              >
                <div className="time">{time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
              </div>
            );
          }
        }
        return timeSlots;
      };
   
   


  return (
    <div>
    <HeaderSection />
    <PageHeader pageHeader={t('dashboard.title')} />
    <div className="operator-schedule">
      <div className="calendar-container">
        <Calendar onChange={handleDateChange} value={selectedDate} />
      </div>
      <div className="time-slots-container">
        {renderTimeSlots()}
      </div>
    </div>

    <div className="status-containers">
      <div className="status-column">
        <div className="pending-container">
          {/* Content for Pending container */}
          <h2>Pending</h2>
          {/* Add your content here */}
        </div>
      </div>
      <div className="status-column">
        <div className="inprogress-container">
          {/* Content for In Progress container */}
          <h2>In Progress</h2>
          {/* Add your content here */}
        </div>
      </div>
      <div className="status-column">
        <div className="completed-container">
          {/* Content for Completed container */}
          <h2>Completed</h2>
          {/* Add your content here */}
        </div>
      </div>
    </div>





    </div>
  );
};

export default OperatorSchedule;

