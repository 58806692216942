export const columns: TableColumn[] = [
    {
        Header: "Sl no",
        accessor: "slno",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      // },
      
]


interface TableColumn {
    Header: string;
    accessor: string;
  }