import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Signin from './screens/Signin';
import Navbar from './components/navbar';
import Dashboard from './components/admin/dashbord';
import SplashScreen from './screens/SplashScreen';
import { Provider } from 'react-redux';
import store from '../src/store/store';

function App() {
  return (
    <Provider store={store}>
      <SplashScreen />
    </Provider>
  );
}

export default App;
