import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );

const createEquipments = async (dataToSend: any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/equipments/create`, dataToSend,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}

const listEquipments = async (page : any, pageSize: any , keyword: string, type_id:string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/equipments/list_all?page=${page}&size=${pageSize}&sort=&order=desc&status=active&keyword=${keyword}&type_ids=${type_id}&id`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}

const listEquipmentTypes = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/master/list_equipment_types?sort=id&order=asc&status=active&keyword=&type_ids=&parent_area=&id`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const listEquipmentFilter = async (type_id:string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/equipments/list_all?sort=&order=desc&status=active&keyword=&type_ids=${type_id}&id`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}


const equipments = {
    createEquipments,
    listEquipments,
    listEquipmentTypes,
    listEquipmentFilter
};
  
  
export { equipments };