
//table columns for lab test 
export const columns: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'slno',
      },
      {
        Header: 'tableCommon.batch_code',
        accessor: 'batch_number',
      },
      {
        Header: 'tableCommon.seriel_number',
        accessor: 'serial_number',
      },
      {
        Header: 'Lab Test Done On',
        accessor: 'labTestDoneOn',
      },
      {
        Header: 'Colony Count',
        accessor: 'colonyCount',
      },
      {
        Header: 'Test Remarks',
        accessor: 'testRemarks',
      },
      // {
      //   Header: 'Exposure Area name',
      //   accessor: 'exposureAreaName',
      // },
      // {
      //   Header: 'Incubation Duration',
      //   accessor: 'incubationDuration',
      // },
      // {
      //   Header: 'Incubated By',
      //   accessor: 'incubatedBy',
      // },
      // {
      //   Header: 'Incubation Area name',
      //   accessor: 'incubationAreaName',
      // },
      // {
      //   Header: 'Lab Test Done by',
      //   accessor: 'labtestUser',
      // },
      // {
      //   Header: 'Lab Test Done Date',
      //   accessor: 'labTestCompletionDateTime',
      // },
      // {
      //   Header: 'Plate Status',
      //   accessor: 'PlateStatus',
      // }, 
  ];
  
  interface TableColumn {
    Header: string;
    accessor: string;
  }
  