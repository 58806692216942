import React, { useEffect, useState } from 'react'
import HeaderSection from '../../common/headerSection'
import PageHeader from '../../common/pageHeader'
import { useTranslation } from 'react-i18next';
import Pagination from '../../../widgets/pagination';
import CustomTable from '../../../widgets/table';
import { HistoryColumns, columns } from './columns';
import { userService } from '../../../service/plateServices';
import moment from 'moment';
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsQrCodeScan } from 'react-icons/bs';
import { areaService } from '../../../service/areaServices';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import AddException from '../../common/addException';
import { SerielNumberScanner } from '../common/serielNumberScanner';
import { FaEye } from 'react-icons/fa';
import LabReportViewModel from '../../lab_operator/labReport/models/labReportViewModel';
import ImageSlider from '../../lab_operator/labReport/partials/imageSlider';
import CameraApp from '../labtestArea/partials/cameraModal';
import baseUrl from '../../../service';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { floraServices } from '../../../service/fileServices/environmentFlora';
import CustomToast from '../../../widgets/toaster';
import AlertModal from '../../../widgets/alertModal';
import { FaGalacticSenate, FaLinesLeaning } from 'react-icons/fa6';


const EnvironmentalFlora = () => {

    const [tab, setTab] = useState("1");
    const { t } = useTranslation();
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(5)
    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0);
    const [useEffectRequired, setUseEffectRequired] = useState(false)
    const[pendingDataTable , setPendingDataTable] = useState<Array<any>>([]); 
    const [clickedSerialNumber, setClickedSerialNumber] = useState(null);
    const [scanCode, setScanCode] = useState("")
    const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
    const [userId, setUserId] = useState<number>(0);
    const [authToken , setAuthToken] = useState("");
    const [authUserID , setAuthUserID] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [serielScanShow, setSerielScanShow] = useState(false);
    const [show, setShow] = useState(false);
    const [labAreaName, setLabAreaName] = useState("");
    const areaId = useSelector((state: RootState) => state.area.areaId)|| '';
    const areaName = useSelector((state: RootState) => state.area.areaName) || '';

    const [showView, setShowView] = useState(false);
    const [singlePlateDetails, setSinglePlateDetails] = useState<any>({});
    const [incubationDetails, setIncubationDetails] = useState<any>([]);
    const [exposureDetails, setExposureDetails] = useState<any>([]);
    const [fingureDabDetails, setFingureDabDetails] = useState<any>([]);
    const [plateSamplingDetails, setPlateSamplingDetails] = useState<any>([]);
    const [rawMaterialDetails, setRawMaterialDetails] = useState<any>([]);
    const [surfaceMonitoringDetails, setSurfaceMonitoringDetails] = useState<any>([]);
    const [labTestDetails, setLabTestDetails] = useState<any>([]);
    const [currentExposureIndex, setCurrentExposureIndex] = useState(0);
    const [currentSamplingIndex, setCurrentSamplingIndex] = useState(0);
    const [currentRawmaterialTestIndex, setCurrentRawmaterialTestIndex] = useState(0);
    const [currentFingerDabIndex, setCurrentFingerDabIndex] = useState(0);
    const [currentIncubationIndex, setCurrentIncubationIndex] = useState(0);
    const [currentLabTestIndex, setCurrentLabTestIndex] = useState(0);
    const [currentSurfaceMonitoringIndex, setCurrentSurfaceMonitoringIndex] = useState(0);
    const [plateId, setPlateId] = useState("");
    const [capturedImageId, setCapturedImageId] = useState<any | null>("");
    const [zoomLevel, setZoomLevel] = React.useState(1)
    const [imageIds, setImageIds] = useState([]);
    const [colonyCount, setColonyCount] = useState<number>(0);
    const [areaAlert , setAreaAlert] = useState(false);
    const [savelabId , setLabId] = useState("");

    const [shape, setShape] = useState<Array<any>>([]);
    const[type , setType] = useState<Array<any>>([]);  
    const[color , setColor] = useState<Array<any>>([]);
    const[genus , setGenus] = useState<Array<any>>([]); 
    const[species , setSpecies] = useState<Array<any>>([]);  
    const[strain , setStrain] = useState<Array<any>>([]); 

    const [pageHistory, setPageHistory] = useState(1)
    const [pageSizeHistory, setPageSizeHistory] = useState(5)
    const [totalPagesHistory, setTotalPagesHistory] = useState(0)
    const [totalElementsHistory, setTotalElementsHistory] = useState(0);
    const[historyDataTable , setHistoryDataTable] = useState<Array<any>>([]); 

    const [selectedOrganismTypes, setSelectedOrganismTypes] = useState<Array<any>>([]);
    const [selectedGenus, setSelectedGenus] = useState<Array<any>>([]);
    const [selectedSpecies, setSelectedSpecies] = useState<Array<any>>([]);
    const [savedLabId, setSavedLabId] = useState("");
    const [plateBatches, setPlateBatches] = useState([]);
    const [plateSerielNum, setPlateSerielNums] = useState([]);
    const [keyWord, setkeyWord] = useState("");
    const [activityType, setActivityType] = useState("");

    const [showHistoryView, setShowHistoryView] = useState(false);




  



    interface FormDataItem {
      [key: string]: string | number;
      i: number;
    }
    const [formData, setFormData] = useState<FormDataItem[]>([]);
    const [students, setStudents] = useState<Array<any>>([]);
   

    const handlePageChange = (newPage : any, pageSize?: number) => {
      setPage(newPage)
        if(pageSize) {
          setPageSize(pageSize)
        }
        setUseEffectRequired(true)
    };

    const handleHistoryPageChange = (newPage : any, pageSize?: number) => {
      setPageHistory(newPage)
      if(pageSize) {
        setPageSizeHistory(pageSize)
      }
      setUseEffectRequired(true)
  };

    useEffect(() => {
        var storedData = localStorage.getItem('userDetails');
  
        if(storedData) {
          var storedObject = JSON.parse(storedData);
          setUserId(storedObject[0].id)
          setAuthToken(storedObject[0].x_auth_token)
          setAuthUserID(storedObject[0].x_userid)
        }
        getPendingPlates();
        HistoryFloraPlates();
        getAreaByScan(areaId, areaName);
        listPlateBatch();
        listSerialNumber();
        setUseEffectRequired(false)
      }, [useEffectRequired]);

    useEffect(() => {
      setSelectedOrganismTypes(Array.from({ length: colonyCount }, () => ""));
      setSelectedGenus(Array.from({ length: colonyCount }, () => ""));
      setSelectedSpecies(Array.from({ length: colonyCount }, () => ""));
      setStudents(
        Array.from({ length: colonyCount }, (_:any, index: number) => ({
          colony_order: (index + 1).toString(),
          // colony_shape_id: "",
          // colony_colour_id: "",
          // colony_size: "",
          description: "",
          organism_type_id: "",
          genus_id: "",
          species_id: "",
          strain_id: "",
        }))
      );
    }, [colonyCount]);

    //api call to get pending plate list
    const getPendingPlates = async () => {
      try {
        const response = await floraServices.PendingFloraPlates(page, pageSize, areaId, activityType, keyWord);
        if(response?.status && response?.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages)
                setTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {

                  const plateType = parseInt(element.plate_type, 10); 
                      if (plateType == 1){
                        var plate_type = "Q A Plate"
                      }else if (plateType == 2){
                        var plate_type = "Exposure Plate"
                      }else if (plateType == 3){
                        var plate_type = "Finger DAB Plate"
                      }else if (plateType == 4){
                        var plate_type = "Sampling Plate"
                      }else if (plateType == 5){
                        var plate_type = "Material Test Plate"
                      }else if (plateType == 6){
                        var plate_type = "Surface Monitoring Plate"
                      }else if (plateType == 7){
                        var plate_type = "Surface Monitoring - SWAB Collection"
                      } else{
                        var plate_type = "-"
                      }

                  return { 
                      sl_no: index + 1, 
                      id: element.id,
                      serial_number: element.serial_number,
                      batch_number: element.batch_number,
                      activity_type : plate_type,
                  };
                });
                setPendingDataTable(rowData);
              }
            } catch (error) {
              console.log(error);
            }
        } else {
          setPendingDataTable([]);
        }
      } catch (error) {
        console.error('user response', error);
      }
    };

    //api call to get shapes
    const listShapesOptions = async () => {
      try {
        const response = await floraServices.listShapesOptions();  
        if (response?.status && response?.statusCode === 200) {
          const rowData = response.data.map((element:any, index:any) => ({
            sl_no: index + 1,
            id: element.id,
            shape_name: element.shape_name
          }));
          setShape(rowData);
        } else {
          setShape([]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    //api call to get color
    const listColorOptions = async () => {
      try {
        const response = await floraServices.listColorOptions();  
        if (response?.status && response?.statusCode === 200) {
          const rowData = response.data.map((element:any, index:any) => ({
            sl_no: index + 1,
            id: element.id,
            colony_colour: element.colony_colour
          }));
          setColor(rowData);
        } else {
          setColor([]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    //api call to get type
    const listTypeOptions = async () => {
      try {
        const response = await floraServices.listTypeOptions();  
        if (response?.status && response?.statusCode === 200) {
          const rowData = response.data.map((element:any, index:any) => ({
            sl_no: index + 1,
            id: element.id,
            organism_type: element.organism_type
          }));
          setType(rowData);
        } else {
          setType([]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

      //handle table filters
    const handleFilter = (key: string, value: string) => {
      console.log(key, value, "values--")
      if(key === "search") {
        setkeyWord(value)
      } else if (key === "Plate Type") {
        setActivityType(value)
      }
      setUseEffectRequired(true)
    }

    //batch list for filter dropdown 
    const listPlateBatch = async () => {
      try {
        const response = await userService.listPlateBatch();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.batch_number,
                      label: element.batch_number ,
                  };
                });
                setPlateBatches(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    //serial number listing for table drop down 
    const listSerialNumber = async () => {
      try {
        const response = await userService.listSerialNumber();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.serial_number,
                      label: element.serial_number ,
                  };
                });
                setPlateSerielNums(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }


    //api call to get genus
    const handleOrganismTypeChange = async (selectedTypeId: string, i: number, id: string) => {
      try {
        const response = await floraServices.listGenusOptions(selectedTypeId);
        if (response?.status && response?.statusCode === 200) {
          const rowData = response.data.map((element:any, index:any) => ({
            sl_no: index + 1,
            id: element.id,
            genus_name: element.genus_name,
            organism_type_id: i + 1
          }));

          // Group data by organism_type_id
          const groupedData: { [key: number]: any[] } = {};
          rowData.forEach((row: any) => {
            const { organism_type_id } = row;
            if (!groupedData[organism_type_id]) {
              groupedData[organism_type_id] = [];
            }
            groupedData[organism_type_id].push(row);
          });

          // Iterate through grouped data and remove duplicates
          Object.keys(groupedData).forEach((key: string) => {
            const typeId = parseInt(key);
            const group = groupedData[typeId];
            if (group.length > 0) {
              // Remove duplicates
              const uniqueRows = group.reduce((acc: any[], current: any) => {
                if (!acc.find((item) => item.id === current.id)) {
                  acc.push(current);
                }
                return acc;
              }, []);
              // Update genus state
              setGenus((prevGenus) => [
                ...prevGenus.filter((genusItem) => genusItem.organism_type_id !== typeId),
                ...uniqueRows,
              ]);
            }
          });

          // setGenus(rowData);
          // setGenus(prevGenus => [...prevGenus, ...rowData]);
        } else {
          let organismTypeId = i + 1
          setGenus((prevGenus) => [
            ...prevGenus.filter((genusItem) => genusItem.organism_type_id !== organismTypeId)
          ]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const handleGenusTypeChange = async (selectedTypeId: string, i: number, id: string) => {
      try {
        const response = await floraServices.listSpeciesOptions(selectedTypeId);
        if (response?.status && response?.statusCode === 200) {
          const rowData = response.data.map((element:any, index:any) => ({
            sl_no: index + 1,
            id: element.id,
            name: element.name,
            genus_id: i + 1
          }));

           // Group data by genus_id
           const groupedData: { [key: number]: any[] } = {};
           rowData.forEach((row: any) => {
             const { genus_id } = row;
             if (!groupedData[genus_id]) {
               groupedData[genus_id] = [];
             }
             groupedData[genus_id].push(row);
           });
 
           // Iterate through grouped data and remove duplicates
           Object.keys(groupedData).forEach((key: string) => {
             const typeId = parseInt(key);
             const group = groupedData[typeId];
             if (group.length > 0) {
               // Remove duplicates
               const uniqueRows = group.reduce((acc: any[], current: any) => {
                 if (!acc.find((item) => item.id === current.id)) {
                   acc.push(current);
                 }
                 return acc;
               }, []);
               // Update species state
               setSpecies((prevSpecies) => [
                 ...prevSpecies.filter((speciesItem) => speciesItem.genus_id !== typeId),
                 ...uniqueRows,
               ]);
             }
           });

          // setSpecies(rowData);
          // setSpecies(prevSpecies => [...prevSpecies, ...rowData]);
        } else {
          let genusId = i + 1
          setSpecies((prevSpecies) => [
            ...prevSpecies.filter((speciesItem) => speciesItem.genus_id !== genusId)
          ]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const handleSpeciesTypeChange = async (selectedTypeId: string, i: number, id: string) => {
      try {
        const response = await floraServices.listStrainOptions(selectedTypeId);
        if (response?.status && response?.statusCode === 200) {
          const rowData = response.data.map((element:any, index:any) => ({
            sl_no: index + 1,
            id: element.id,
            name: element.name,
            species_id: i + 1
          }));

          // Group data by species_id
          const groupedData: { [key: number]: any[] } = {};
          rowData.forEach((row: any) => {
            const { species_id } = row;
            if (!groupedData[species_id]) {
              groupedData[species_id] = [];
            }
            groupedData[species_id].push(row);
          });

          // Iterate through grouped data and remove duplicates
          Object.keys(groupedData).forEach((key: string) => {
            const typeId = parseInt(key);
            const group = groupedData[typeId];
            if (group.length > 0) {
              // Remove duplicates
              const uniqueRows = group.reduce((acc: any[], current: any) => {
                if (!acc.find((item) => item.id === current.id)) {
                  acc.push(current);
                }
                return acc;
              }, []);
              // Update strain state
              setStrain((prevStrain) => [
                ...prevStrain.filter((strainItem) => strainItem.species_id !== typeId),
                ...uniqueRows,
              ]);
            }
          });


          // setStrain(rowData);
          // setStrain(prevStrain => [...prevStrain, ...rowData]);
        } else {
          let speciesId = i + 1
          setStrain((prevStrain) => [
            ...prevStrain.filter((strainItem) => strainItem.species_id !== speciesId)
          ]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const onNavigateFloraClick = (data: any) => {
        setTab("2");
        setClickedSerialNumber(data);
        handleScanCodeChange(data)
    }

    //pass the serial number if it is entered in text area 
    const handleScanCodeInput = (result: any) => {
        try {
          const parsedResult = JSON.parse(result);
          if (typeof parsedResult === 'object') {
            if (parsedResult.hasOwnProperty('serial_number')) {
              handleScanCodeChange(parsedResult.serial_number)
            }
          }
        } catch (error) {
          handleScanCodeChange(result)
          console.error('Error parsing JSON:', error);
        }
      }

    //fetch plate details by serial number in scan plate area 
    const handleScanCodeChange = async (e: any) => {
      setScanCode(e);
      if (e.trim() === "") {
        setPlateDetails([]);
      } else {
        try {
          const response = await userService.listAutoClavePlateBySerielNo(e, "environmentalFlora");
          if (response?.status && response?.statusCode === 200) {
            try {
              let rowData: any;
              // const checkIfSameUser = response.data.lab_test_details[0]?.test_done_by_user_id === userId ? 1 : 0
              if (response.data) {
                const element = response.data;
                              interface Exposure {
                                 id: string;
                                 start_date_time: string;
                                 end_date_time: string;
                                 updated_by_first_name: string;
                                 updated_by_last_name: string;
                                 exposure_area_name: string;
                               }
                               // Calculate total exposed time
                               const exposureDetails : Exposure[] = element.exposure_details || [];
                               let totalExposedTime = 0;
               
                               exposureDetails.forEach((exposure) => {
                                 const startDate = new Date(exposure.start_date_time);
                                 const endDate = new Date(exposure.end_date_time);
                                 const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                                 totalExposedTime += timeDifferenceMs;            
               
                               });
                               const totalExposedTimeHours = Math.floor(totalExposedTime / 3600000);
                               const totalExposedTimeMinutes = Math.floor(
                                 (totalExposedTime % 3600000) / 60000
                               );
                               const totalExposedTimeSeconds = Math.floor((totalExposedTime % 60000) / 1000);
               
                               const fullNames = exposureDetails.map((exposure : Exposure) => {
                                 return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
                               });
               
                               //caluculate the total incubated time 
                               interface Incubation {
                                 id: string;
                                 start_date_time: string;
                                 end_date_time: string;
                                 created_by_first_name: string;
                                 updated_by_first_name: string;
                                 updated_by_last_name: string;
                               }
                               const incubationDetails : Incubation[] = element.incubation_details || [];
                               let totalIncubatedTime = 0;
                               incubationDetails?.forEach((incubation)=>{
                                 const startDate = new Date(incubation.start_date_time);
                                 const endDate = incubation.end_date_time ? new Date(incubation.end_date_time) : null;
                                 if (endDate) {
                                   const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                                   totalIncubatedTime += timeDifferenceMs;
                                 }
                               });
                               const totalIncubatedTimeHours = Math.floor(totalIncubatedTime / 3600000);
                               const totalIncubatedTimeMinutes = Math.floor(
                                 (totalIncubatedTime % 3600000) / 60000
                               );
               
                               const fullNamesIncubation = incubationDetails.map((incubation : Incubation) => {
                                 return `${incubation.updated_by_first_name} ${incubation.updated_by_last_name}`;
                               });
                               const incubated_user = fullNamesIncubation.join(", ");
              
                               const exposure_name = exposureDetails.map((exposure : Exposure) => {
                                return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
                              });
                              const exposed_user_name = exposure_name.join(", ");
                             
                             interface Labtest {
                                colony_count: string;
                                plate_image_id: string;
                                id: string
                              }
                              // Calculate total exposed time
                              const labDetails : Labtest[] = element.lab_test_details || [];                      
                              let highestColonyCount: number = 0;
                              let correspondingPlateImageIds: any = [];
                              labDetails.forEach((labTest, index) => {
                              const colonyCount = parseInt(labTest.colony_count);
                              const labSaveId = labTest.id;
                                if (colonyCount > highestColonyCount || (colonyCount === highestColonyCount && index === 0)) {
                                    highestColonyCount = colonyCount;
                                    correspondingPlateImageIds = labTest.plate_image_id.split(',').map(id => parseInt(id.trim()));   
                                    setImageIds(correspondingPlateImageIds) 
                                    setCapturedImageId(correspondingPlateImageIds?.[0])
                                    setColonyCount(highestColonyCount)
                                    setSavedLabId(labTest.id)                        
                                }
                            });
                          rowData = {
                      "Plate Serial Num": element.serial_number,
                      "Batch ID": element.batch_number,    
                      "Plate Experiy Date":  element.expiry_date, 
              }
              setPlateId(element.id)
              scanPlateById(element.id)
            }
              setPlateDetails(rowData);
              listShapesOptions();
              listColorOptions();
              listTypeOptions();
            } catch (error) {
              console.log(error);
            }
          } else {
            setPlateDetails([]);
            setToastMessage(response.message);
            setToastType("error")
            setShowToast(true)
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      }
    };
    //on click cancel button in labtest 
    const handleClearBtn = () => {
      handleScanCodeChange("")
      setSinglePlateDetails({})
      setExposureDetails([])
      setFingureDabDetails([])
      setPlateSamplingDetails([])
      setRawMaterialDetails([])
      setIncubationDetails([])
      setLabTestDetails([])
      setSurfaceMonitoringDetails([])
      setPlateId("")
      setImageIds([])
      setCapturedImageId("")
      setColonyCount(0);
    }

    //top bar area name listing 
    const data: Record<string, string | number> = {
      "Area Name": labAreaName,
    };

    //get area details from scan qr 
    const getAreaByScan = async (id : any , location_type : any ) => {
        try {
          const response = await areaService.getAreaByScan(id, location_type);
          if(response?.status && response?.statusCode === 200){
            try {   
                setLabAreaName(response.data.lab_area_name);
              } catch (error) {
                console.log(error);
              }
          } 
        } catch (error) {
          console.error('user response', error);
        }
      };

    const handleConfirmSubmit = async () => {
      setAreaAlert(false)

      const dataToSend = {
        user_id: userId,
        plate_id: plateId,
        lab_id: savedLabId,
        colony_details: students,
        interface_name : "Add Environmental Flora"
      }  

    try {
      const response = await floraServices.saveEnvironmentFlora(dataToSend);
      if (response?.status && response?.statusCode === 200) {
        setToastType("success")
        setToastMessage(response.message)
        window.location.reload()
      } else {
        setToastType("error")
        setToastMessage(response.message)
      } 
      setShowToast(true);
      handleClearBtn()
      
    } catch (error) {
      console.error(error);
    }  
    }
    
    //toaster message after scaning plate 
    const handleScanCode = (serielNumber : any) => {
        if(serielNumber) {
          handleScanCodeChange(serielNumber)
          setToastMessage(`Plate Seriel scan successfull`);
          setToastType("success");
        } else {
          setToastMessage("Plate Seriel scan failed");
          setToastType("error");
        }
        setShowToast(true)
    }

    const scanPlateById = async (id:any, key?:string) => {
      const dataToSend = {
        "id" : id,
        "lab_area_id":areaId
      }
      let response
      if(key === "history") {
        response = await userService.scanPlateById({"id": id});
      } else {
        response = await floraServices.scanEnvironmentFloraById(dataToSend);
      }

      if(response?.status && response?.statusCode === 200){
       
        const plateType = parseInt(response?.data.plate_type, 10); 
                      if (plateType == 1){
                        var plate_type = "Q A Plate"
                      }else if (plateType == 2){
                        var plate_type = "Exposure Plate"
                      }else if (plateType == 3){
                        var plate_type = "Finger DAB Plate"
                      }else if (plateType == 4){
                        var plate_type = "Sampling Plate"
                      }else if (plateType == 5){
                        var plate_type = "Material Test Plate"
                      }else if (plateType == 6){
                        var plate_type = "Surface Monitoring Plate"
                      }else if (plateType == 7){
                        var plate_type = "Surface Monitoring - SWAB Collection"
                      } else{
                        var plate_type = "-"
                      }
  
        const statusNumber = parseInt(response?.data?.status, 10); 
                     if (statusNumber == 21){
                      var status = "pending"
                     }else{
                      var status = "unknown"
                     }
  
        const basicDetails = {
          "id": response.data.id,
          "serial_number": response.data.serial_number,
          "batch_number": response.data.batch_number,
          "plate_status": response.data.plate_status,
          "status": status,
          "expiry_date": response.data.expiry_date,
          "manufacturing_date": response.data.manufacturing_date,
          "current_location_id": response.data.current_location_id,
          "plate_type": plate_type,
          "accepted_colony_count" : `${response.data.min_count} - ${response.data.max_count}`
        }
  
        let exposureDetails:any = response.data?.exposure_details?.map((element: any, index: number) => {
          return {
            exposedAreaName : element.exposure_area_name || "-",
            exposedBy : element.created_by_first_name + " " + element.created_by_last_name || "-",
            exposedDate : element.start_date_time,
            collectedBy : element.updated_by_first_name + " " + element.updated_by_last_name || "-",
            collectedDate : element.end_date_time || "-",
          }
        })
  
        let fingureDabDetails = response.data?.fingerdab_details?.map((element: any, index: number) => {
          return {
            fingerDabAreaName : element.location_name || "-",
            fingerDabbededBy : element.completed_by || "-",
            dabbedDate : element.completed_date_time || "-",
            fingerDabType: element.finger_dab_option_id || "-",
            figerDabOption : element.finger_dab_option_id || "-",
  
          }
        })
  
        let plateSamplingDetails:any = response.data?.plate_sampling_details?.map((element: any, index: number) => {
          return {
            plateSamplingType : element.test_type_name || "-",
            sampledBy : element.ended_user_firstname + " " + element.ended_user_lastname || "-",
            sampledDate : element.completed_date_time || "-",
          }
        })
  
        let rawMaterialDetails:any = response.data?.raw_material_test_details?.map((element: any, index: number) => {
          return {
            rawMaterialTypeName : element.material_type_name || "-",
            conductedBy : element.ended_user_firstname + " " + element.ended_user_lastname || "-",
            testDate : element.completed_date_time || "-",
          }
        })
  
        let surfaceMonitoringDetails:any = response.data?.surface_monitoring_details?.map((element: any, index: number) => {
          return {
            surfaceMonitoringAreaName : element.location_name || "-",
            surfaceMonitoringStartDate : element.start_date_time || "-",
          }
        })
  
  
        let incubationDetails:any = response.data?.incubation_details?.map((element: any, index: number) => {
          return {
            incubationAreaName: element.incubator_name || "-",
            incubatedBy : element.created_by_first_name + " " + element.created_by_last_name || "-",
            incubationDateTime : element.start_date_time || "-",
            incubationOutBy : element.updated_by_first_name + " " + element.updated_by_last_name || "-",
            incubationoutDateTime : element.end_date_time || "-",
          }
        })
  
        let labTestDetails:any = response.data?.lab_test_details?.map((element: any, index: number) => {
          return {
            conductedBy: element.completed_by_user_firstname + " " + element.completed_by_user_lastname || "-",
            dateAndTime : element.completed_date_time || "-",
            colonyCount : element.colony_count || "-",
            imageId : element.plate_image_id || "",
            id: element.id || "-",
            species: element.species || "-",
            genus: element.genus || "-",
          }
        })
  
  
        setSinglePlateDetails(basicDetails)
        setExposureDetails(exposureDetails)
        setFingureDabDetails(fingureDabDetails)
        setPlateSamplingDetails(plateSamplingDetails)
        setRawMaterialDetails(rawMaterialDetails)
        setIncubationDetails(incubationDetails)
        setLabTestDetails(labTestDetails)
        setSurfaceMonitoringDetails(surfaceMonitoringDetails)
      }
    }

    const handleClose = () => {
      setShowView(false)
      // setSinglePlateDetails({})
      // setExposureDetails([])
      // setFingureDabDetails([])
      // setPlateSamplingDetails([])
      // setRawMaterialDetails([])
      // setIncubationDetails([])
      // setLabTestDetails([])
    };

    const handleNextClick = (currentIndex : number) => {
      if (currentIndex === 1){
        if (currentExposureIndex < exposureDetails.length - 1) {
          setCurrentExposureIndex(currentExposureIndex + 1);
        }
      } else if (currentIndex === 2) {
        if (currentIncubationIndex < incubationDetails.length - 1) {
          setCurrentIncubationIndex(currentIncubationIndex + 1);
        }
      } else if (currentIndex === 3) {
        if (currentLabTestIndex < labTestDetails.length - 1) {
          setCurrentLabTestIndex(currentLabTestIndex + 1);
        }
      } else if (currentIndex === 4) {
        if (currentFingerDabIndex < fingureDabDetails.length - 1) {
          setCurrentFingerDabIndex(currentFingerDabIndex + 1);
        }
      } else if (currentIndex === 5) {
        if (currentSamplingIndex < plateSamplingDetails.length - 1) {
          setCurrentSamplingIndex(currentSamplingIndex + 1);
        }
      } else if (currentIndex === 6) {
        if (currentRawmaterialTestIndex < rawMaterialDetails.length - 1) {
          setCurrentRawmaterialTestIndex(currentRawmaterialTestIndex + 1);
        }
      } else if (currentIndex === 7) {
        if (currentSurfaceMonitoringIndex < surfaceMonitoringDetails.length - 1) {
          setCurrentSurfaceMonitoringIndex(currentSurfaceMonitoringIndex + 1);
        }
      }
    };
  
    const handlePreviousClick = (currentIndex : number) => {
      if (currentIndex === 1){
        if (currentExposureIndex > 0) {
          setCurrentExposureIndex(currentExposureIndex - 1);
        }
      } else if (currentIndex === 2) {
        if (currentIncubationIndex > 0) {
          setCurrentIncubationIndex(currentIncubationIndex - 1);
        }
      } else if (currentIndex === 3) {
        if (currentLabTestIndex > 0) {
          setCurrentLabTestIndex(currentLabTestIndex - 1);
        }
      } else if (currentIndex === 4) {
        if (currentFingerDabIndex > 0) {
          setCurrentFingerDabIndex(currentFingerDabIndex - 1);
        }
      } else if (currentIndex === 5) {
        if (currentSamplingIndex > 0) {
          setCurrentSamplingIndex(currentSamplingIndex - 1);
        }
      } else if (currentIndex === 6) {
        if (currentRawmaterialTestIndex > 0) {
          setCurrentRawmaterialTestIndex(currentRawmaterialTestIndex - 1);
        }
      } else if (currentIndex === 7) {
        if (currentSurfaceMonitoringIndex > 0) {
          setCurrentSurfaceMonitoringIndex(currentSurfaceMonitoringIndex - 1);
        }
      }
    };

    const handleViewBtnClick = () => {
      setShowView(true)
    }

    const handleZoomIn = () => {
      setZoomLevel(zoomLevel + 0.1);
    };
  
    const handleZoomOut = () => {
      setZoomLevel(zoomLevel - 0.1);
    };
          
    // const generateFields = (shape: string[], color: string[], type: string[], genus: string[], species: string[], strain: string[]) => {
    //   const fields = [];
    //   const labels = ['Shape', 'Colour', 'Size', 'Description', 'Organism Type', 'Genus', 'Species', 'Strain'];
    //   const ids = ['colony_shape_id', 'colony_colour_id', 'colony_size', 'description', 'organism_type_id', 'genus_id', 'species_id', 'strain_id'];
    
    //   for (let i = 1; i <= colonyCount; i++) {
    //     fields.push(
    //       <>
    //       <p>Colony count : {i}</p>
    //       <div key={i} className='d-flex mt-3 '>
    //         {labels.map((label, index) => (
    //           <div key={index} className='w-25 mx-3'>
    //             <label htmlFor={`${ids[index]}_${i}`}>{label}:</label>
    //             {label === 'Size' || label === 'Description' ? (
    //               <textarea
    //                 id={`${ids[index]}_${i}`}
    //                 className="colonyRemarkTextArea"
    //                 style={{ height: '32px' }}
    //                 placeholder={label}
    //                 onChange={(event) => {
    //                   handleStudentInfoChange(i - 1, ids[index], event.target.value);
    //                 }}
    //               ></textarea>
    //             ) : (
    //               <select
    //                 id={`${ids[index]}_${i}`}
    //                 className="form-select"
    //                 onChange={(event) => {
    //                   handleStudentInfoChange(i - 1, ids[index], event.target.value);
    //                 }}
    //               >
    //                 <option value="">Select</option>
    //                 {label === 'Shape' && shape.map((option: any) => (
    //                   <option key={option.id} value={option.id}>{option.shape_name}</option>
    //                 ))}
    //                 {label === 'Colour' && color.map((option: any) => (
    //                   <option key={option.id} value={option.id}>{option.colony_colour}</option>
    //                 ))}
    //                 {label === 'Organism Type' && type.map((option: any) => (
    //                   <option key={option.id} value={option.id}>{option.organism_type}</option>
    //                 ))}
    //                 {label === 'Genus' && genus.filter(
    //                     (option: any) =>
    //                       option.organism_type_id == i
    //                   ).map((option: any) => (
    //                   <option key={option.id} value={option.id}>{option.genus_name}</option>
    //                 ))}
    //                 {label === 'Species' && species.filter(
    //                     (option: any) =>
    //                       option.genus_id == i 
    //                   ).map((option: any) => (
    //                   <option key={option.id} value={option.id}>{option.name}</option>
    //                 ))}
    //                 {label === 'Strain' && strain.filter(
    //                     (option: any) =>
    //                       option.species_id == i
    //                   ).map((option: any) => (
    //                   <option key={option.id} value={option.id}>{option.name}</option>
    //                 ))}
    //               </select>
    //             )}
    //           </div>
    //         ))}
    //       </div>
    //       </>
    //     );
    //     fields.push(<hr key={`hr_${i}`} className="mt-4" />);
    //   }
    //   return fields;
    // };
    
    // const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>, id: string, i: number, label: string, ids: string[]) => {
    //   const { value } = event.target;
    //   const newData = formData.slice();
    //   console.log(value, "value")
    
    //   const index = newData.findIndex(item => item.i === i);
    //   const itemData = index !== -1 ? { ...newData[index] } as Record<string, string> : {};

    //   itemData[id] = String(value);
    
    //   const keyIndexString = id.split('_').slice(-1)[0];
    //   const keyIndex = parseInt(keyIndexString);
    //   if (isNaN(keyIndex)) {
    //     console.error('Invalid id format:', id);
    //     return;
    //   }
    
    //   if (ids.length === 0 || keyIndex > ids.length) {
    //     console.error('Invalid ids array or key index:', ids, keyIndex);
    //     return;
    //   }
    
    //   const key = ids[keyIndex - 1];
    
    //   if (label === 'Organism Type') {
    //     handleOrganismTypeChange(value, i, key);
    //     const updatedOrganismTypes = [...selectedOrganismTypes];
    //     updatedOrganismTypes[i] = value;
    //     setSelectedOrganismTypes(updatedOrganismTypes);
    //   } else if (label === 'Genus') {
    //     handleGenusTypeChange(value, i, key);
    //     const updatedGenusTypes = [...selectedGenus];
    //     updatedGenusTypes[i] = value;
    //     setSelectedGenus(updatedGenusTypes);
    //   } else if (label === 'Species') {
    //     handleSpeciesTypeChange(value, i, key);
    //     const updatedSpeciesTypes = [...selectedSpecies];
    //     updatedSpeciesTypes[i] = value;
    //     setSelectedSpecies(updatedSpeciesTypes);
    //   }
    
    //   if (index !== -1) {
    //     newData[index] = { ...itemData, i, colony_order: i };
    //   } else {
    //     newData.push({ ...itemData, i, colony_order: i });
    //   }
    
    //   setFormData(newData);
    // };
    

    const generateFields = (shape: string[], color: string[], type: string[], genus: string[], species: string[], strain: string[]) => {
      const fields = [];
      const labels = ['Remarks', 'Organism Type', 'Genus', 'Species', 'Strain'];
      const ids = ['description', 'organism_type_id', 'genus_id', 'species_id', 'strain_id'];
    
      for (let i = 1; i <= colonyCount; i++) {
        fields.push(
          <>
          <p>Colony count : {i}</p>
          <div key={i} className='d-flex mt-3 '>
            {labels.map((label, index) => (
              <div key={index} className='w-25 mx-3'>
                <label htmlFor={`${ids[index]}_${i}`}>{label}:</label>
                {label === 'Remarks' || label === 'Description' ? (
                  <textarea
                    id={`${ids[index]}_${i}`}
                    className="colonyRemarkTextArea"
                    style={{ height: '32px' }}
                    placeholder={label}
                    onChange={(event) => {
                      handleStudentInfoChange(i - 1, ids[index], event.target.value);
                    }}
                  ></textarea>
                ) : (
                  <select
                    id={`${ids[index]}_${i}`}
                    className="form-select"
                    onChange={(event) => {
                      handleStudentInfoChange(i - 1, ids[index], event.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    {label === 'Shape' && shape.map((option: any) => (
                      <option key={option.id} value={option.id}>{option.shape_name}</option>
                    ))}
                    {label === 'Colour' && color.map((option: any) => (
                      <option key={option.id} value={option.id}>{option.colony_colour}</option>
                    ))}
                    {label === 'Organism Type' && type.map((option: any) => (
                      <option key={option.id} value={option.id}>{option.organism_type}</option>
                    ))}
                    {label === 'Genus' && genus.filter(
                        (option: any) =>
                          option.organism_type_id == i
                      ).map((option: any) => (
                      <option key={option.id} value={option.id}>{option.genus_name}</option>
                    ))}
                    {label === 'Species' && species.filter(
                        (option: any) =>
                          option.genus_id == i 
                      ).map((option: any) => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                    {label === 'Strain' && strain.filter(
                        (option: any) =>
                          option.species_id == i
                      ).map((option: any) => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                )}
              </div>
            ))}
          </div>
          </>
        );
        fields.push(<hr key={`hr_${i}`} className="mt-4" />);
      }
      return fields;
    };


    const handleStudentInfoChange = (index: number, key: any, value: any) => {
      const updatedStudents = [...students];
      updatedStudents[index][key] = value;
      setStudents(updatedStudents);

      if (key === 'organism_type_id') {
        handleOrganismTypeChange(value, index, key);
        const updatedOrganismTypes = [...selectedOrganismTypes];
        updatedOrganismTypes[index] = value;
        setSelectedOrganismTypes(updatedOrganismTypes);
      } else if (key === 'genus_id') {
        handleGenusTypeChange(value, index, key);
        const updatedGenusTypes = [...selectedGenus];
        updatedGenusTypes[index] = value;
        setSelectedGenus(updatedGenusTypes);
      } else if (key === 'species_id') {
        handleSpeciesTypeChange(value, index, key);
        const updatedSpeciesTypes = [...selectedSpecies];
        updatedSpeciesTypes[index] = value;
        setSelectedSpecies(updatedSpeciesTypes);
      }
    };

    const fields = generateFields(shape, color, type, genus, species, strain);
    
   
    // const modifiedFormData = formData.map((item: any) => {
    //   const modifiedItem: Record<string, string> = {};
    //   for (const key in item) {
    //     if (Object.prototype.hasOwnProperty.call(item, key) && key !== 'i') {
    //       const newKey = key.replace(/_\d+$/, '');
    //       modifiedItem[newKey] = item[key];
    //     }
    //   }
    //   return modifiedItem;
    // });
    
    //api call to get pending plate list
    const HistoryFloraPlates = async () => {
        try {
          const response = await floraServices.HistoryFloraPlates(pageHistory, pageSizeHistory, areaId);
          if(response?.status && response?.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  setTotalPagesHistory(response?.totalPages)
                  setTotalElementsHistory(response?.totalElements)
                  let rowData: any = response.data.map((element: any, index: number) => {                 
                    return { 
                        slno: index + 1, 
                        id: element.id,
                        serial_number: element.serial_number,
                        batch_number: element.batch_number,
                        location_name:element.location_name,
                        updated_at: element.updated_at
                    };
                  });
                  setHistoryDataTable(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          } else {
            setHistoryDataTable([]);
          }
        } catch (error) {
          console.error('user response', error);
        }
    };

    const handleHistoryViewClick = (id: string, viewValue: boolean) => {
      const updatedData = historyDataTable?.filter((row: any) => row.slno === id);
      // handleScanCodeChange(updatedData[0]?.serial_number)
      scanPlateById(updatedData[0].id, "history")
      setClickedSerialNumber(updatedData[0]?.serial_number);
      setShowHistoryView(true);
    }

    const handleCloseHistoryModal = () => {
      setShowHistoryView(false);
      handleClearBtn()
    }
      
      
  return (
    <div>
      <HeaderSection />
        <PageHeader pageHeader='Environmental Flora' />
        <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        />
        <div className='exceptionHandlingTab'>
              <button onClick={() => setTab("1")} className={tab === "1" ? 'colTabBtnSel': 'colTabBtn'}>{t('EnvironmentalFlora.pending')}</button>
              <button onClick={() => setTab("2")} className={tab === "2" ? 'colTabBtnSel': 'colTabBtn'}>{t('EnvironmentalFlora.flora')}</button>
              <button onClick={() => setTab("3")} className={tab === "3" ? 'colTabBtnSel': 'colTabBtn'}>{t('EnvironmentalFlora.history')}</button>

        </div>

        {tab === "1" && (
        <div className='me-3' style={{marginTop : "10px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                  tableHead={t('EnvironmentalFlora.pending')}
                  data={pendingDataTable} 
                  columns={columns} 
                  totalElements={totalElements}  
                  isActionVisible={false} 
                  isViewVisible={false} 
                  isSeachable={true} 
                  showBatchFilter={true}
                  showSerielNoFilter={true}
                  onUseEffectRequired={() => 
                  setUseEffectRequired(true)}   
                  isNavigateFlora={true} 
                  onNavigateFloraClick={onNavigateFloraClick}  
                  plateBatch={plateBatches}
                  plateSerialNumber={plateSerielNum}  
                  onFilterClick={handleFilter}
                  showPlateTypeFilter={true}
                />
            </div>
            <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
        </div>
        )}

        {tab === "3" && (
        <div className='me-3' style={{marginTop : "10px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                  tableHead={t('EnvironmentalFlora.history')}
                  data={historyDataTable} 
                  columns={HistoryColumns} 
                  totalElements={totalElementsHistory}  
                  isActionVisible={false} 
                  isViewVisible={true} 
                  isSeachable={false} 
                  showBatchFilter={false}
                  onUseEffectRequired={() => 
                  setUseEffectRequired(true)} 
                  onViewClick={handleHistoryViewClick}
                />
            </div>
            <div>
              <Pagination
                page={pageHistory}
                totalPages={totalPagesHistory}
                handlePageChange={handleHistoryPageChange}
              />
            </div>
        </div>
        )}

        {tab === "2" && (
            <div>
                <div className='scanWrapLg'>
                    <div className='scanInputWrapLg p-1'>
                        <input type='text' value={scanCode} onChange={(e) => handleScanCodeInput(e.target.value)} placeholder='Scan Code' />
                        {scanCode ? (
                              <p onClick={() => handleClearBtn()} className='scanAddText py-2 me-2 my-0 '>{t('buttons.cancel')}</p>
                            ) : (
                              <div onClick={() => setSerielScanShow(true)} className='scanBg '><BsQrCodeScan /></div>
                            )}
                    </div>
                    <div className='pt-3 mx-2'>
                          <p onClick={() => setShow(true)} className='exceptionBtn ms-3' >{t('Exception.exception')}</p>
                    </div>
                    <div className='scannedDetailsWrap ps-3' style={{"marginLeft":"15%"}}>
                         <table className=''>
                            <tbody>
                              {Object.keys(data).map((key) => (
                                  <tr key={key}>
                                    <td className='keyText'>{key}</td>
                                    <td className='px-3'>:</td>
                                    <td className='valueText'>{data[key]}</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                        </table>                      
                    </div>                  
                </div>
                {singlePlateDetails?.serial_number && (
                <div className='scanWrapLg-flora mt-3 '>
                    <div className="view-body-content">
                    <p className="plateDetailHead">Plate Details</p>
                    <div className="view-body-details">
                        <div className="row">
                            <div className="col-md-2">
                                <p className="plate-detail-flora">Plate Serial No</p>
                                <p className="plate-detail-flora-value">{singlePlateDetails?.serial_number}</p>
                            </div>
                            <div className="col-md-2">
                                <p className="plate-detail-flora">Batch Id</p>
                                <p className="plate-detail-flora-value">{singlePlateDetails?.batch_number}</p>
                            </div>
                            {/* <div className="col-md-2">
                                <p className="plate-detail-flora"> Manufacturing Date</p>
                               <p className="plate-detail-flora-value">{singlePlateDetails?.manufacturing_date}</p>
                            </div> */}
                            <div className="col-md-2">
                                <p className="plate-detail-flora"> Expiry date</p>
                                <p className="plate-detail-flora-value">{singlePlateDetails?.expiry_date}</p>
                            </div>
                            <div className="col-md-2">
                                <p className="plate-detail-flora">Activity Type</p>
                                <p className="plate-detail-flora-value">{singlePlateDetails?.plate_type}</p>
                            </div>
                            <div className="col-md-2">
                                <p className="plate-detail-flora">Accepted Colony Count</p>
                                <p className="plate-detail-flora-value">{singlePlateDetails?.accepted_colony_count}</p>
                            </div>
                            {plateId && (
                            <div className="col-md-2">
                                <p onClick={() => handleViewBtnClick()} className="plate-detail-flora-modal">View Plate Details</p>                         
                            </div> 
                            )}                          
                        </div>
                    </div>
                  </div>
                </div>
                )}

                {/* image section */}

                {singlePlateDetails?.serial_number && capturedImageId === "" && (
                <div className='d-flex my-2 justify-content-center'>
                  <div >
                    {/* <div className='d-flex justify-content-center'>
                        <BsChevronDoubleLeft className='m-1' />
                        <p>Lab Details 1</p>
                        <BsChevronDoubleRight className='m-1' />
                    </div> */}
                <TransformWrapper>

                {({ zoomIn, zoomOut }) => (
                <>
                <TransformComponent>

                <div 
                  className="capturedPlateBg my-2" 
                  style={{ transform: `scale(${zoomLevel})`}} 
                > 
                  <img src={`${baseUrl}/files/download?file_id=${capturedImageId}`} />
                </div>

                </TransformComponent>
                  <div className=' d-flex justify-content-end'>
                        <button className='zoominBtn m-1' onClick={() => zoomIn()}>Zoom In</button>
                        <button className='zoominBtn m-1 px-2' onClick={handleZoomIn}>+</button>
                        <button className='zoomoutBtn m-1 px-2' onClick={handleZoomOut}>-</button>
                        <button className='zoomoutBtn m-1' onClick={() => zoomOut()}>Zoom Out</button>
                      </div>
                  </>
                  )}
                </TransformWrapper>
                </div>

                <div>
                {imageIds.map((obj: any) =>(
                  <div className='m-1 p-1' onClick={ () => setCapturedImageId(obj) }>
                    {/* <img src={obj} width={100} height={60} alt="Captured" /> */}
                    <img
                      src={`${baseUrl}/files/download?file_id=${obj || ""}`}
                      alt="Plate Image"
                      width={100}
                      height={50}
                    />
                  </div>
                ))}
                </div>
                </div>
                )}

                {/* plate details and submit section */}
                {singlePlateDetails?.serial_number && (
                <div className='row'>
                    <div className='labtestRightWrap col-12 my-1 ms-2'>
                      <p className="plateDetailHead ms-2">Environmental Flora Identification</p>
                      <div className='my-2'>                   
                      </div>
                        <div className='labtestInputWrap p-2'>
                        {fields}                       
                          <div className='d-flex mt-3 '></div>
                          <div className='' style={{"height":"7vh"}}>
                            {/* {errors.colonyCount && <p className="errorText p-0 m-0" >{errors.colonyCount}</p>}
                            {errors.remarks && <p className="errorText p-0 m-0" >{errors.remarks}</p>} */}
                          </div>
                          <div className='colonyCountBottomSec pe-3 py-1'>
                                  <button onClick={() => handleClearBtn()}  className='modalCancelBtn'>{t('buttons.cancel')}</button>
                                  <button onClick={() => setAreaAlert(true)} className='squareOrangeBtn3'>{t('buttons.submit')}</button>
                          </div>
                        </div>
                    </div>
                </div>
                )}
            </div>
        )}

        <AddException showModel={show} setShowModel={() => setShow(false)} />

        <SerielNumberScanner show={serielScanShow} setShow={setSerielScanShow} handleScanCode={handleScanCode} />

        <LabReportViewModel 
          showView={showView}
          singlePlateDetails={singlePlateDetails}
          currentExposureIndex={currentExposureIndex}
          currentFingerDabIndex={currentFingerDabIndex}
          currentSamplingIndex={currentSamplingIndex}
          currentRawmaterialTestIndex={currentRawmaterialTestIndex}
          currentIncubationIndex={currentIncubationIndex}
          currentLabTestIndex={currentLabTestIndex}
          currentSurfaceMonitoringIndex={currentSurfaceMonitoringIndex}
          exposureDetails={exposureDetails}
          fingureDabDetails={fingureDabDetails}
          plateSamplingDetails={plateSamplingDetails}
          rawMaterialDetails={rawMaterialDetails}
          incubationDetails={incubationDetails}
          labTestDetails={labTestDetails}
          surfaceMonitoringDetails={surfaceMonitoringDetails}
          showApproveRejectBtn={false}
          handleClose={handleClose}
          handlePreviousClick={handlePreviousClick}
          handleNextClick={handleNextClick}
        />

        {/* labreport view for history */}
        <LabReportViewModel 
          showView={showHistoryView}
          singlePlateDetails={singlePlateDetails}
          currentExposureIndex={currentExposureIndex}
          currentFingerDabIndex={currentFingerDabIndex}
          currentSamplingIndex={currentSamplingIndex}
          currentRawmaterialTestIndex={currentRawmaterialTestIndex}
          currentIncubationIndex={currentIncubationIndex}
          currentLabTestIndex={currentLabTestIndex}
          currentSurfaceMonitoringIndex={currentSurfaceMonitoringIndex}
          exposureDetails={exposureDetails}
          fingureDabDetails={fingureDabDetails}
          plateSamplingDetails={plateSamplingDetails}
          rawMaterialDetails={rawMaterialDetails}
          incubationDetails={incubationDetails}
          labTestDetails={labTestDetails}
          surfaceMonitoringDetails={surfaceMonitoringDetails}
          showApproveRejectBtn={false}
          handleClose={handleCloseHistoryModal}
          handlePreviousClick={handlePreviousClick}
          handleNextClick={handleNextClick}
        />

      <AlertModal 
        show={areaAlert}
        onHide={() => setAreaAlert(false)}
        title='Alert'
        message={`Submit Environment Flora`}
        onConfirm={handleConfirmSubmit}
      />
    </div>
  )
}

export default EnvironmentalFlora;
