import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs';
import baseUrl from '../../../../service';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import ImageSlider from '../partials/imageSlider';



interface LabReportViewModalProps {
    showView: boolean;
    singlePlateDetails: { 
        serial_number: string,
        batch_number: string,
        manufacturing_date: string,
        expiry_date: string,
        plate_type: string
    }
    exposureDetails: any[];
    fingureDabDetails: any[];
    plateSamplingDetails: any[];
    rawMaterialDetails: any[];
    incubationDetails: any[];
    labTestDetails: any[];
    surfaceMonitoringDetails: any[]
    currentExposureIndex: number,
    currentFingerDabIndex:number,
    currentSamplingIndex: number,
    currentRawmaterialTestIndex: number,
    currentIncubationIndex: number,
    currentLabTestIndex: number,
    currentSurfaceMonitoringIndex: number,
    showApproveRejectBtn: boolean,

    handleClose: () => void;
    handlePreviousClick: (value: number) => void;
    handleNextClick: (value: number) => void;
    setShowApproveAlert?:(value: boolean) => void;
    setShowRejectAlert?: (value: boolean) => void;
}

const LabReportViewModel: React.FC<LabReportViewModalProps> = ({
    showView,
    singlePlateDetails,
    currentExposureIndex,
    currentFingerDabIndex,
    currentSamplingIndex,
    currentRawmaterialTestIndex,
    currentIncubationIndex,
    currentLabTestIndex,
    currentSurfaceMonitoringIndex,
    exposureDetails,
    fingureDabDetails,
    plateSamplingDetails,
    rawMaterialDetails,
    incubationDetails,
    labTestDetails,
    surfaceMonitoringDetails,
    showApproveRejectBtn,
    handleClose,
    handlePreviousClick,
    handleNextClick,
    setShowApproveAlert,
    setShowRejectAlert,
}) => {

   
    

  return (
    <Modal className="view-modal" show={showView} size="lg">
        <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title className="view-header">View</Modal.Title>
        </Modal.Header>
        <Modal.Body className="view-body">
            <div className="view-body-content">
                <p className="plate-details">Plate Details</p>
                <div className="view-body-details">
                    <div className="row">
                        <div className="col-md-2">
                            <p className="plate-detail-key">Plate Serial No</p>
                            <p className="plate-detail-value">{singlePlateDetails?.serial_number}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="plate-detail-key">Batch Id</p>
                            <p className="plate-detail-value">{singlePlateDetails?.batch_number}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="plate-detail-key">Plate Manufacturing Date</p>
                            <p className="plate-detail-value">{singlePlateDetails?.manufacturing_date}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="plate-detail-key">Plate Expiry date</p>
                            <p className="plate-detail-value">{singlePlateDetails?.expiry_date}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="plate-detail-key">Activity Type</p>
                            <p className="plate-detail-value">{singlePlateDetails?.plate_type}</p>
                        </div>
                        {/* <div className="col-md-2">
                            <p className="plate-detail-key">Verified By</p>
                            <p className="plate-detail-value">QA1 & QA2</p>
                        </div>                            */}
                    </div>
                </div>
            </div>
            <div className="view-lab-details">
                <div className="view-lab-details-content">
                    <div className="row">
                        {exposureDetails && singlePlateDetails?.plate_type === "Exposure Plate" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Exposer Details</p>
                                        <div>
                                            <button 
                                            className={currentExposureIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(1)} 
                                            disabled={currentExposureIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentExposureIndex === exposureDetails?.length - 1 || exposureDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(1)} 
                                            disabled={currentExposureIndex === exposureDetails.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1">
                                                                
                                    <p className="plate-detail-key">Exposed Area Name</p>
                                    <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.exposedAreaName || "-"}</p>                             
                                
                                    <p className="plate-detail-key">Exposed By</p>
                                    <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.exposedBy || "-"}</p>
                                                            
                                    <p className="plate-detail-key">Exposed Date & Time</p>
                                    <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.exposedDate || "-"}</p>

                                    <p className="plate-detail-key">Collected By</p>
                                    <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.collectedBy || "-"}</p>

                                    <p className="plate-detail-key">Collected Date & Time</p>
                                    <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.collectedDate || "-"}</p>                                                     
                                </div>    
                            </div>
                        )}
                        {fingureDabDetails && singlePlateDetails?.plate_type === "Finger DAB Plate" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Finger Dab Details</p>
                                        <div>
                                            <button 
                                            className={currentFingerDabIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(4)} 
                                            disabled={currentFingerDabIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentFingerDabIndex === fingureDabDetails?.length - 1 || fingureDabDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(4)} 
                                            disabled={currentFingerDabIndex === fingureDabDetails.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1">
                                                                
                                    <p className="plate-detail-key">FingerDab Area Name</p>
                                    <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.fingerDabAreaName || "-"}</p>                             
                                
                                    <p className="plate-detail-key">FingerDabbed By</p>
                                    <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.fingerDabbededBy || "-"}</p>
                                                            
                                    <p className="plate-detail-key">FingerDabbed Date & Time</p>
                                    <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.dabbedDate || "-"}</p>

                                    <p className="plate-detail-key">FingerDab Started By</p>
                                    <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.fingerDabType || "-"}</p>

                                    <p className="plate-detail-key">FingerDab Option</p>
                                    <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.figerDabOption || "-"}</p>

                                
                                </div>    
                            </div>
                        )}
                        {plateSamplingDetails && singlePlateDetails?.plate_type === "Sampling Plate" &&(
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Sampling Details</p>
                                        <div>
                                            <button 
                                            className={currentSamplingIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(5)} 
                                            disabled={currentSamplingIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentSamplingIndex === plateSamplingDetails?.length - 1 || plateSamplingDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(5)} 
                                            disabled={currentSamplingIndex === plateSamplingDetails.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1">
                                                                
                                    <p className="plate-detail-key">Plate Sampling Type</p>
                                    <p className="plate-detail-value">{plateSamplingDetails[currentSamplingIndex]?.plateSamplingType || "-"}</p>                             
                                
                                    <p className="plate-detail-key">Plate Sampled By</p>
                                    <p className="plate-detail-value">{plateSamplingDetails[currentSamplingIndex]?.sampledBy || "-"}</p>
                                                            
                                    <p className="plate-detail-key">Plate Sampled Date & Time</p>
                                    <p className="plate-detail-value">{plateSamplingDetails[currentSamplingIndex]?.sampledDate || "-"}</p>

                                    {/* <p className="plate-detail-key">Collected By</p>
                                    <p className="plate-detail-value">{plateSamplingDetails[currentFingerDabIndex]?.collectedBy || "-"}</p> */}

                                    {/* <p className="plate-detail-key">Collected Date & Time</p>
                                    <p className="plate-detail-value">{plateSamplingDetails[currentFingerDabIndex]?.collectedDate || "-"}</p>                                                      */}
                                </div>    
                            </div>
                        )}
                        {rawMaterialDetails && singlePlateDetails?.plate_type === "Material Test Plate" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Material Test Details</p>
                                        <div>
                                            <button 
                                            className={currentRawmaterialTestIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(6)} 
                                            disabled={currentRawmaterialTestIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentRawmaterialTestIndex === rawMaterialDetails?.length - 1 || rawMaterialDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(6)} 
                                            disabled={currentRawmaterialTestIndex === rawMaterialDetails.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1">
                                                                
                                    <p className="plate-detail-key">Raw Material Name</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentRawmaterialTestIndex]?.rawMaterialTypeName || "-"}</p>                             
                                
                                    <p className="plate-detail-key">Raw Material Test Conducted By</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentRawmaterialTestIndex]?.conductedBy || "-"}</p>
                                                            
                                    <p className="plate-detail-key">Raw Material Test Date & Time</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentRawmaterialTestIndex]?.testDate || "-"}</p>

                                    {/* <p className="plate-detail-key">Collected By</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentFingerDabIndex]?.collectedBy || "-"}</p> */}

                                    {/* <p className="plate-detail-key">Collected Date & Time</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentFingerDabIndex]?.collectedDate || "-"}</p>                                                      */}
                                </div>    
                            </div>
                        )}
                         {surfaceMonitoringDetails && singlePlateDetails?.plate_type === "Surface Monitoring Plate" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Surface Monitoring</p>
                                        <div>
                                            <button 
                                            className={currentSurfaceMonitoringIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(7)} 
                                            disabled={currentSurfaceMonitoringIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentSurfaceMonitoringIndex === surfaceMonitoringDetails?.length - 1 || rawMaterialDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(7)} 
                                            disabled={currentSurfaceMonitoringIndex === surfaceMonitoringDetails.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1">
                                                                
                                    <p className="plate-detail-key">Surface Monitoring Area Name</p>
                                    <p className="plate-detail-value">{surfaceMonitoringDetails[currentSurfaceMonitoringIndex]?.surfaceMonitoringAreaName || "-"}</p>                             
                                
                                    <p className="plate-detail-key">Surface Monitoring Start Date</p>
                                    <p className="plate-detail-value">{surfaceMonitoringDetails[currentSurfaceMonitoringIndex]?.surfaceMonitoringStartDate || "-"}</p>
                                                            
                                    {/* <p className="plate-detail-key">Raw Material Test Date & Time</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentRawmaterialTestIndex]?.testDate || "-"}</p> */}

                                    {/* <p className="plate-detail-key">Collected By</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentFingerDabIndex]?.collectedBy || "-"}</p> */}

                                    {/* <p className="plate-detail-key">Collected Date & Time</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentFingerDabIndex]?.collectedDate || "-"}</p>                                                      */}
                                </div>    
                            </div>
                        )}
                        <div className="col-md-3">
                            <div className="d-flex justify-content-between">
                                    <p className="lab-detail-key">Incubation Details</p>
                                    <div>
                                        <button 
                                        className={currentIncubationIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                        onClick={() => handlePreviousClick(2)} 
                                        disabled={currentIncubationIndex === 0}>
                                            <BsChevronDoubleLeft />
                                        </button>
                                        <button 
                                        className={currentIncubationIndex === incubationDetails.length - 1 || incubationDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                        onClick={() => handleNextClick(2)} 
                                        disabled={currentIncubationIndex === incubationDetails.length - 1}>
                                            <BsChevronDoubleRight />
                                        </button>
                                    </div>
                                </div> 
                            <div className="detail-col-1">
                                <p className="plate-detail-key">Incubation Area Name</p>
                                <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubationAreaName || "-"}</p>                             
                            
                                <p className="plate-detail-key">Incubated By</p>
                                <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubatedBy || "-"}</p>
                                                        
                                <p className="plate-detail-key">Incubation Date & Time</p>
                                <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubationDateTime || "-"}</p>

                                <p className="plate-detail-key">Incubation Stop</p>
                                <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubationOutBy || "-"}</p>

                                <p className="plate-detail-key">Incubation out Date & Time</p>
                                <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubationoutDateTime || "-"}</p>             
                            </div>                           
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-between">
                                    <p className="lab-detail-key">Lab Details</p>
                                    <div>
                                        <button 
                                        className={currentLabTestIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                        title='Click here to view Details'
                                        onClick={() => handlePreviousClick(3)} 
                                        disabled={currentLabTestIndex === 0}>
                                            <BsChevronDoubleLeft />
                                        </button>
                                        <button 
                                        className={currentLabTestIndex === labTestDetails.length - 1 || labTestDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'}
                                        title='Click here to view Details' 
                                        onClick={() => handleNextClick(3)} 
                                        disabled={currentLabTestIndex === labTestDetails.length - 1}>
                                            <BsChevronDoubleRight />
                                        </button>
                                    </div>
                                </div>
                            <div className="detail-col-3">
                            <div className="row">
                                <div className="col-md-4">
                                    <p className="plate-detail-key">Lab Test Conducted by</p>
                                    <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.conductedBy || "-"}</p>                             
                                </div>
                                <div className="col-md-4">
                                    <p className="plate-detail-key">Lab Test Date & Time</p>
                                    <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.dateAndTime || "-"}</p>                             
                                </div>
                                <div className="col-md-4">
                                    <p className="plate-detail-key">Colony Count</p>
                                    <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.colonyCount || "-"}</p>                             
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <p className="plate-detail-key">Species</p>
                                    <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.species || "-"}</p>                             
                                </div>
                                <div className="col-md-4">
                                    <p className="plate-detail-key">Genus</p>
                                    <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.genus || "-"}</p>                             
                                </div>
                            </div>
                            {labTestDetails[currentLabTestIndex]?.imageId && (
                                <>
                                <p className="plate-detail-key">Image</p>
                                <div className='view-image-count-wrap'>
                                    <ImageSlider baseUrl={baseUrl} imageIds={labTestDetails[currentLabTestIndex]?.imageId || ""} />
                                </div>
                                </>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer">
        <Button className="footerCancelBtn" variant="tertiory" onClick={handleClose}>
        Cancel
        </Button>              
        <div>
        {showApproveRejectBtn && (
            <>
                <Button className="footerApproveBtn" onClick={() => setShowApproveAlert && setShowApproveAlert(true)}>
                    Approve
                </Button>
                <Button className="footerRejectBtn" onClick={() => setShowRejectAlert && setShowRejectAlert(true)}>
                    Reject
                </Button>
            </>
        )}
        </div>
        </Modal.Footer>
    </Modal>
  )
}

export default LabReportViewModel