
//column heads for Exception handling Request 
export const columns: TableColumn[] = [
    {
        Header: "exceptionHandling.tableColumns.exception_id",
        accessor: "slno",
      },
      {
        Header: "tableCommon.batch_code",
        accessor: "batch_number",
      },
      {
        Header: "tableCommon.seriel_number",
        accessor: "serial_number",
      },
      {
        Header: "tableCommon.exception_reason",
        accessor: "reason",
      },
      {
        Header: "exceptionHandling.tableColumns.date",
        accessor: "date",
      },
      {
        Header: "exceptionHandling.tableColumns.area_name",
        accessor: "location_name",
      },
      {
        Header: "exceptionHandling.tableColumns.operator_name",
        accessor: "exception_requested_user_firstname",
      },
      {
        Header: "exceptionHandling.tableColumns.operator_id",
        accessor: "exception_requested_by",
      },
      {
        Header: "exceptionHandling.tableColumns.status",
        accessor: "status",
      },
]


//column heads for Exception handling History  
export const columnsHistory: TableColumn[] = [
    {
        Header: "exceptionHandling.tableColumns.exception_id",
        accessor: "slno",
      },
      {
        Header: "tableCommon.batch_code",
        accessor: "batch_number",
      },
      {
        Header: "tableCommon.seriel_number",
        accessor: "serial_number",
      },
      {
        Header: "tableCommon.exception_reason",
        accessor: "reason",
      },
      {
        Header: "exceptionHandling.tableColumns.area_name",
        accessor: "location_name",
      },
      {
        Header: "exceptionHandling.tableColumns.operator_name",
        accessor: "operator_name",
      },
      {
        Header: "exceptionHandling.tableColumns.operator_id",
        accessor: "exception_requested_by",
      },
      {
        Header: "exceptionHandling.tableColumns.approved_by",
        accessor: "approved_by",
      },
      {
        Header: "exceptionHandling.tableColumns.approved_on",
        accessor: "approved_on",
      },
      {
        Header: "exceptionHandling.tableColumns.status",
        accessor: "status",
      },
]


interface TableColumn {
    Header: string;
    accessor: string;
  }
  