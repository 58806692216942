import React from "react";
import { v4 } from "uuid";

/**
 *
 * @param {any} prevProp
 * @param {any} nextProp
 * @returns Boolean
 */
const shouldComponentUpdate = (prevProp, nextProp) => {
  try {
    return JSON.stringify(prevProp) === JSON.stringify(nextProp);
  } catch (error) {
    return false;
  }
};

const isEmail = (value) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
/**
 *
 * @param {Object} obj
 * @param {String} prop
 * @returns Boolean
 *  check object property is exist or not
 */
const isPropertyExist = (obj, prop) => {
  return obj.hasOwnProperty(prop);
};

/**
 *
 * @param {any} value
 * @returns boolean
 */
const isNotEmpty = (value) => {
  return value !== "" && value !== null && value !== undefined;
};
/**
 *
 * @param {any} value
 * @returns boolean
 */
const isArray = (value) => {
  return Array.isArray(value);
};

/**
 * @param {pagination} value
 */
const isPaginationResponse = (response) => {
  if (
    isPropertyExist(response, "data") &&
    isPropertyExist(response.data, "content") &&
    isPropertyExist(response.data, "totalElements") &&
    isArray(response.data.content)
  ) {
    return true;
  }
  return false;
};

const arrayHasData = (array) => {
  return isNotEmpty(array) && isArray(array) && array.length > 0;
};

/**
 *
 * @param {Object} obj
 * @returns bool
 */
const objectHasValue = (obj) => {
  let isValid = false;
  if (
    obj !== null &&
    obj !== undefined &&
    typeof obj === "object" &&
    Object.keys(obj).length > 0
  ) {
    for (const key in obj) {
      if (obj[key] !== null || obj[key] !== undefined || obj[key] !== "") {
        isValid = true;
        break;
      }
    }
  }
  return isValid;
};

/**
 * function to get the first n letters of a string
 * @param {String} str
 * @param {Number} n
 * @returns string
 */
const getFirstLetters = (str, n) => {
  if (isNotEmpty(str)) {
    return str.length > n ? `${str.substring(0, n)}...` : str;
  }
  return "";
};

/**
 * check object exist in array od objects
 * @param {Array.<object>} array
 * @param {object} obj
 * @returns boolean
 */
const isObjectExistInArray = (array, obj) => {
  let isExist = false;
  if (arrayHasData(array)) {
    array.forEach((item) => {
      if (JSON.stringify(item) === JSON.stringify(obj)) {
        isExist = true;
      }
    });
  }
  return isExist;
};

/**
 * check object exist in array objects by key
 * @param {Array} array
 * @param {Object} object
 * @param {string} key
 * @returns boolean
 */
const isObjectExistInArrayByKey = (array, object, key) => {
  let isExist = false;
  if (arrayHasData(array)) {
    array.forEach((item) => {
      if (
        typeof object === "object" &&
        typeof item === "object" &&
        isPropertyExist(item, key) &&
        isPropertyExist(object, key) &&
        item[key] === object[key]
      ) {
        isExist = true;
      }
    });
  }
  return isExist;
};

/**
 * get object index in array by object key
 * @param {Array} array
 * @param {object} object
 * @param {key} key
 * @returns array index
 */
const getObjectIndexInArrayByKey = (array, object, key) => {
  let index = -1;
  if (arrayHasData(array)) {
    array.forEach((item, i) => {
      if (
        typeof object === "object" &&
        typeof item === "object" &&
        isPropertyExist(item, key) &&
        isPropertyExist(object, key) &&
        item[key] === object[key]
      ) {
        index = i;
      }
    });
  }
  return index;
};

const getErrorString = (error, t) => {
  if (isArray(error?.errors)) {
    return error?.errors.reduce(
      (acc, item) => acc + t(`armErrorCode.${item?.errorCode}`) + "\n",
      ""
    );
  }
  if (isPropertyExist(error, "message") && isStringNotEmpty(error.message)) {
    return error.message;
  }
  return t("armErrorCode.internalServerError");
};
const isStringNotEmpty = (str) => {
  return (
    typeof str === "string" &&
    str.trim().length > 0 &&
    str !== "null" &&
    str !== "undefined" &&
    str !== "NaN" &&
    str !== ""
  );
};

const isObject = (obj) => {
  return obj !== null && typeof obj === "object";
};

// function to check given string is valid mobile number or not
// eslint-disable-next-line
const isValidMobileNumber = (mobileNumber) => /^[0-9\s\+]*$/.test(mobileNumber);

export const translateField = (field, t) => {
  if (!field || typeof field !== "object" || typeof t !== "function") {
    return field;
  }
  // const fields = {
  //   ...field,
  //   label: t(field.label),
  //   placeholder: t(field.placeholder),
  // };
  const fields = field;
  if (field?.title) {
    fields['title'] = t(field.title);
  }
  if (field?.label) {
    fields['label'] = t(field.label);
  }
  if (field?.placeholder) {
    fields['placeholder'] = t(field.placeholder);
  }
  if (field?.inputPlaceholder) {
    fields['inputPlaceholder'] = t(field.inputPlaceholder);
  }
  if (
    typeof field?.props?.required === "object" &&
    field?.props?.required?.value === true
  ) {
    fields["props"]["required"] = {
      ...field?.props?.required,
      errorMsg: t(field.props.required?.errorMsg || ""),
    };
  }
  if (typeof field?.props?.regex === "object") {
    fields["props"]["regex"] = {
      ...field?.props?.regex,
      errorMsg: t(field.props.regex?.errorMsg || ""),
    };
  }
  if (Array.isArray(fields?.options)) {
    fields["options"] = fields?.options.map((opt) => ({
      ...opt,
      label: t(opt.label),
    }));
  }
  return fields;
};

const camelize = (str) => {
  return isNotEmpty(str) ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "";
};

const getLoggedInUserName = () => {
  if (window?.getLoggedInUserName && typeof window?.getLoggedInUserName === "function") {
    return window?.getLoggedInUserName();
  } else {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let given_name = userInfo?.given_name ? userInfo?.given_name : "";
    let family_name = userInfo?.family_name ? userInfo?.family_name : "";
    return (family_name + ' ' + given_name).trim();
  }
}

export const helperFunctions = {
  shouldComponentUpdate,
  isPropertyExist,
  isArray,
  isNotEmpty,
  isPaginationResponse,
  arrayHasData,
  objectHasValue,
  getFirstLetters,
  isObjectExistInArray,
  isObjectExistInArrayByKey,
  getObjectIndexInArrayByKey,
  getErrorString,
  isObject,
  isEmail,
  isValidMobileNumber,
  camelize,
  getLoggedInUserName
};
