import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import HeaderSection from '../../../common/headerSection'
import PageHeader from '../../../common/pageHeader'
import CustomTable from '../../../../widgets/table'
import Pagination from '../../../../widgets/pagination'
import { TfiClose } from 'react-icons/tfi'
import { Modal } from 'react-bootstrap'
import { dashboardServices } from '../../../../service/dashboard'
import { useTranslation } from 'react-i18next';
import { setLoading } from '../../../../store/store'
import { useDispatch } from 'react-redux'
import DownloadPdf from '../../../../widgets/downloadPdf'
import { IncubatorTableColumns as columns } from './partials/columns'
import { IncubatorPlateDetailsTableColumns as columns2 } from './partials/columns'

const moment = require('moment');


type IncubationStatType = {
  incubation_area: number;
  plates_in_incubation: number;
  incubation_occupancy_or_capacity: number;
  plates_ready_for_collection: number;
  over_due_plates_for_collection: number;
  deviations: number;
};

type PlateViewType = {
  incubation_point_name: string;
  incubation_start_date: string;
  end_date_time : string;
  serial_number: string;
  exception_status: string;
  exception_added : string;
  started_by : string;
  ended_by : string;
  status : string;
  escalations : string;
};

const IncubationTable = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal , setShowModal] = useState(false);
  const [showButtonWithCloseIcon, setShowButtonWithCloseIcon] = useState(false);
  const [closeIconText, setCloseIconText] = useState("");
  const [incubationStat, setIncubationStat] = useState<IncubationStatType | null>(null);
  const [incubationAreaNames, setIncubationAreaNames] = useState<Array<any>>([]);
  const [incubationPlateDetails, setIncubationPlateDetails] = useState<Array<any>>([]);
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [plateView, setPlateView] = useState<PlateViewType | null>(null);
  const [page, setpage] = useState(1)
  const [pagesize, setpagesize] = useState(5)
  const [totalPages, settotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  const [useEffectRequired, setUseEffectRequired] = useState(false)



 
  const handleSchedulePageChange = (newPage : any, pageSize?: number) => { 
    console.log(newPage, pageSize, "page")  
    setpage(newPage);
    if(pageSize) {
      setpagesize(pageSize)
    }
    setUseEffectRequired(true)
};

//handle view click in incubator table 
const handleViewClick = (id: string, viewValue: boolean) => {
  const updatedData = plateDetails?.filter((row) => row.slno === id);
  getPlateView(updatedData[0]?.id)
  setShowModal(viewValue)
};

//on row click plate details is fetched under each incubator 
const handleRowClick = (id: string) => {
  setShowButtonWithCloseIcon(true)
  setCloseIconText(`incubator ${id}`)
  ListIncubatorPlates(id)
}

//on click on selected incubator list 
const handleCloseBtnClick = () => {
  setShowButtonWithCloseIcon(false)
  setPlateDetails([])
}

//api call to get the incubation status 
const getIncubationStatus = async () => {
  try {
    const response = await dashboardServices.getIncubationStatDetails(); 
      if(response.status && response.statusCode === 200){
        setIncubationStat({
          incubation_area : response.data.total_incubators,
          incubation_occupancy_or_capacity : response.data.occupancy_capacity,
          plates_in_incubation: response.data.plates_in_incubation,
          plates_ready_for_collection : response.data.ready_for_collection,
          over_due_plates_for_collection : response.data.over_due_plates,
          deviations : response.data.escalations
          });
          dispatch(setLoading(false));
    }
  } catch (error) {
    console.error('user response', error);
  }
};

//api call to get the incubation area names 
const getIncubationAreaNames = async () => {
  try {
    const response = await dashboardServices.getIncubationAreaNames();
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {
              return { 
                  slno: index + 1, 
                  id: element.id,
                  name: element.name,  
              };
            });
            setIncubationAreaNames(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    }
  } catch (error) {
    console.error('exposure response', error);
  }
};

//api call to get the total incubator list 
const getIncubationPlateDetails = async (page: any , pageSize: any) => {
  try {
    const response = await dashboardServices.getIncubationPlateDetails(page, pageSize);
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            settotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {
              return { 
                  slno: index + 1, 
                  id: element.id,
                  name: element.name,  
                  capacity : element.capacity,
                  plates_in_incubation : element.stat.plates_in_incubation,
                  ready_for_collection : element.stat.ready_for_collection,
                  over_due_plates : element.stat.over_due_plates,
                  exception_added : element.stat.exception_added,
                  approved_exceptions : element.stat.approved_exceptions,
                  escalations : element.stat.escalations,
              };
            });
            setIncubationPlateDetails(rowData);
          }
        } catch (error) {
         
          console.log(error);
        }
    }else{
      setIncubationPlateDetails([])
    }
  } catch (error) {
    console.error('exposure response', error);
  }
};

//api call to get the total plates under each incuabtor 
const ListIncubatorPlates = async (location_id : string) => {
    try {
      const response = await dashboardServices.ListIncubatorPlates(page, pagesize, location_id);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              settotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
             
              let rowData: any = response.data.map((element: any, index: number) => {

              const plate_detail = parseInt(element.plate_status, 10);
              let plateStatus = ""
              if(plate_detail == 12){
                plateStatus = "Pending"
              } else if (plate_detail == 13){
                plateStatus = "Completed"
              }else{
                plateStatus = "Unknown"
              }
              const plateType = parseInt(element.plate_type, 10); 
              if (plateType == 1){
                var plate_type = "Q A Plate"
              }else if (plateType == 2){
                var plate_type = "Exposure Plate"
              }else if (plateType == 3){
                var plate_type = "Finger DAB Plate"
              } else{
                var plate_type = "-"
              }


              const createdFirstName = element.incubation_details[0]?.created_by_first_name || '-';
              const createdLastName = element.incubation_details[0]?.created_by_last_name || '-';
              const fullName = createdFirstName + ' ' + createdLastName;

              const updatedFirstName = element.incubation_details[0]?.updated_by_first_name || '-';
              const updatedLastName = element.incubation_details[0]?.updated_by_last_name || '-';
              let updatedFullName = '';
              if (updatedFirstName !== null) {
                updatedFullName = updatedFirstName;
              if (updatedLastName !== null) {
              updatedFullName += ' ' + updatedLastName;
              }
              } else {
              updatedFullName = '-';
              }

              const endDateTime = element.incubation_details[0]?.end_date_time;
              const formattedEndDate = endDateTime
              ? moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss')
              : '-';
              // const status = endDateTime ? 'Completed' : 'Pending';


                return { 
                  slno: index + 1, 
                  id: element.id,
                  plate_serial_number : element.serial_number,
                  plate_status : plateStatus,
                  plate_type : plate_type,
                  incubation_start_time : moment(element.incubation_details[0]?.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss'),
                  incubated_start_by : fullName,
                  incubation_end_time : formattedEndDate,
                  incubated_end_by : updatedFullName 
              };
            });
            setPlateDetails(rowData);
          }
        } catch (error) {
          console.log(error); 
        }
    } else {
      setPlateDetails([])
    }
  } catch (error) {
    console.error('Plate response', error);
  }
};

//api call to get the plate view on row select 
const getPlateView = async (id: any) => {
  const dataToSend = {
    "id" : id
  }
  try {
    const response = await dashboardServices.scanPlateById(dataToSend);
    if (response.status && response.statusCode === 200) {

      const endDateTime = response.data.incubation_details[0]?.end_date_time;
      const formattedEndDate = endDateTime
      ? moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss')
      : '-';
      const status = endDateTime ? 'Completed' : 'Pending';

    const createdFirstName = response.data.incubation_details[0]?.created_by_first_name;
    const createdLastName = response.data.incubation_details[0]?.created_by_last_name;
    const fullName = createdFirstName + ' ' + createdLastName;

    const updatedFirstName = response.data.incubation_details[0]?.updated_by_first_name;
    const updatedLastName = response.data.incubation_details[0]?.updated_by_last_name;
    let updatedFullName = '';
    if (updatedFirstName !== null) {
      updatedFullName = updatedFirstName;
    if (updatedLastName !== null) {
    updatedFullName += ' ' + updatedLastName;
    }
    } else {
    updatedFullName = '-';
    }
      setPlateView({
        incubation_point_name: response.data.incubation_details[0]?.incubator_name,
        serial_number : response.data.serial_number,
        incubation_start_date : moment(response.data.incubation_details[0]?.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss'),
        end_date_time :  formattedEndDate,
        exception_status : response.data.incubation_details[0]?.exception_status,
        exception_added : response.data.incubation_details[0]?.exception_added,
        escalations : response.data.incubation_details[0]?.escallation,
        started_by : fullName,
        ended_by : updatedFullName,
        status  : status
      });
    }
  } catch (error) {
    console.error('view response', error);
  }
};


useEffect(() => {
  dispatch(setLoading(true));
  getIncubationPlateDetails(page, pagesize)
  getIncubationStatus();
  getIncubationAreaNames();
  setUseEffectRequired(false)
}, [useEffectRequired])

  return (
   <div>
    <HeaderSection />
    <PageHeader pageHeader="Dashboard" />
    <div id='dashWrap'>
    <nav aria-label="breadcrumb" className="breadcrumb-container row">
      <div className='col-10 d-flex'>
        <ol className="breadcrumb">
          <li className="breadcrumb-item dashboard-hover"><Link to="/*">{t('dashboard.title')}</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{t('dashboard.incubator')}</li>
        </ol>
      </div>
      <div className='col-2 d-flex'>
        <DownloadPdf divId="dashWrap" reportHead="Incubation Report" />
      </div>
    </nav>

    {/*map the incubator stat value*/}
    <div className="key-values-container">
      {incubationStat && Object.entries(incubationStat).map(([key, value]) => (
          <div  key={key} className="key-value">
            <div className="key">
              {key.replace(/_/g, ' ')}
            </div>
            <div className='value'>{value}</div>
          </div>
        ))}
      </div>

    <div className='row'>
        <div >
          {!showButtonWithCloseIcon ? (
            <div className='mx-3 pe-3' style={{"height":"45vh"}}>
                <CustomTable
                    tableHead='Incubation Details' 
                    data={incubationPlateDetails} 
                    columns={columns} 
                    isEditable={false} 
                    isActionVisible={false} 
                    isViewVisible={false} 
                    totalElements={totalElements}
                    isSeachable={false}
                    isToggleStatus={false}      
                    onViewClick={handleViewClick}
                    onRowClick={handleRowClick}
                    buttonWithCloseIcon={showButtonWithCloseIcon}
                    buttonWithCloseIconText={closeIconText}
                    onCloseBtnClick={() => handleCloseBtnClick()}
                />
            </div>
                ):(
            <div className='mx-3 pe-3' style={{"height":"45vh"}}>
                  <CustomTable
                    tableHead='Plate Details' 
                    data={plateDetails} 
                    columns={columns2} 
                    isEditable={false} 
                    isActionVisible={false} 
                    isViewVisible={true} 
                    totalElements={totalElements}
                    isSeachable={false}
                    isToggleStatus={false}      
                    onViewClick={handleViewClick}
                    buttonWithCloseIcon={showButtonWithCloseIcon}
                    buttonWithCloseIconText={closeIconText}
                    onCloseBtnClick={() => handleCloseBtnClick()}
                />
              </div>
                )}
        </div>
        <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handleSchedulePageChange}
              />
        </div>
    </div>
    </div>

  {/*on view click opens the modal*/}
    <Modal
              className="top-right-modal"
              centered={false}
              show={showModal}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{plateView?.incubation_point_name}</p>
                <div onClick={() => setShowModal(false)} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>

              <div className="selectWrap view-exposure-head">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.plate_serial_id')}</label>
                    <br />
                    <input 
                       id="plate_serial_id"
                       value={plateView?.serial_number}
                       name="plate_serial_id"
                       type='text'
                       placeholder='Plate Serial ID'
                       className='view-exposure-head'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.status')}</label>
                    <br />
                    <input 
                       id="status"
                       value={plateView?.status}
                       name="status"
                       type='text'
                       placeholder='Status'
                       className='view-exposure-head'
                       disabled 
                    />  
                  </div>
                </div>
                  <div className="selectWrap mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.inc_start_time')}</label>
                    <br />
                    <input 
                       id="inc_start_time"
                       value={plateView?.incubation_start_date}
                       name="inc_start_time"
                       type='text'
                       placeholder='Incubation Start Time'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.inc_started_by')}</label>
                    <br />
                    <input 
                       id="inc_started_by"
                       value={plateView?.started_by}
                       name="inc_started_by"
                       type='text'
                       placeholder='Incubation started by'
                       className='view-exposure-details'
                       disabled
                    />  
                  </div>
                </div>

                <div className="selectWrap mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.inc_end_time')}</label>
                    <br />
                    <input 
                       id="inc_end_time"
                       value={plateView?.end_date_time}
                       name="inc_end_time"
                       type='text'
                       placeholder='Incubation End Time'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.inc_stopped_by')}</label>
                    <br />
                    <input 
                       id="inc_stopped_by"
                       value={plateView?.ended_by}
                       name="inc_stopped_by"
                       type='text'
                       placeholder='Incubation Stopped By'
                       className='view-exposure-details'
                       disabled
                    />  
                  </div>
                </div>

                <div className="selectWrap mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.Exception_added')}</label>
                    <br />
                    <input 
                       id="Exception_added"
                       value={plateView?.exception_added}
                       name="Exception_added"
                       type='text'
                       placeholder='Exception added'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.exception_status')}</label>
                    <br />
                    <input 
                       id="exception_status"
                       value={plateView?.exception_status}
                       name="exception_status"
                       type='text'
                       placeholder='Exception Status'
                       className='view-exposure-details'
                       disabled 
                    />  
                  </div>
                </div>

                <div className="selectWrap-email mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.escalations')}</label>
                    <br />
                    <input 
                       id="escalations"
                       value={plateView?.escalations}
                       name="escalations"
                       type='text'
                       placeholder='escalations'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>
                </div>
               
              </div>
              <div className="bottomArea">
            <button onClick={() => setShowModal(false)} className="modalCancelBtn">
            {t('buttons.cancel')}
            </button>        
          </div>
            </div>
    </Modal>

 </div>

  )
}

export default IncubationTable;
