import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { dashboardServices } from "../../../../service/dashboard";
import translationData from '../../../../locales/dashboardTranslation.json'

interface Column {
  id: number;
  content: string;
  count: number;
}

interface PlateReconciliationProps {
  heading: string;
  selectedDate: any;
}

const columnStyle = {
  margin: "0 0 6px 0",
};

const lastColumnStyle = {
  margin: "0",
};

const PlateReconciliation: React.FC<PlateReconciliationProps> = ({
  heading,
  selectedDate,
}) => {
  const navigate = useNavigate();

  interface ColumnData {
    id: number;
    apiName: string;
    displayName: string;
    count: number;
    isBlinking?: boolean;
  }
  
  const [columnsData, setColumnsData] = useState<ColumnData[]>([]);
  const [highlightBlink, setHighlightBlink] = useState(false);
//   const [alertStat, setAlertStat] = useState(null);
  const navigateToOtherPage = () => {
    // navigate('/exposure-table'); 
  };
  
  const switchToPage = (id: number) => {
    // if(id >= 1 && id <= 2) {
    //     navigate(`/inner-view-descrepency/${id}`);
    // }
  }

 //api call to get the discrepency stat values 
//   const getDescrepancyStatus = async () => {
//     try {
//       const response = await dashboardServices.getDescrepancyStatus(selectedDate);
//       if (response.status && response.statusCode === 200) {
//         try {
//           const responseData = response.data;
//           if (responseData) {
//             const alertStat = responseData.alert_stat;
//             setAlertStat(alertStat);

//             setColumnsData(prevColumnsData => {
//               return prevColumnsData.map(column => {
//                 const { apiName, displayName } = column;

//                 const apiCount = responseData[apiName];

//                 if (apiCount !== undefined) {
//                   return { ...column, count: apiCount };
//                 } else {
//                   console.warn(`API response for ${displayName} is undefined or missing.`);
//                   return column;
//                 }
//               });
//             });

//             if (alertStat === "1") {
//               setHighlightBlink(true);
//             } else {
//               setHighlightBlink(false);
//             }
//           }
//         } catch (error) {
//           console.log(error);
//         }
//       }
//     } catch (error) {
//       console.error('user response', error);
//     }
//   };
  
  useEffect(() => {
    const plateReconciliation = translationData.plateReconciliation;
    setColumnsData(plateReconciliation.map((item => ({ ...item, count: 0 }))));
    getReconciliationStat()
    }, []); 

    // getReconciliationStat

    const getReconciliationStat = async () => {
      try {
        const response = await dashboardServices.getReconciliationStat();
        if (response.status && response.statusCode === 200) {
          console.log(response, "res")
          try {
            const responseData = response.data;
            if (responseData) {
              setColumnsData(prevColumnsData => {
                return prevColumnsData.map(column => {
                  const { apiName, displayName } = column;
  
                  const apiCount = responseData[apiName];
  
                  if (apiCount !== undefined) {
                    return { ...column, count: apiCount };
                  } else {
                    console.warn(`API response for ${displayName} is undefined or missing.`);
                    return column;
                  }
                });
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.error('user response', error);
      }
    };

  //api call when change in selected date 
//   useEffect(() => {
//     getDescrepancyStatus();
//   }, [])

  //blinking effect based on the alert status key 
//   useEffect(() => {
//     // Start blinking when alert_stat is 1
//     if (alertStat === 1) {
//       const blinkInterval = setInterval(() => {
//         setHighlightBlink(prevState => !prevState);
//       }, 1000);

//       return () => {
//         clearInterval(blinkInterval);
//       };
//     } else {
      
//       setHighlightBlink(false);
//     }
//   }, [alertStat]);

  //highlight the coloumn in stat values
  const getColumnNameStyle = (column: any) => {
    if (column.displayName.includes('Pending Exceptions') && highlightBlink) {
      return `highlight ${highlightBlink ? 'blink' : ''} column-style redText`;
    } else if (column.displayName === 'Pending Exceptions') {
      return 'column-style yellowText';
    } else if (column.displayName === 'TOTAL PLATES') {
      return 'reconcilationBoldText yellowText';
    } else {
      return 'column-style';
    }
  };


  const getColumnCountStyle = (column: any) => {
    if (column.displayName === 'TOTAL PLATES') {
      return `countStyle reconcilationBoldText`;
    } else {
      return 'countStyle';
    }
  };


return (
  <div className="container">
  <h2 className="head-style" title="Click here to View plate Reconciliation" onClick={navigateToOtherPage}>
    {heading}
  </h2>
  <div className="columns" style={{ padding: "10px" }}>
    {columnsData.map((column) => (
      <div key={column.id} className="column" style={column.id === columnsData.length ? lastColumnStyle : columnStyle}>
        <div className="linkStyle" onClick={() => switchToPage(column.id)}>
        <span className={getColumnNameStyle(column)}>
            {column.displayName}
            {column.displayName === 'Pending Exceptions' && highlightBlink && (
              <span className={`highlight ${highlightBlink ? 'blink' : ''} column-style redText`}>
                &nbsp;
              </span>
            )}     
          </span>
          <span className={getColumnCountStyle(column)}>{column.count}</span>
        </div>
      </div>
    ))}
  </div>
</div>
);
};

export default PlateReconciliation;
