import React, { useEffect, useState } from "react";
import CustomToast from "../../../widgets/toaster";
import { CiCircleRemove } from "react-icons/ci";
import Select from "react-select";
import { columns, Lotcolumns, Platecolumns } from "./partials/columns";
import { FaMinus, FaPlus } from "react-icons/fa";
import { userService } from "../../../service/plateServices";
import { PlateReconcilationApi } from "../../../service/plateReconcilation";
import { userService as plateRelatedService } from "./../../../service/plateServices";
import PageHeader from "../../common/pageHeader";
import HeaderSection from "../../common/headerSection";
import { visualInspection } from "../../../service/visualinspection";

const PlateReconcilationTreeTable = () => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [expandedLotTable, setExpandedLottable] = useState<number[]>([]);
  const [plateBatches, setPlateBatches] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [plateLotCode, setPlateLotCode] = useState([]);
  const [scanCode, setScanCode] = useState("");
  const [plateBySerielNo, setPlateBySerielNo] = useState<Array<any>>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("success");
  const [toastType, setToastType] = useState("success");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [batchlist, setBatchList] = useState<Array<any>>([]);
  const [selectedBatch, setSelectedBatch] = useState("");
  /*Batch details variable ends here*/

  const [BatchByPage, setBatchByPage] = useState(1);
  const [BatchByPageSize, setBatchByPageSize] = useState(5);
  const [BatchByTotalPages, setBatchByTotalPages] = useState(0);
  const [BatchByTotalElements, setBatchByTotalElements] = useState(0);
  const [sort, setSort] = useState("");
  const [keyword, setKeyword] = useState("");
  const [lotList, setLotList] = useState<Array<any>>([]);
  const [batchid, setBatchId] = useState("");
  const [lotid, setLotId] = useState("");
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [lot_id_flag, set_id_flag] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [expandedPlateDetails, setExpandedPlateDetails] = useState<number[]>(
    []
  );
  const [selectedPlateDetails, setSelectedPlateDetails] = useState<{
    [key: number]: any;
  }>({});

  const toggleRow = (index: number) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((i) => i !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
      const batchId = batchlist[index].id;
      setBatchId(batchId);
    }
  };

  const toggleRowLotTable = (index: number) => {
    if (expandedLotTable.includes(index)) {
      setExpandedLottable(expandedLotTable.filter((i) => i !== index));
    } else {
      setExpandedLottable([...expandedLotTable, index]);
      const lotId = lotList[index].id;
      setLotId(lotId);
    }
  };

  const toggleRowPlateDetails = (index: number) => {
    if (expandedPlateDetails.includes(index)) {
      setExpandedPlateDetails(expandedPlateDetails.filter((i) => i !== index));
    } else {
      setExpandedPlateDetails([...expandedPlateDetails, index]);
      const selectedPlate = plateDetails[index];
      setSelectedPlateDetails((prevState: any) => ({
        ...prevState,
        [index]: selectedPlate,
      }));
    }
  };

  const handleSelectChange = (selectedOption: any) => {
    console.log(selectedOption, "selectedOption");
    if (!selectedOption) {
      setExpandedRows([]);
      setBatchList([]);
      setLotList([]);
      set_id_flag("");
      setPlateDetails([]);
      setExpandedLottable([]);
      setSerialNumber("");
      return;
    }

    if (selectedOption?.key === "batchCode") {
      setBatchList([]);
      setSelectedBatch(selectedOption?.value);
    }
    if (selectedOption?.key === "serielNumber") {
      setLotId(selectedOption?.lot_id);
      set_id_flag(selectedOption?.lot_id);
      setBatchId(selectedOption?.batch_id);
      getPlateDetailsBySerielNo(selectedOption?.value);
      setSerialNumber(selectedOption?.value)
    }
    if (selectedOption?.key === "lotCode") {
      getBatchList(selectedOption?.batchCode);
      setBatchId(selectedOption?.batch_id);
      set_id_flag(selectedOption?.value);
      setLotId(selectedOption?.id);
    }
  };

  const getPlateDetailsBySerielNo = async (slNo: string) => {
    let process = "test";
    if (slNo !== "") {
      console.log("have slno");
      try {
        const response = await plateRelatedService.listAutoClavePlateBySerielNo(
          slNo,
          process
        );
        if (response.status && response.statusCode === 200) {
          try {
            if (response.data && typeof response.data === "object") {
              let rowData = [
                {
                  slno: 1,
                  plateSerielId: response.data.id,
                  plate_type: plateRelatedService.getPlateStatus(
                    parseInt(response.data.plate_status)
                  ),
                  batch_number: response.data.batch_number,
                  serial_number: response.data.serial_number,
                  lot_pass_count: 0,
                },
              ];
              setSelectedBatch(response.data.batch_number);
              // setPlateBySerielNo(rowData);

              const startingSlno = plateBySerielNo.length + 1;
              rowData = rowData.map((item, index) => ({
                ...item,
                slno: startingSlno + index,
              }));

              const uniqueUpdatedDataWithSlno = rowData.filter(
                (newElement: any) => {
                  return !plateBySerielNo.some(
                    (existingElement) =>
                      existingElement.serial_number === newElement.serial_number
                  );
                }
              );

              setPlateBySerielNo((prevUserRoleList) => [
                ...prevUserRoleList,
                ...uniqueUpdatedDataWithSlno,
              ]);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          // setPlateBySerielNo([]);
          setToastMessage(response.message);
          setToastType("error");
          setShowToast(true);
        }
      } catch (error) {
        console.error("Plate response", error);
      }
    } else {
      console.log("no slno");
    }
  };

  //pass the obtained serial number to the api
  const handleScanSerielNo = (result: any) => {
    console.log("Original Result:", result);
    if (result.trim() !== "") {
      setScanCode(result);
      try {
        const parsedResult = JSON.parse(result);
        console.log("Parsed Result:", parsedResult);

        if (typeof parsedResult === "object") {
          let serialNumber;

          if (parsedResult.hasOwnProperty("serial_number")) {
            serialNumber = parsedResult.serial_number;
          } else {
            console.log(
              "No serial number property found in the parsed result."
            );
          }
          if (serialNumber) {
            getPlateDetailsBySerielNo(serialNumber);
            // setScanCode(serialNumber);
          }
        } else {
          console.log("Parsed result is not an object.");
        }
      } catch (error) {
        // getPlateDetailsBySerielNo(result);
        console.error("Error parsing JSON:", error);
      }
    }
  };

  const getBatchList = async (selectedBatch: any) => {
    try {
      const response = await PlateReconcilationApi.getBatchList(
        page,
        pageSize,
        selectedBatch
      );
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                const StatusConstants = {
                  QUARANTINE: "0",
                  QA_APPROVED: "1",
                  READY_TO_USE: "2",
                  ACTIVITY_INPROGRESS: "3",
                  QA_REJECTED: "4",
                  UNDER_TEST: "5",
                  BATCH_DISCARD: "6",
                };

                let status;

                // Mapping numeric status to string representation
                switch (element.batch_status) {
                  case StatusConstants.UNDER_TEST:
                    status = "Waiting for QA Approval";
                    break;
                  case StatusConstants.QA_APPROVED:
                    status = "QA APPROVED";
                    break;
                  case StatusConstants.READY_TO_USE:
                    status = "READY TO USE";
                    break;
                  case StatusConstants.ACTIVITY_INPROGRESS:
                    status = "ACTIVITY INPROGRESS";
                    break;
                  case StatusConstants.QA_REJECTED:
                    status = "QA REJECTED";
                    break;
                  case StatusConstants.QUARANTINE:
                    status = "QUARANTINE";
                    break;
                  case StatusConstants.BATCH_DISCARD:
                    status = "DISCARD";
                    break;
                  default:
                    status = "UNKNOWN STATUS";
                }

                return {
                  id: element.id,
                  slno: index + 1,
                  batch_code: element.batch_no,
                  batch_grn: element.batch_grn,
                  manufacturing_date: element.manufacturing_date,
                  plateType: element.plate_size_type_name,
                  quantity: element.plate_count,
                  lot_count: element.plate_count,
                  status: status,
                };
              }
            );
            setBatchList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setBatchList([]);
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  const ListLots = async (lot_id: any) => {
    setLotList([]);
    try {
      const response = await userService.ListLotsReconcilation(
        sort,
        keyword,
        batchid,
        lot_id
      );
      if (response.status && response.statusCode === 200) {
        if (Array.isArray(response.data) && response.data?.length > 0) {
          setBatchByTotalPages(response?.totalPages);
          setBatchByTotalElements(response?.totalElements);
          let rowData: any = response.data.map(
            (element: any, index: number) => {
              return {
                id: element.id,
                slno: index + 1,
                batch_no: element.batch_no,
                lot_code: element.lot_code,
                plate_count: element.plate_count,
                inspection_status: element.inspection_status,
              };
            }
          );
          setLotList(rowData);
        }
      } else {
        setLotList([]);
      }
    } catch (error) {
      return error;
    }
  };

  const getPlateList = async () => {
    try {
      const response = await PlateReconcilationApi.getPlateListApi(lotid, serialNumber);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  id: element.id,
                  slno: index + 1,
                  batch_number: element.batch_number,
                  serial_number: element.serial_number,
                  manufacturing_date: element.manufacturing_date,
                  expiry_date: element.expiry_date,
                  plate_type: element.plate_type,
                  exposure_details: element.exposure_details,
                  finger_dab_details: element.finger_dab_details,
                  plate_sampling_details: element.plate_sampling_details,
                  raw_material_test_details: element.raw_material_test_details,
                  surface_monitoring_details:
                    element.surface_monitoring_details,
                  incubation_details: element.incubation_details,
                  lab_details: element.lab_details,
                  autoclave_details: element.autoclave_details,
                };
              }
            );
            setPlateDetails(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setPlateDetails([]);
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  useEffect(() => {
    if (selectedBatch) {
      getBatchList(selectedBatch);
    }
  }, [selectedBatch]);

  useEffect(() => {
    if (batchid && lot_id_flag) {
      ListLots(lot_id_flag);
    } else {
      ListLots("");
    }
  }, [batchid]);

  useEffect(() => {
    if (lotid) {
      getPlateList();
    }
  }, [lotid]);

  //batch listing dropdown
  const listPlateBatch = async () => {
    try {
      const response = await PlateReconcilationApi.listPlateBatch();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.batch_number,
                  label: element.batch_number,
                  key: "batchCode",
                  batch_id: element.id,
                };
              }
            );
            setPlateBatches(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //serial number listing for table drop down
  const listSerialNumber = async () => {
    try {
      const response = await plateRelatedService.listSerialNumber();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.serial_number,
                  label: element.serial_number,
                  lot_id: element.lot_id,
                  batch_id: element.batch_id,
                  key: "serielNumber",
                };
              }
            );
            setPlateSerielNums(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //lot code listing for table drop down
  const listLotCode = async () => {
    try {
      const response = await visualInspection.listFilterLots();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.id,
                  label: element.lot_code,
                  batchCode: element.batch_no,
                  key: "lotCode",
                };
              }
            );
            setPlateLotCode(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  useEffect(() => {
    listPlateBatch();
    listLotCode();
    listSerialNumber();
  }, []);

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Plate Reconciliation" />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      {/* Table 1 */}
      <div className="d-flex">
        <div className="scanWrap pt-3" style={{ width: "45%" }}>
          {/* <label>Scan Plate</label>
          <div className="scanInputWrap p-2">
            <input
              onChange={(e) => handleScanSerielNo(e.target.value)}
              placeholder="Scan QR Code"
              value={scanCode}
            />
            {scanCode && (
              <CiCircleRemove
                onClick={() => setScanCode("")}
                fontSize={30}
                color="red"
                className="mt-1"
                style={{ cursor: "pointer" }}
              />
            )}
          </div> */}
        </div>
        <div className="d-flex justify-content-end">
          <div style={{ zIndex: "10" }} className="mx-2 my-3">
            <label htmlFor="areaTypeSelect" className="filterLabelLeft">
              Batch Code
            </label>
            <div style={{ zIndex: "10" }}>
              <Select
                options={plateBatches}
                onChange={handleSelectChange}
                placeholder="Batch code"
                isSearchable={true}
                isClearable
                className="areaTypeSelect2 me-3"
              />
            </div>
          </div>
          <div style={{ zIndex: "10" }} className="mx-2 my-3">
            <label htmlFor="areaTypeSelect" className="filterLabelLeft">
              Lot Code
            </label>
            <div style={{ zIndex: "10" }}>
              <Select
                options={plateLotCode}
                onChange={handleSelectChange}
                isClearable
                placeholder="Lot Code"
                isSearchable={true}
                className="areaTypeSelect2 me-3"
              />
            </div>
          </div>
          <div style={{ zIndex: "10" }} className="mx-2 my-3">
            <label htmlFor="areaTypeSelect" className="filterLabelLeft">
              Serial Number
            </label>
            <div style={{ zIndex: "10" }}>
              <Select
                options={plateSerielNum}
                onChange={handleSelectChange}
                isClearable
                placeholder="Serial Number"
                isSearchable={true}
                className="areaTypeSelect2 me-3"
              />
            </div>
          </div>
        </div>
      </div>
      <table className="custom-table-reconcilation">
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.accessor}>{col.Header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {batchlist.map((item, index) => (
            <React.Fragment key={item.id}>
              <tr>
                {columns.map((col) => (
                  <td key={col.accessor}>{item[col.accessor]}</td>
                ))}
                <td>
                  {/* Expander icon */}
                  {expandedRows.includes(index) ? (
                    <FaMinus
                      onClick={() => toggleRow(index)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <FaPlus
                      onClick={() => toggleRow(index)}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </td>
              </tr>
              {/* Additional row for expanded content */}
              {expandedRows.includes(index) && (
                <tr>
                  <td colSpan={columns.length + 1}>
                    {/* Table 2 content */}
                    <table className="nested-table">
                      <thead>
                        <tr>
                          {/* Define headers for Table 2 */}
                          {Lotcolumns.map((col) => (
                            <th key={col.accessor}>{col.Header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {lotList.map((item, index) => (
                          <React.Fragment key={item.id}>
                            <tr>
                              {Lotcolumns.map((col) => (
                                <td key={col.accessor}>{item[col.accessor]}</td>
                              ))}
                              <td>
                                {/* Expander icon */}
                                {expandedLotTable.includes(index) ? (
                                  <>
                                    <FaMinus
                                      onClick={() => toggleRowLotTable(index)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <FaPlus
                                      onClick={() => toggleRowLotTable(index)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </>
                                )}
                              </td>
                            </tr>
                            {/* Additional row for expanded content */}
                            {expandedLotTable.includes(index) && (
                              <tr>
                                <td colSpan={columns.length + 1}>
                                  <table className="nested-table">
                                    <thead>
                                      <tr>
                                        {Platecolumns.map((col) => (
                                          <th key={col.accessor}>
                                            {col.Header}
                                          </th>
                                        ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {plateDetails.map((item, index) => (
                                        <React.Fragment key={item.id}>
                                          <tr>
                                            {Platecolumns.map((col) => (
                                              <td key={col.accessor}>
                                                {item[col.accessor]}
                                              </td>
                                            ))}
                                            <td>
                                              {/* Expander icon */}
                                              {expandedPlateDetails.includes(
                                                index
                                              ) ? (
                                                <>
                                                  <FaMinus
                                                    onClick={() =>
                                                      toggleRowPlateDetails(
                                                        index
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  <FaPlus
                                                    onClick={() =>
                                                      toggleRowPlateDetails(
                                                        index
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                          {expandedPlateDetails.includes(
                                            index
                                          ) && (
                                            <tr>
                                              <td colSpan={columns.length + 1}>
                                                <table className="nested-table">
                                                  <tbody>
                                                    {/* exposureDetails */}
                                                    {selectedPlateDetails[
                                                      index
                                                    ]?.exposure_details?.map(
                                                      (
                                                        detail: any,
                                                        idx: number
                                                      ) => (
                                                        <tr key={idx}>
                                                          <th scope="row">
                                                            Exposure Details -{" "}
                                                            {idx + 1}
                                                          </th>
                                                          <td>
                                                            <div className="custom-key">
                                                              Exposed Area Name:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.exposure_area_name ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Exposed By:
                                                            </div>
                                                            <div className="custom-value">{`${
                                                              detail?.created_by_first_name ??
                                                              "-"
                                                            } ${
                                                              detail?.created_by_last_name ??
                                                              "-"
                                                            }`}</div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Exposed Date &
                                                              Time:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.start_date_time ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Collected By:
                                                            </div>
                                                            <div className="custom-value">{`${
                                                              detail?.updated_by_first_name ??
                                                              "-"
                                                            } ${
                                                              detail?.updated_by_last_name ??
                                                              "-"
                                                            }`}</div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Collected Date &
                                                              Time:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.updated_at ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    {/* fingerDabDetails */}
                                                    {selectedPlateDetails[
                                                      index
                                                    ]?.finger_dab_details?.map(
                                                      (
                                                        detail: any,
                                                        idx: number
                                                      ) => (
                                                        <tr key={idx}>
                                                          <th scope="row">
                                                            Finger Dab Details -{" "}
                                                            {idx + 1}
                                                          </th>
                                                          <td>
                                                            <div className="custom-key">
                                                              Finger Dab Area
                                                              Name:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.location_name ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Finger Dabbed By:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.completed_by ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Dabbed Date &
                                                              Time:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.completed_date_time ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Finger Dab Type:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.finger_dab_option_id ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Finger Dab Option:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.finger_dab_option_id ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    {/* surfaceMonitoringDetails */}
                                                    {selectedPlateDetails[
                                                      index
                                                    ]?.surface_monitoring_details?.map(
                                                      (
                                                        detail: any,
                                                        idx: number
                                                      ) => (
                                                        <tr key={idx}>
                                                          <th scope="row">
                                                            Surface Monitoring
                                                            Details - {idx + 1}
                                                          </th>
                                                          <td>
                                                            <div className="custom-key">
                                                              Surface Monitoring
                                                              Area Name:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.location_name ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Surface Monitoring
                                                              Start Date:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.start_date_time ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    {/* rawMaterialTestDetails */}
                                                    {selectedPlateDetails[
                                                      index
                                                    ]?.raw_material_test_details?.map(
                                                      (
                                                        detail: any,
                                                        idx: number
                                                      ) => (
                                                        <tr key={idx}>
                                                          <th scope="row">
                                                            Raw Material Details
                                                            - {idx + 1}
                                                          </th>
                                                          <td>
                                                            <div className="custom-key">
                                                              Material Type
                                                              Name:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.material_type_name ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Conducted By:
                                                            </div>
                                                            <div className="custom-value">{`${
                                                              detail?.ended_user_firstname ??
                                                              "-"
                                                            } ${
                                                              detail?.ended_user_lastname ??
                                                              "-"
                                                            }`}</div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Test Date:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.completed_date_time ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    {/* plateSamplingDetails */}
                                                    {selectedPlateDetails[
                                                      index
                                                    ]?.plate_sampling_details?.map(
                                                      (
                                                        detail: any,
                                                        idx: number
                                                      ) => (
                                                        <tr key={idx}>
                                                          <th scope="row">
                                                            Plate Sampling
                                                            Details - {idx + 1}
                                                          </th>
                                                          <td>
                                                            <div className="custom-key">
                                                              Plate Sampling
                                                              Type:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.test_type_name ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Sampled By:
                                                            </div>
                                                            <div className="custom-value">{`${
                                                              detail?.ended_user_firstname ??
                                                              "-"
                                                            } ${
                                                              detail?.ended_user_lastname ??
                                                              "-"
                                                            }`}</div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Sampled Date:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.completed_date_time ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    {/* incubationDetails */}
                                                    {selectedPlateDetails[
                                                      index
                                                    ]?.incubation_details?.map(
                                                      (
                                                        detail: any,
                                                        idx: number
                                                      ) => (
                                                        <tr key={idx}>
                                                          <th scope="row">
                                                            Incubation Details -{" "}
                                                            {idx + 1}
                                                          </th>
                                                          <td>
                                                            <div className="custom-key">
                                                              Incubation Area
                                                              Name:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.incubator_name ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Incubated By:
                                                            </div>
                                                            <div className="custom-value">{`${
                                                              detail?.created_by_first_name ??
                                                              "-"
                                                            } ${
                                                              detail?.created_by_last_name ??
                                                              "-"
                                                            }`}</div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Incubation Date &
                                                              Time:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.start_date_time ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Incubation Stop:
                                                            </div>
                                                            <div className="custom-value">{`${
                                                              detail?.updated_by_first_name ??
                                                              "-"
                                                            } ${
                                                              detail?.updated_by_last_name ??
                                                              "-"
                                                            }`}</div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Incubation out
                                                              Date & Time:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.end_date_time ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    {/* labDetails */}
                                                    {selectedPlateDetails[
                                                      index
                                                    ]?.lab_details?.map(
                                                      (
                                                        detail: any,
                                                        idx: number
                                                      ) => (
                                                        <tr key={idx}>
                                                          <th scope="row">
                                                            Lab Test Details -{" "}
                                                            {idx + 1}
                                                          </th>
                                                          <td>
                                                            <div className="custom-key">
                                                              Lab Test Conducted
                                                              by:
                                                            </div>
                                                            <div className="custom-value">{`${
                                                              detail.collected_by_user_firstname ??
                                                              "-"
                                                            } ${
                                                              detail.collected_by_user_lastname ??
                                                              "-"
                                                            }`}</div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Lab Test Date &
                                                              Time:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail.start_date_time ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Colony Count:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail.colony_count ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    {/* autoclaveDetails */}
                                                    {selectedPlateDetails[
                                                      index
                                                    ]?.autoclave_details?.map(
                                                      (
                                                        detail: any,
                                                        idx: number
                                                      ) => (
                                                        <tr key={idx}>
                                                          <th scope="row">
                                                            Autoclave Details -{" "}
                                                            {idx + 1}
                                                          </th>
                                                          <td>
                                                            <div className="custom-key">
                                                              Autoclave Area
                                                              Name:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail?.location_name ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Autoclave By:
                                                            </div>
                                                            <div className="custom-value">{`${
                                                              detail?.started_by_user_firstname ??
                                                              "-"
                                                            } ${
                                                              detail?.started_by_user_lastname ??
                                                              "-"
                                                            }`}</div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Autoclave Date &
                                                              Time:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail.start_time ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Autoclave Stop:
                                                            </div>
                                                            <div className="custom-value">{`${
                                                              detail?.ended_user_firstname ??
                                                              "-"
                                                            } ${
                                                              detail?.ended_user_lastname ??
                                                              "-"
                                                            }`}</div>
                                                          </td>
                                                          <td>
                                                            <div className="custom-key">
                                                              Autoclave out Date
                                                              & Time:
                                                            </div>
                                                            <div className="custom-value">
                                                              {detail.end_time ??
                                                                "-"}
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          )}
                                        </React.Fragment>
                                      ))}

                                      {/* <tr>
                                          {plateDetails.map((col) => (
                                              <th key={col.accessor}>
                                                {item[col.accessor]}
                                              </th>
                                            ))}
                                          </tr> */}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlateReconcilationTreeTable;
