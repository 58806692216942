import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );

const getExposureStatus = async (from_date: string, to_date: string, block_id: string, plant_id: string, unit_id:string, area_id: string) => {
try {
    const response = await axiosInstance.get(`${baseUrl}/historical_dashboard/exposure_stat?from_date_time=${from_date}&to_date_time=${to_date}&plant_id=${plant_id}&unit_id=${unit_id}&block_id=${block_id}&area_id=${area_id}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
        return error.response?.data
    }
}
}

const ListPlateForExposure =  async (page :number, pageSize :number, sort :string, order : string,from_date: string, to_date: string, block_id: string, plant_id: string, unit_id:string, area_id: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/historical_dashboard/list_exposure_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&from_date_time=${from_date}&to_date_time=${to_date}&plant_id=${plant_id}&unit_id=${unit_id}&block_id=${block_id}&area_id=${area_id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}

const getIncubationStatus = async (from_date: string, to_date: string, block_id: string, plant_id: string, unit_id:string, area_id: string) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/historical_dashboard/incubation_stat?from_date_time=${from_date}&to_date_time=${to_date}&plant_id=${plant_id}&unit_id=${unit_id}&block_id=${block_id}&area_id=${area_id}`,
        {
          headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
          }
        });
        return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
            return error.response?.data
        }
    }
}

const ListPlateForIncubation =  async (page :number, pageSize :number, sort :string, order : string,from_date: string, to_date: string, block_id: string, plant_id: string, unit_id:string, area_id: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/historical_dashboard/list_incubator_stat?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&from_date_time=${from_date}&to_date_time=${to_date}&plant_id=${plant_id}&unit_id=${unit_id}&block_id=${block_id}&area_id=${area_id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}

const getLabAreaStatus = async (from_date: string, to_date: string, block_id: string, plant_id: string, unit_id:string, area_id: string) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/historical_dashboard/lab_stat?from_date_time=${from_date}&to_date_time=${to_date}&plant_id=${plant_id}&unit_id=${unit_id}&block_id=${block_id}&area_id=${area_id}`,
        {
          headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
          }
        });
        return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
            return error.response?.data
        }
    }
}

const ListPlateForLabtest =  async (page :number, pageSize :number, sort :string, order : string,from_date: string, to_date: string, block_id: string, plant_id: string, unit_id:string, area_id: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/historical_dashboard/list_lab_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&from_date_time=${from_date}&to_date_time=${to_date}&plant_id=${plant_id}&unit_id=${unit_id}&block_id=${block_id}&area_id=${area_id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}

const getAutoclaveStatus = async (from_date: string, to_date: string, block_id: string, plant_id: string, unit_id:string, area_id: string) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/historical_dashboard/autoclave_stat?from_date_time=${from_date}&to_date_time=${to_date}&plant_id=${plant_id}&unit_id=${unit_id}&block_id=${block_id}&area_id=${area_id}`,
        {
          headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
          }
        });
        return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
            return error.response?.data
        }
    }
}

const ListPlateForAutoclave =  async (page :number, pageSize :number, sort :string, order : string,from_date: string, to_date: string, block_id: string, plant_id: string, unit_id:string, area_id: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/historical_dashboard/list_autoclave_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&from_date_time=${from_date}&to_date_time=${to_date}&plant_id=${plant_id}&unit_id=${unit_id}&block_id=${block_id}&area_id=${area_id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}

const historicalServices = {
    getExposureStatus,
    ListPlateForExposure,
    getIncubationStatus,
    ListPlateForIncubation,
    getLabAreaStatus,
    ListPlateForLabtest,
    getAutoclaveStatus,
    ListPlateForAutoclave
  };
  
  export { historicalServices };