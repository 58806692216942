export const columns: TableColumn[] = [
  {
    Header: "tableCommon.sl_no",
    accessor: "slno",
  },
  {
    Header: "scheduleProduction.tableColumns.production_schedule_name",
    accessor: "production_schedule_name",
  },
  {
    Header: "scheduleProduction.tableColumns.product_name",
    accessor: "product_name",
  },
  {
    Header: "scheduleProduction.tableColumns.production_type",
    accessor: "production_type",
  },
  {
    Header: "scheduleProduction.tableColumns.from_date_time",
    accessor: "from_date_time",
  },
  {
    Header: "scheduleProduction.tableColumns.to_date_time",
    accessor: "to_date_time",
  },
  {
    Header: "scheduleProduction.tableColumns.activity_type_name",
    accessor: "activity_type_name",
  },
  {
    Header: "scheduleProduction.tableColumns.area_name",
    accessor: "area_name",
  },
];

export const opColumns: TableColumn[] = [
  {
    Header: "tableCommon.sl_no",
    accessor: "slno",
  },
  {
    Header: "scheduleProduction.tableColumns.plateCount",
    accessor: "plateCount",
  },
  {
    Header: "scheduleProduction.tableColumns.location_name",
    accessor: "location_name",
  },
  {
    Header: "scheduleProduction.tableColumns.operator_name",
    accessor: "operator_name",
  },
];

interface TableColumn {
  Header: string;
  accessor: string;
}
