import React, { useEffect, useState } from "react";
import CustomTable from "../../../widgets/table/index";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import { userService } from "../../../service/plateServices";
import Pagination from "../../../widgets/pagination";
import AlertModal from "../../../widgets/alertModal";
import { columns } from './partials/columns';
import RequestViewModel from "./models/labReportViewModel";
import { dashboardServices } from "../../../service/dashboard";

type LabtestStatType = {
  pending_plates_for_lab_approval: number;
  lab_approved_plates: number;
  escalations: number;
};


const LabReport = () => {
  const [tab, setTab] = useState("1");
  const [showView, setShowView] = useState(false);
  const [historyView, setHistoryView] = useState(false);
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [plateDetailsHistory, setPlateDetailsHistory] = useState<Array<any>>([]);
  const [singlePlateDetails, setSinglePlateDetails] = useState<any>({});
  const [incubationDetails, setIncubationDetails] = useState<any>([]);
  const [exposureDetails, setExposureDetails] = useState<any>([]);
  const [fingureDabDetails, setFingureDabDetails] = useState<any>([]);
  const [plateSamplingDetails, setPlateSamplingDetails] = useState<any>([]);
  const [rawMaterialDetails, setRawMaterialDetails] = useState<any>([]);
  const [surfaceMonitoringDetails, setSurfaceMonitoringDetails] = useState<any>([]);

  const [labTestDetails, setLabTestDetails] = useState<any>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [plateBatches, setPlateBatches] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [keyWord, setkeyWord] = useState("");
  const [historyKeyWord, setHistoryKeyWord] = useState("");
  const [userId, setUserId] = useState<number>(0);
  const [showApproveAlert, setShowApproveAlert] = useState(false)
  const [showRejectAlert, setShowRejectAlert] = useState(false)
  const [currentExposureIndex, setCurrentExposureIndex] = useState(0);
  const [currentSamplingIndex, setCurrentSamplingIndex] = useState(0);
  const [currentRawmaterialTestIndex, setCurrentRawmaterialTestIndex] = useState(0);
  const [currentFingerDabIndex, setCurrentFingerDabIndex] = useState(0);
  const [currentIncubationIndex, setCurrentIncubationIndex] = useState(0);
  const [currentLabTestIndex, setCurrentLabTestIndex] = useState(0);
  const [currentSurfaceMonitoringIndex, setCurrentSurfaceMonitoringIndex] = useState(0);
  const [labtestStat, setLabTestStat] = useState<LabtestStatType | null>(null);
  const [selectedTab, setSelectedTab] = useState<number | null>(null);

  // pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  //history pagination
  const [historyPage, setHistoryPage] = useState(1);
  const [historyPageSize, setHistoryPageSize] = useState(5);
  const [historyTotalPages, setHistoryTotalPages] = useState(0);
  const [historyTotalElements, setHistoryTotalElements] = useState(0);



  useEffect (() => {
    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id)
    }
    getLabtestStatuDetails();
    getLabApprovedPlates();
    getLabPlatesHistory()
    listPlateBatch()
    listSerialNumber()
    setUseEffectRequired(false)
  }, [useEffectRequired]) 

  const getLabtestStatuDetails = async () => {
    try {
      const response = await dashboardServices.getLabtestStatuDetails(); 
        if(response.status && response.statusCode === 200){
          setLabTestStat({
  
            pending_plates_for_lab_approval: response.data.pending_plates_for_lab_approval,
            lab_approved_plates : response.data.lab_approved_plates,
            escalations : response.data.escalations,
            });
      }
    } catch (error) {
      console.error(error);
    }
    };
  
  const handleViewClick = (id: string, viewValue: boolean) => {
    console.log("ok")
    const updatedData = plateDetails.filter((row) => row.slno === id);
    scanPlateById(updatedData[0]?.plate_id)
    setShowView(viewValue);
  };

  const handleHistoryView = (id: string, viewValue: boolean) => {
    const updatedData = plateDetailsHistory?.filter((row) => row.slno === id);
    scanPlateById(updatedData[0]?.plate_id)
    setHistoryView(viewValue);
  };

  const getLabApprovedPlates = async () => {
      try {
        const response = await userService.getLabApprovedPlates(page, pageSize, keyWord);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages)
                setTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {
  
                   const statusNumber = parseInt(element.status, 10); 
                   if (statusNumber == 21){
                    var status = "pending"
                   }else{
                    var status = "unknown"
                   }

                   const plateType = parseInt(element.plate_type, 10); 
                    if (plateType == 1){
                      var plate_type = "Q A Plate"
                    }else if (plateType == 2){
                      var plate_type = "Exposure Plate"
                    }else if (plateType == 3){
                      var plate_type = "Finger DAB Plate"
                    }else if (plateType == 4){
                      var plate_type = "Sampling Plate"
                    }else if (plateType == 5){
                      var plate_type = "Material Test Plate"
                    }else if (plateType == 6){
                      var plate_type = "Surface Monitoring Plate"
                    } else if (plateType == 7){
                      var plate_type = "Surface Monitoring - SWAB Collection"
                    }else{
                      var plate_type = "-"
                    }
                  
                  return { 
                      slno: index + 1, 
                      batch_id: element.batch_number,
                      plate_serial_number: element.serial_number,
                      colony_count: element.colony_count,
                      plate_type : plate_type,
                      status : status,
                      id : element.id,
                      plate_id: element.plate_id,
                      lab_approved_date: element.approved_date
                  };
                });
                setPlateDetails(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        } else {
          setPlateDetails([])
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    };

  const getLabPlatesHistory = async () => {
      try {
        const response = await userService.getLabPlatesHistory(historyPage, historyPageSize, historyKeyWord);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setHistoryTotalPages(response?.totalPages)
                setHistoryTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {
  
                   const statusNumber = parseInt(element.status, 10); 
                   if (statusNumber == 22){
                    var status = "Approved"
                   }else if (statusNumber == 23){
                    var status = "Rejected"
                   }else{
                    var status = "unknown"
                   }
                   const plateType = parseInt(element.plate_type, 10); 
                    if (plateType == 1){
                      var plate_type = "Q A Plate"
                    }else if (plateType == 2){
                      var plate_type = "Exposure Plate"
                    }else if (plateType == 3){
                      var plate_type = "Finger DAB Plate"
                    }else if (plateType == 4){
                      var plate_type = "Sampling Plate"
                    }else if (plateType == 5){
                      var plate_type = "Material Test Plate"
                    }else if (plateType == 6){
                      var plate_type = "Surface Monitoring Plate"
                    }else if (plateType == 7){
                      var plate_type = "Surface Monitoring - SWAB Collection"
                    } else{
                      var plate_type = "-"
                    }
                   
                  const fullNamesLabtest = `${element.completed_by_user_firstname} ${element.completed_by_user_lastname}`
                  return { 
                      slno: index + 1, 
                      batch_id: element.batch_number,
                      plate_serial_number: element.serial_number,
                      colony_count: element.colony_count,
                      plate_type : plate_type,
                      status : status,
                      id : element.id,
                      plate_id: element.plate_id,
                      lab_approved_date: element.approved_date,
                      approved_by: fullNamesLabtest
                  };
                });
                setPlateDetailsHistory(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        } else {
          setPlateDetailsHistory([])
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    };

  const scanPlateById = async (id:any) => {
    const dataToSend = {
      "id" : id
    }
    const response = await userService.scanPlateById(dataToSend);
    if(response.status && response.statusCode === 200){
      console.log(response, "response--")


      const plateType = parseInt(response?.data.plate_type, 10); 
                    if (plateType == 1){
                      var plate_type = "Q A Plate"
                    }else if (plateType == 2){
                      var plate_type = "Exposure Plate"
                    }else if (plateType == 3){
                      var plate_type = "Finger DAB Plate"
                    }else if (plateType == 4){
                      var plate_type = "Sampling Plate"
                    }else if (plateType == 5){
                      var plate_type = "Material Test Plate"
                    }else if (plateType == 6){
                      var plate_type = "Surface Monitoring Plate"
                    }else if (plateType == 7){
                      var plate_type = "Surface Monitoring - SWAB Collection"
                    } else{
                      var plate_type = "-"
                    }

      const statusNumber = parseInt(response?.data.status, 10); 
                   if (statusNumber == 21){
                    var status = "pending"
                   }else{
                    var status = "unknown"
                   }

      const basicDetails = {
        "id": response.data.id,
        "serial_number": response.data.serial_number,
        "batch_number": response.data.batch_number,
        "plate_status": response.data.plate_status,
        "status": status,
        "expiry_date": response.data.expiry_date,
        "manufacturing_date": response.data.manufacturing_date,
        "current_location_id": response.data.current_location_id,
        "plate_type": plate_type,
      }

      let exposureDetails:any = response.data?.exposure_details?.map((element: any, index: number) => {
        return {
          exposedAreaName : element.exposure_area_name || "-",
          exposedBy : element.created_by_first_name + " " + element.created_by_last_name || "-",
          exposedDate : element.start_date_time,
          collectedBy : element.updated_by_first_name + " " + element.updated_by_last_name || "-",
          collectedDate : element.end_date_time || "-",
        }
      })

      let fingureDabDetails = response.data?.fingerdab_details?.map((element: any, index: number) => {
        return {
          fingerDabAreaName : element.location_name || "-",
          fingerDabbededBy : element.finger_dab_operator_name || "-",
          dabbedDate : element.completed_date_time || "-",
          fingerDabType: element.started_by_user_firstname + " " + element.started_by_user_lastname || "-",
          figerDabOption : element.finger_dab_option_name || "-",

        }
      })

      let plateSamplingDetails:any = response.data?.plate_sampling_details?.map((element: any, index: number) => {
        return {
          plateSamplingType : element.test_type_name || "-",
          sampledBy : element.started_by_user_firstname + " " + element.started_by_user_lastname || "-",
          sampledDate : element.start_date_time || "-",
        }
      })

      let rawMaterialDetails:any = response.data?.raw_material_test_details?.map((element: any, index: number) => {
        return {
          rawMaterialTypeName : element.material_type_name || "-",
          conductedBy : element.started_by_user_firstname + " " + element.started_by_user_lastname || "-",
          testDate : element.start_date_time || "-",
        }
      })

      let surfaceMonitoringDetails:any = response.data?.surface_monitoring_details?.map((element: any, index: number) => {
        return {
          surfaceMonitoringAreaName : element.location_name || "-",
          surfaceMonitoringStartDate : element.start_date_time || "-",
        }
      })


      let incubationDetails:any = response.data?.incubation_details?.map((element: any, index: number) => {
        return {
          incubationAreaName: element.incubator_name || "-",
          incubatedBy : element.created_by_first_name + " " + element.created_by_last_name || "-",
          incubationDateTime : element.start_date_time || "-",
          incubationOutBy : element.updated_by_first_name + " " + element.updated_by_last_name || "-",
          incubationoutDateTime : element.end_date_time || "-",
        }
      })

      let labTestDetails:any = response.data?.lab_test_details?.map((element: any, index: number) => {
        return {
          conductedBy: element.completed_by_user_firstname + " " + element.completed_by_user_lastname || "-",
          dateAndTime : element.completed_date_time || "-",
          colonyCount : element.colony_count || "-",
          imageId : element.plate_image_id || "",
          id: element.id || "-",
          species: element.species || "-",
          genus: element.genus || "-",
        }
      })


      setSinglePlateDetails(basicDetails)
      setExposureDetails(exposureDetails)
      setFingureDabDetails(fingureDabDetails)
      setPlateSamplingDetails(plateSamplingDetails)
      setRawMaterialDetails(rawMaterialDetails)
      setIncubationDetails(incubationDetails)
      setLabTestDetails(labTestDetails)
      setSurfaceMonitoringDetails(surfaceMonitoringDetails)
    }
  }

  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  const handleHistoryPageChange = (newPage : any, pageSize?: number) => {
    setHistoryPage(newPage);
    if(pageSize) {
      setHistoryPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  const handleFilter = (key: string, value: any) => {
    if (key === "search") {
      setkeyWord(value)
    }
    setUseEffectRequired(true)
  }

  const handleHistoryFilter = (key: string, value: any) => {
    if (key === "search") {
      setHistoryKeyWord(value)
    }
    setUseEffectRequired(true)
  }

  const listPlateBatch = async () => {
    try {
      const response = await userService.listPlateBatch();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.batch_number,
                    label: element.batch_number ,
                };
              });
              setPlateBatches(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  const listSerialNumber = async () => {
    try {
      const response = await userService.listSerialNumber();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.serial_number,
                    label: element.serial_number ,
                };
              });
              setPlateSerielNums(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  const handleClose = () => {
    setShowView(false)
    setSinglePlateDetails({})
    setExposureDetails([])
    setFingureDabDetails([])
    setPlateSamplingDetails([])
    setRawMaterialDetails([])
    setIncubationDetails([])
    setLabTestDetails([])
  };

  const handleHistoryClose = () => {
    setHistoryView(false)
    setSinglePlateDetails({})
    setExposureDetails([])
    setFingureDabDetails([])
    setPlateSamplingDetails([])
    setRawMaterialDetails([])
    setIncubationDetails([])
    setLabTestDetails([])
  };

  const handleApproveBtn = (selectedRows : any[]) => {
    const filteredPlateDetails = plateDetails.filter((item) => selectedRows.includes(item.slno));
    const selectedIds = filteredPlateDetails.map((item) => item.id).join(',');
    const status = "22"
    changeLabReportStatus(selectedIds, status)
  }

  const handleRejectBtn = (selectedRows : any[]) => {
    const filteredPlateDetails = plateDetails.filter((item) => selectedRows.includes(item.slno));
    const selectedIds = filteredPlateDetails.map((item) => item.id).join(',');
    const status = "23"
    changeLabReportStatus(selectedIds, status)
  }

  const changeLabReportStatus = async (selectedIds: any, status : any) => {

    const dataToSend = {
        lab_test_ids : selectedIds,
        user_id : userId,
        lab_test_status : status,
        approver_remarks : "",
        interface_name : "Lab Report"
    }

    try {
      const response = await userService.changeLabReportStatus(dataToSend);
      if(response.status && response.statusCode === 200){
      }
    } catch (error) {
      console.error('Plate response', error);
    }
    setShowApproveAlert(false)
    setShowRejectAlert(false)
    handleClose();
    setkeyWord("")
    setUseEffectRequired(true)
  }

  const handleNextClick = (currentIndex : number) => {
    if (currentIndex === 1){
      if (currentExposureIndex < exposureDetails.length - 1) {
        setCurrentExposureIndex(currentExposureIndex + 1);
      }
    } else if (currentIndex === 2) {
      if (currentIncubationIndex < incubationDetails.length - 1) {
        setCurrentIncubationIndex(currentIncubationIndex + 1);
      }
    } else if (currentIndex === 3) {
      if (currentLabTestIndex < labTestDetails.length - 1) {
        setCurrentLabTestIndex(currentLabTestIndex + 1);
      }
    } else if (currentIndex === 4) {
      if (currentFingerDabIndex < fingureDabDetails.length - 1) {
        setCurrentFingerDabIndex(currentFingerDabIndex + 1);
      }
    } else if (currentIndex === 5) {
      if (currentSamplingIndex < plateSamplingDetails.length - 1) {
        setCurrentSamplingIndex(currentSamplingIndex + 1);
      }
    } else if (currentIndex === 6) {
      if (currentRawmaterialTestIndex < rawMaterialDetails.length - 1) {
        setCurrentRawmaterialTestIndex(currentRawmaterialTestIndex + 1);
      }
    } else if (currentIndex === 7) {
      if (currentSurfaceMonitoringIndex < surfaceMonitoringDetails.length - 1) {
        setCurrentSurfaceMonitoringIndex(currentSurfaceMonitoringIndex + 1);
      }
    }
  };

  const handlePreviousClick = (currentIndex : number) => {
    if (currentIndex === 1){
      if (currentExposureIndex > 0) {
        setCurrentExposureIndex(currentExposureIndex - 1);
      }
    } else if (currentIndex === 2) {
      if (currentIncubationIndex > 0) {
        setCurrentIncubationIndex(currentIncubationIndex - 1);
      }
    } else if (currentIndex === 3) {
      if (currentLabTestIndex > 0) {
        setCurrentLabTestIndex(currentLabTestIndex - 1);
      }
    } else if (currentIndex === 4) {
      if (currentFingerDabIndex > 0) {
        setCurrentFingerDabIndex(currentFingerDabIndex - 1);
      }
    } else if (currentIndex === 5) {
      if (currentSamplingIndex > 0) {
        setCurrentSamplingIndex(currentSamplingIndex - 1);
      }
    } else if (currentIndex === 6) {
      if (currentRawmaterialTestIndex > 0) {
        setCurrentRawmaterialTestIndex(currentRawmaterialTestIndex - 1);
      }
    } else if (currentIndex === 7) {
      if (currentSurfaceMonitoringIndex > 0) {
        setCurrentSurfaceMonitoringIndex(currentSurfaceMonitoringIndex - 1);
      }
    }
  };

  const handleBoxClick = (value : number) => {
    setSelectedTab(value)
  };
  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Lab Report" />
      <div className="container-lab">      
              <div className="box-display" style={{ backgroundColor: selectedTab === 1 ? '#e0e0e0' : '' }} onClick={() => handleBoxClick(1)}>
                    <h3 className='lab-dash-head'>Pending Plates</h3>
                    <p className='lab-dash-value' style={{color : "#CBB613"}} >{labtestStat?.pending_plates_for_lab_approval}</p>
              </div>
              <div className="box-display" style={{ backgroundColor: selectedTab === 2 ? '#e0e0e0' : '' }} onClick={() => handleBoxClick(2)}>
                    <h3 className='lab-dash-head'>Approved Plates</h3>
                    <p className='lab-dash-value'style={{color : "#148E04"}} >{labtestStat?.lab_approved_plates}</p>
              </div>
              <div className="box-display" style={{ backgroundColor: selectedTab === 3 ? '#e0e0e0' : '' }} onClick={() => handleBoxClick(3)}>
                    <h3 className='lab-dash-head'>Pending Escalation</h3>
                    <p className='lab-dash-value' style={{color : "#36405D"}} >{labtestStat?.escalations}</p>
              </div>  
      </div>
       
      <div className='exceptionHandlingTab'>
        <button
          onClick={() => setTab("1")}
          className={tab === "1" ? "selectedNavBtn" : "navBtn"}
        >
          Requests
        </button>
        <button
          onClick={() => setTab("2")}
          className={tab === "2" ? "selectedNavBtn" : "navBtn"}
        >
          History
        </button>
      </div>
      

      {/* lab report request */}
      {tab === "1" && (
        <div>    
          <div className=" mx-3 pe-2">
            <CustomTable
              data={plateDetails}
              columns={columns}
              isEditable={false}
              totalElements={totalElements}
              isActionVisible={true}
              isViewVisible={true}
              onViewClick={handleViewClick}
              onFilterClick={handleFilter}
              approveButton={true}
              approveButtonText="Approve"
              rejectButton={true}
              rejectButtonText="Reject"
              tableHead="Approvals"
              isSeachable={true}
              plateBatch={plateBatches}
              plateSerialNumber={plateSerielNum}
              onStartClick={handleApproveBtn}
              onEndClick={handleRejectBtn}
            />
          </div>
          <div>
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>


          <AlertModal
            show={showApproveAlert}
            onHide={() => setShowApproveAlert(false)}
            title='Alert'
            message={`Approve this plate?`}
            onConfirm={() => changeLabReportStatus(labTestDetails[0]?.id, "22")}
          />

          <AlertModal
            show={showRejectAlert}
            onHide={() => setShowRejectAlert(false)}
            title='Alert'
            message={`Reject this plate?`}
            onConfirm={() => changeLabReportStatus(labTestDetails[0]?.id, "23")}
          /> 

          <RequestViewModel 
            showView={showView}
            singlePlateDetails={singlePlateDetails}
            currentExposureIndex={currentExposureIndex}
            currentFingerDabIndex={currentFingerDabIndex}
            currentSamplingIndex={currentSamplingIndex}
            currentRawmaterialTestIndex={currentRawmaterialTestIndex}
            currentIncubationIndex={currentIncubationIndex}
            currentLabTestIndex={currentLabTestIndex}
            currentSurfaceMonitoringIndex={currentSurfaceMonitoringIndex}
            exposureDetails={exposureDetails}
            fingureDabDetails={fingureDabDetails}
            plateSamplingDetails={plateSamplingDetails}
            rawMaterialDetails={rawMaterialDetails}
            incubationDetails={incubationDetails}
            labTestDetails={labTestDetails}
            surfaceMonitoringDetails={surfaceMonitoringDetails}
            showApproveRejectBtn={true}
            handleClose={handleClose}
            handlePreviousClick={handlePreviousClick}
            handleNextClick={handleNextClick}
            setShowApproveAlert={setShowApproveAlert}
            setShowRejectAlert={setShowRejectAlert}
          />
        </div>
      )}

      {/* lab report history */}
      {tab === "2" && (
        <div>
          <div className=" mx-3 pe-2">
            <CustomTable
              data={plateDetailsHistory}
              columns={columns}
              isEditable={false}
              totalElements={historyTotalElements}
              isActionVisible={false}
              isViewVisible={true}
              onViewClick={handleHistoryView}
              tableHead="Lab Report"
              onFilterClick={handleHistoryFilter}
              isSeachable={true}
              plateBatch={plateBatches}
              plateSerialNumber={plateSerielNum}
            />
          </div>
          <div>
            <Pagination
              page={historyPage}
              totalPages={historyTotalPages}
              handlePageChange={handleHistoryPageChange}
            />
          </div>
        <RequestViewModel 
            showView={historyView}
            singlePlateDetails={singlePlateDetails}
            currentExposureIndex={currentExposureIndex}
            currentFingerDabIndex={currentFingerDabIndex}
            currentSamplingIndex={currentSamplingIndex}
            currentRawmaterialTestIndex={currentRawmaterialTestIndex}
            currentIncubationIndex={currentIncubationIndex}
            currentLabTestIndex={currentLabTestIndex}
            currentSurfaceMonitoringIndex={currentSurfaceMonitoringIndex}
            exposureDetails={exposureDetails}
            fingureDabDetails={fingureDabDetails}
            incubationDetails={incubationDetails}
            plateSamplingDetails={plateSamplingDetails}
            rawMaterialDetails={rawMaterialDetails}
            labTestDetails={labTestDetails}
            surfaceMonitoringDetails={surfaceMonitoringDetails}
            showApproveRejectBtn={false}
            handleClose={handleHistoryClose}
            handlePreviousClick={handlePreviousClick}
            handleNextClick={handleNextClick}
            setShowApproveAlert={setShowApproveAlert}
            setShowRejectAlert={setShowRejectAlert}
          />
      </div>
      )}
    </div>
  );
};


export default LabReport;



