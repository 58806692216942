import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );

const createPolicy = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/policies/create`, 
       dataToSend,
       {
         headers: {
           'x-auth-token': authToken,
           'x-userid': authUserID
         }
       }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

const getPolicyId = async (Id:string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/policies/details?id=${Id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const getPolicyList = async (id: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/policies/details?id=${id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const createSchedule = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/schedules/add_schedule`, 
       dataToSend,
       {
         headers: {
           'x-auth-token': authToken,
           'x-userid': authUserID
         }
       }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const getScheduleId = async (Id:string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/schedules/get_details_by_id?id=${Id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  





  const policyService = {
    createPolicy,
    getPolicyId,
    getPolicyList,
    createSchedule,
    getScheduleId,
  };
  
  export { policyService };