import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { workflow } from "../../../../service/workflow"; 
interface AttributeValue {
  master_process_atributes_mapping_id: string;
  value: string;
  default_value: string | null;
  name: string;
  uom: string | null;
  field_type: string;
  label_name: string;
}

interface ProcessDetail {
  id: string;
  master_process_id: string;
  activity_workflow_definition_id: string;
  sequence: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  process_name: string;
  process_description: string;
  attribute_values: AttributeValue[];
  equipments: any[]; // Assuming this is an array of equipment details
}

interface Workflow {
  id: string;
  name: string;
  description: string;
  master_activity_id: string;
  status: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  activity_name: string;
  process_details: ProcessDetail[];
}

interface WorkflowModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  workflowId: string; // Updated prop to pass workflow ID from parent component
}

const WorkflowModal: React.FC<WorkflowModalProps> = ({
  isOpen,
  onRequestClose,
  workflowId,
}) => {
  const [workflowData, setWorkflowData] = useState<Workflow | null>(null);

  useEffect(() => {
    const fetchWorkflow = async () => {
      if (workflowId) {
        try {
          const response = await workflow.EditById(workflowId); // Assuming EditById fetches workflow by ID
          if (response.status && response.statusCode === 200) {
            setWorkflowData(response.data);
          } else {
            console.error("Failed to fetch workflow:", response.message);
            setWorkflowData(null); // Reset workflowData on failure
          }
        } catch (error) {
          console.error("Error fetching workflow:", error);
          setWorkflowData(null); // Reset workflowData on error
        }
      }
    };

    if (isOpen && workflowId) {
      fetchWorkflow();
    }
  }, [isOpen, workflowId]);

  const calculateWidth = (text: any) => {
    const minWidth = 100; // Minimum width for the rectangle
    const textLength = text.length;
    const estimatedWidth = textLength * 10; // Adjust based on your font and sizing
    return Math.max(minWidth, estimatedWidth); // Ensure width is at least minWidth
  };

  return (
    <Modal show={isOpen} contentLabel="Workflow Modal">
      <Modal.Header closeButton onClick={() => onRequestClose()}>
        <Modal.Title>{workflowData?.name}</Modal.Title>
      </Modal.Header>
     

      <Modal.Body style={{ height: "60vh", overflowY: "auto" }}>
  {workflowData ? (
    <div className="workflow-container1">
      <div className="workflow-node start-node">
        <div className="node-content">
          <div className="label-value">
            <strong>Name: </strong>&nbsp; <span style={{ whiteSpace: 'nowrap' }}>{workflowData.name}</span>
          </div>
          <div className="label-value">
            <strong>Activity Name: </strong>&nbsp; <span style={{ whiteSpace: 'nowrap' }}>{workflowData.activity_name}</span>
          </div>
        </div>
      </div>
      {workflowData.process_details.map((process, index) => (
        <React.Fragment key={process.id}>
          <div className="workflow-node process-node">
            <div className="node-content">{process.process_name}</div>
          </div>
          {index < workflowData.process_details.length - 1 && (
            <div className="workflow-arrow"></div>
          )}
        </React.Fragment>
      ))}
    </div>
  ) : (
    <div>Loading...</div> // Optional: Add a loading indicator
  )}
</Modal.Body>


      <Modal.Footer>
        <button onClick={onRequestClose} className="btn btn-secondary">
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default WorkflowModal;
