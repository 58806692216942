import React, { useEffect, useState } from "react";
import Signin from "./Signin";
import PortalScreen from "./PortalScreen";
import UpdatePassword from "./UpdatePassword";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

const SplashScreen = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");
    if (storedData) {
      setIsLoggedIn(true);
    }
  }, []);

  if (isLoggedIn) {
    return <PortalScreen />;
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/updatePassword" element={<UpdatePassword />} />
          <Route path="/" element={<Signin />} />
          {/* <Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
      </BrowserRouter>
    );
  }
};

export default SplashScreen;
