export const columns: TableColumn[] = [
    {
        Header: 'Sl no',
        accessor: 'slno',
      },
      {
        Header: 'Batch Code',
        accessor: 'batch_code',
      },
      {
        Header: 'Batch GRN',
        accessor: 'batch_grn',
      },
      {
        Header: 'Manufacturing Date',
        accessor: 'manufacturing_date',
      },
      {
        Header: 'Lot Count',    
        accessor: 'lot_count',
      },
      {
        Header: 'Plate Type',    
        accessor: 'plateType',
      },
      {
        Header: 'Quantity',    
        accessor: 'quantity',
      },
      {
        Header: 'Status',    
        accessor: 'status',
      },
]

export const Lotcolumns: TableColumn[] = [
  {
      Header: 'Sl no',
      accessor: 'slno',
    },
    {
      Header: 'Lot Code',
      accessor: 'lot_code',
    },
    {
      Header: 'Lot Count',    
      accessor: 'plate_count',
    },
    {
      Header: 'Status',    
      accessor: 'inspection_status',
    },
]


export const Platecolumns: TableColumn[] = [
  {
      Header: 'Sl no',
      accessor: 'slno',
    },
    {
      Header: 'Plate Serial No',
      accessor: 'serial_number',
    },
    // {
    //   Header: 'Activity',
    //   accessor: 'activity',
    // },
    // {
    //   Header: 'Location',
    //   accessor: 'location',
    // },
    {
      Header: 'Start Date',    
      accessor: 'manufacturing_date',
    },
    {
      Header: 'End Date',    
      accessor: 'expiry_date',
    },
    // {
    //   Header: 'Started By',    
    //   accessor: 'started_by',
    // },
    // {
    //   Header: 'Completed By',    
    //   accessor: 'completed_by',
    // },
    // {
    //   Header: 'Status',    
    //   accessor: 'status',
    // },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }
