import React, { useEffect, useState } from "react";
import moment from "moment";
import CustomTable from "../../../../widgets/table/index";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { columns } from "./partials/columns";
import { scheduleService } from "../../../../service/scheduleService";
import Pagination from "../../../../widgets/pagination";
import { Modal } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import AlertModal from "../../../../widgets/alertModal";
import CustomToast from "../../../../widgets/toaster";
import { userService } from "../../../../service/userService";
import { useTranslation } from "react-i18next";
import { gradeTypes } from "../../../../service/gradeType";
import Select from "react-select";
import { helperFunctions as _h } from "../../../../utils/helper";

const ScheduleOperator = () => {
  const { t } = useTranslation();
  const defaultFormData = {
    id: "",
    activity_type_id: "",
    reason: "",
    status: "",
    created_at: "",
    updated_at: "",
    created_by: "",
    updated_by: "",
    activity_type_name: "",
    schedule_production_id: "",
    start_date_time: "",
    end_date_time: "",
    process_id: "",
    user_master_id: "",
    production_schedule_name: "",
    product_name: "",
    production_type: "",
    user_first_name: "",
    user_last_name: "",
    operator_name: "",
    process_name: "",
  };
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false);

  // table pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [keyWord, setkeyWord] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddAreaAlert, setShowAddAreaAlert] = useState(false);
  const [statusChangeAlert, setStatusChangeAlert] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(defaultFormData);

  //toaster section
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [userId, setUserId] = useState<number>(0);

  //handle error section
  interface Errors {
    activity_type_id?: string;
    reason?: string;
    status?: string;
    schedule_production_id?: string;
    start_date_time?: string;
    end_date_time?: string;
    process_id?: string;
    user_master_id?: string;
  }
  const [masterFormData, setMasterFormData] = useState(defaultFormData);
  const [modalName, setModalName] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [permission, setPermission] = useState<any>([]);
  const [categoryListData, setCategoryListData] = useState([]);
  const [grade, setGrade] = useState<Array<any>>([]);
  const [activityType, setActivityType] = useState<Array<any>>([]);
  const [prodScheduleList, setProdScheduleList] = useState<Array<any>>([]);
  const [processList, setProcessList] = useState<Array<any>>([]);
  const [usersList, setUsersList] = useState<Array<any>>([]);

  //handle user details for submit handling
  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");
    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      getPermissionByRole(storedObject[0].role_master_id);
    }
    getProductionSchedules();
    getActivityTypeList();
    getMasterProcessList("");
    getMasterUserList();
    getOperatorScheduleList();
    setUseEffectRequired(false);
  }, [useEffectRequired]);

  //get permission by role
  const getPermissionByRole = async (role: number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if (response.status && response.statusCode === 200) {
        setPermission(response.data.permissions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //handle onchange input fields
  const handleFieldOnChange = async (e: any) => {
    const { name, value } = e.target;
    console.log("handleFieldOnChange=>{ name, value }", { name, value });
    if (name == "schedule_production_id") {
      let selectedProd = prodScheduleList.filter((prod) => {
        return prod.id == value;
      });
      console.log("handleFieldOnChange=>selectedProd", selectedProd);
      if (_h.arrayHasData(selectedProd) && _h.objectHasValue(selectedProd[0])) {
        let activityProcess: any = await getMasterProcessList(
          selectedProd[0]?.activity_type_id
        );
        console.log("handleFieldOnChange=>activityProcess", activityProcess);
        setProcessList(activityProcess);
        setMasterFormData((prevFormData: any) => ({
          ...prevFormData,
          activity_type_id: selectedProd[0]?.activity_type_id,
        }));
      }
    }
    if (name == "start_date_time" && _h.isNotEmpty(value)) {
      setMasterFormData((prevFormData: any) => ({
        ...prevFormData,
        start_date_time: moment(value, "YYYY-MM-DDTHH:mm").format(
          "DD-MM-YYYY - HH:mm:ss"
        ),
      }));
    } else if (name == "end_date_time" && _h.isNotEmpty(value)) {
      setMasterFormData((prevFormData: any) => ({
        ...prevFormData,
        end_date_time: moment(value, "YYYY-MM-DDTHH:mm").format(
          "DD-MM-YYYY - HH:mm:ss"
        ),
      }));
    } else {
      setMasterFormData((prevFormData: any) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    console.log("handleFieldOnChange=>masterFormData", masterFormData);
  };

  //clear modal on close
  const handleFormClose = () => {
    setShowAddModal(false);
    setErrors({});
    setMasterFormData(defaultFormData);
  };

  //handle area validation starts here
  const handleFormValidation = () => {
    const errors: Errors = {};
    if (!masterFormData.activity_type_id) {
      errors.activity_type_id = "Please select an activity type";
    }
    if (!masterFormData.schedule_production_id) {
      errors.schedule_production_id = "Please select an activity type";
    }
    if (!masterFormData.start_date_time) {
      errors.start_date_time = "Please select an activity type";
    }
    if (!masterFormData.end_date_time) {
      errors.end_date_time = "Please select an activity type";
    }
    if (!masterFormData.process_id) {
      errors.process_id = "Please select an activity type";
    }
    if (!masterFormData.user_master_id) {
      errors.user_master_id = "Please select an activity type";
    }
    if (
      _h.isNotEmpty(masterFormData.start_date_time) &&
      _h.isNotEmpty(masterFormData.end_date_time)
    ) {
      let fromEpoch = new Date(masterFormData.start_date_time).valueOf();
      let toEpoch = new Date(masterFormData.end_date_time).valueOf();
      if (fromEpoch >= toEpoch) {
        errors.start_date_time = "Start date should be less than End date";
        errors.end_date_time = "End date should be greater than Start date";
      }
    }

    if (masterFormData.id && !masterFormData.reason) {
      errors.reason = "Please enter reason for edit";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setShowAddAreaAlert(true);
    setErrors({});
  };

  //handle add area submit api call
  const handleFormSubmit = async () => {
    const dataToSend = {
      id: masterFormData.id,
      activity_type_id: masterFormData.activity_type_id,
      interface_name: "Schedule Operator",
      reason: masterFormData.reason,
      schedule_production_id: masterFormData.schedule_production_id,
      start_date_time: _h.isNotEmpty(masterFormData.start_date_time)
        ? moment(
            masterFormData.start_date_time,
            "DD-MM-YYYY - HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss")
        : "",
      end_date_time: _h.isNotEmpty(masterFormData.end_date_time)
        ? moment(masterFormData.end_date_time, "DD-MM-YYYY - HH:mm:ss").format(
            "YYYY-MM-DD HH:mm:ss"
          )
        : "",
      process_id: masterFormData.process_id,
      user_master_id: masterFormData.user_master_id,
    };

    try {
      const response = await scheduleService.createScheduleOperator(dataToSend);
      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        getOperatorScheduleList();
        handleFormClose();
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowAddAreaAlert(false);
      setShowToast(true);
    } catch (error) {
      console.error(error);
    }
  };

  //table filter section
  const handleFilter = (key: string, value: any) => {
    if (key === "search") {
      setkeyWord(value);
    }
    setUseEffectRequired(true);
  };

  // function to sort table
  const handleSort = (key: string, value: boolean) => {
    setSort(key);
    if (value) {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
    setUseEffectRequired(true);
  };

  //handle table pagination
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  //open add area modal
  const handleAddClick = () => {
    setShowAddModal(true);
    setModalName("Create Operator Schedule");
  };

  const handleStatusChange = (id: string, status: string) => {
    setSelectedId(id);
    if (status === "active") {
      setUpdatedStatus("inactive");
    } else {
      setUpdatedStatus("active");
    }
    setStatusChangeAlert(true);
  };

  //change area status api call
  const handleStatusChangeSubmit = async () => {
    try {
      const response = await scheduleService.changeFrequencyScheduleStatus(
        selectedId,
        updatedStatus,
        "Schedule Operator"
      );

      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowToast(true);
      setUseEffectRequired(true);
      setUpdatedStatus("");
      setSelectedId("");
      setStatusChangeAlert(false);
    } catch (error) {
      console.error(error);
    }
  };

  //filter selected id based on the row selected while editing
  const handleEditClick = (id: string) => {
    const row = tableData.filter((item) => item.slno === id);
    if (_h.arrayHasData(row) && _h.objectHasValue(row[0])) {
      console.log("handleEditClick=>row[0]", row[0]);
      setMasterFormData(row[0]);
      setShowAddModal(true);
      setModalName("Edit Operator Schedule");
    }
  };

  const handleViewClick = (id: string, viewValue: boolean) => {
    const row = tableData.filter((item) => item.slno === id);
    if (_h.arrayHasData(row) && _h.objectHasValue(row[0])) {
      console.log("handleEditClick=>row[0]", row[0]);
      setSelectedRow(row[0]);
      setShowViewModal(true);
    }
  };

  // get table data
  const getOperatorScheduleList = async () => {
    try {
      const response = await scheduleService.getOperatorScheduleList(
        page,
        pageSize,
        sort,
        order,
        keyWord,
        ""
      );
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  status: element.status,
                  activity_type_id: element.activity_type_id,
                  created_at: element?.created_at
                    ? moment(element?.created_at, "YYYY-MM-DD HH:mm:ss").format(
                        "DD-MM-YYYY - HH:mm:ss"
                      )
                    : "-",
                  updated_at: element?.updated_at
                    ? moment(element?.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                        "DD-MM-YYYY - HH:mm:ss"
                      )
                    : "-",
                  schedule_production_id: element?.schedule_production_id,
                  start_date_time: element?.start_date_time
                    ? moment(
                        element?.start_date_time,
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("DD-MM-YYYY - HH:mm:ss")
                    : "-",
                  end_date_time: element?.end_date_time
                    ? moment(
                        element?.end_date_time,
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("DD-MM-YYYY - HH:mm:ss")
                    : "-",
                  process_id: element?.process_id,
                  user_master_id: element?.user_master_id,
                  created_by: element?.created_by,
                  updated_by: element?.updated_by,
                  production_schedule_name: element?.production_schedule_name,
                  product_name: element?.product_name,
                  production_type: element?.production_type,
                  user_first_name: element?.user_first_name,
                  user_last_name: element?.user_last_name,
                  operator_name: (
                    (element?.user_first_name).trim() +
                    " " +
                    (element?.user_last_name).trim()
                  ).trim(),
                  process_name: element?.process_name,
                  activity_type_name: element?.activity_type_name,
                };
              }
            );
            setTableData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  // get ProductionScheduleList
  const getProductionSchedules = async () => {
    try {
      const response = await scheduleService.getProductionSchedules();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  production_schedule_name: element?.production_schedule_name,
                  activity_type_id: element.activity_type_id,
                };
              }
            );
            setProdScheduleList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setProdScheduleList([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  // get Activity Type List
  const getActivityTypeList = async () => {
    try {
      const response = await scheduleService.getActivityTypeList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  name: element.name,
                };
              }
            );
            setActivityType(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setActivityType([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  // get MasterProcessList
  const getMasterProcessList = async (activity_type_id: string) => {
    console.log("handleFieldOnChange=>getMasterProcessList", activity_type_id);
    let rowData: any = [];
    try {
      if (
        _h.isNotEmpty(masterFormData.activity_type_id) ||
        _h.isNotEmpty(activity_type_id)
      ) {
        const response = await scheduleService.get_process_by_activity(
          _h.isNotEmpty(activity_type_id)
            ? activity_type_id
            : masterFormData.activity_type_id
        );
        console.log("getMasterProcessList=>response", response);
        if (response.status && response.statusCode === 200) {
          try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
              rowData = response.data.map((element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  name: element.name,
                };
              });
              setProcessList(rowData);
            }
          } catch (error) {
            console.log(error);
            setProcessList([]);
          }
        } else {
          setProcessList([]);
        }
      } else {
        setProcessList([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
    return rowData;
  };

  // getMasterUserList();
  const getMasterUserList = async () => {
    try {
      const response = await scheduleService.getMasterUserList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  name: (
                    (element?.first_name).trim() +
                    " " +
                    (element?.last_name).trim()
                  ).trim(),
                };
              }
            );
            setUsersList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setUsersList([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t("scheduleOperator.pageTitle")} />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      <div className="me-3" style={{ marginTop: "10px" }}>
        <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
          <CustomTable
            tableHead={t("scheduleOperator.tableHead")}
            data={tableData}
            columns={columns}
            isEditable={permission?.includes("edit_operator_schedule")}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={true}
            onViewClick={handleViewClick}
            isSeachable={true}
            addButton={permission?.includes("add_operator_schedule")}
            addButtonText={t("scheduleOperator.add")}
            onFilterClick={handleFilter}
            onSortClick={handleSort}
            onAddClick={handleAddClick}
            isToggleStatus={true}
            onStatusChange={handleStatusChange}
            isQrDownloadable={false}
            onEditClick={handleEditClick}
            showQrGenerateButton={false}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>

      {/* Alert modal for Add/edit Operator schedule */}
      <AlertModal
        show={showAddAreaAlert}
        onHide={() => setShowAddAreaAlert(false)}
        title="Alert"
        message={modalName}
        onConfirm={handleFormSubmit}
      />

      {/* Alert modal for change Operator schedule status */}
      <AlertModal
        show={statusChangeAlert}
        onHide={() => setStatusChangeAlert(false)}
        title="Alert"
        message={`Change Operator Schedule Status?`}
        onConfirm={handleStatusChangeSubmit}
      />

      {/*modal for Add/edit Operator schedule */}
      <Modal
        className="top-right-modal-md"
        centered={false}
        show={showAddModal}
        // size='lg'
      >
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleFormClose()}
              className="p-2 mx-3 my-2 "
            >
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>

          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.prodScheduleList")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="schedule_production_id"
                value={masterFormData?.schedule_production_id}
                name="schedule_production_id"
                onChange={handleFieldOnChange}
              >
                <option value="">select</option>
                {prodScheduleList.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.production_schedule_name}
                  </option>
                ))}
              </select>
              {errors.schedule_production_id && (
                <p className="errorText">{errors.schedule_production_id}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.start_date_time")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="datetime-local"
                name="start_date_time"
                id="start_date_time"
                value={
                  _h.isNotEmpty(masterFormData.start_date_time)
                    ? moment(
                        masterFormData.start_date_time,
                        "DD-MM-YYYY - HH:mm:ss"
                      ).format("YYYY-MM-DDTHH:mm")
                    : ""
                }
                onChange={handleFieldOnChange}
                className="filter-input"
              />
              {errors.start_date_time && (
                <p className="errorText">{errors.start_date_time}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.end_date_time")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="datetime-local"
                name="end_date_time"
                id="end_date_time"
                value={
                  _h.isNotEmpty(masterFormData.end_date_time)
                    ? moment(
                        masterFormData.end_date_time,
                        "DD-MM-YYYY - HH:mm:ss"
                      ).format("YYYY-MM-DDTHH:mm")
                    : ""
                }
                onChange={handleFieldOnChange}
                className="filter-input"
              />
              {errors.end_date_time && (
                <p className="errorText">{errors.end_date_time}</p>
              )}
            </div>
          </div>
          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.activityType")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="activity_type_id"
                value={masterFormData?.activity_type_id}
                name="activity_type_id"
                // onChange={handleFieldOnChange}
                disabled={true}
              >
                <option value="">select</option>
                {activityType.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.activity_type_id && (
                <p className="errorText">{errors.activity_type_id}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.process")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="process_id"
                value={masterFormData?.process_id}
                name="process_id"
                onChange={handleFieldOnChange}
              >
                <option value="">select</option>
                {_h.arrayHasData(processList) &&
                  processList.map((val) => (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  ))}
              </select>
              {errors.process_id && (
                <p className="errorText">{errors.process_id}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.users")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="user_master_id"
                value={masterFormData?.user_master_id}
                name="user_master_id"
                onChange={handleFieldOnChange}
              >
                <option value="">select</option>
                {usersList.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.user_master_id && (
                <p className="errorText">{errors.user_master_id}</p>
              )}
            </div>
          </div>
          <div className="selectWrap-new">
            {masterFormData.id && (
              <div className="form-group my-1 mx-3 px-2">
                <label htmlFor="" className="ReasonLabel">
                  {t("userManagement.reason")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="reason"
                  value={masterFormData.reason}
                  name="reason"
                  type="text"
                  placeholder={t("userManagement.reason")}
                  className="reasonClass"
                  onChange={handleFieldOnChange}
                />
                {errors.reason && <p className="errorText">{errors.reason}</p>}
              </div>
            )}
          </div>
          <div className="bottomArea">
            <button
              onClick={() => handleFormClose()}
              className="modalCancelBtn"
            >
              {t("buttons.cancel")}
            </button>
            <button
              onClick={() => handleFormValidation()}
              className="squareOrangeBtn3"
            >
              {t("buttons.submit")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        show={showViewModal}
        className="modal-view-audit"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton onClick={() => setShowViewModal(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("scheduleOperator.view")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ textAlign: "left" }}>
            {t("scheduleOperator.scheduleOperatorDetails")}
          </h4>
          {_h.objectHasValue(selectedRow) && (
            <>
              <div
                className="key-value-pairs"
                style={{
                  display: "flex",
                  padding: "10px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t(
                      "scheduleOperator.tableColumns.production_schedule_name"
                    )}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.production_schedule_name}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleOperator.tableColumns.product_name")}
                  </div>
                  <div className="audit-value">{selectedRow?.product_name}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleOperator.tableColumns.production_type")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.production_type}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleOperator.tableColumns.start_date_time")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.start_date_time}
                  </div>
                </div>
              </div>
              <div
                className="key-value-pairs"
                style={{
                  display: "flex",
                  padding: "10px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleOperator.tableColumns.end_date_time")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.end_date_time}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleOperator.tableColumns.activity_type_name")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.activity_type_name}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleOperator.tableColumns.process_name")}
                  </div>
                  <div className="audit-value">{selectedRow?.process_name}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleOperator.tableColumns.operator_name")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.operator_name}
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <button
            onClick={() => setShowViewModal(false)}
            className="custom-close-button"
          >
            {t("buttons.close")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ScheduleOperator;
