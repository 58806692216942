import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );



  const getIncidentList = async (page :number, size :number, sort :string, order : string, status: string, reasonId: any, keyword: any, deviationStatus: any) => {
    try {
      const headers = {
        'x-auth-token': authToken,
        'x-userid': authUserID,
      };
      const response = await axiosInstance.get(`${baseUrl}/deviations/list?page=${page}&size=${size}&sort=${sort}&order=${order}&location_type=${status}&reason_id=${reasonId}&keyword=${keyword}&status=${deviationStatus}`, { headers });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const getReasonList = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/deviation/list_reason?status=active,inactive`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const changeDeviationStatus = async (dataToSend : any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/deviations/change_status`, 
       dataToSend,
       {
         headers: {
           'x-auth-token': authToken,
           'x-userid': authUserID
         }
       }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const getAreaId = async (Id:string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/location/get_details_by_id?id=${Id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

    
  const SaveDeviation = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/deviations/add_deviation`, dataToSend ,{
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };
  const IncidentOrDeviationApi = {
    getIncidentList,
    getReasonList,
    changeDeviationStatus,
    getAreaId,
    SaveDeviation
  }

  export { IncidentOrDeviationApi };
