import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import { AiOutlineMenu } from "react-icons/ai";
import { toggleNavbar } from '../../../store/store';
import { useDispatch } from 'react-redux';
import { MdLiveHelp } from 'react-icons/md';
import { IoHelpSharp } from "react-icons/io5";


const HeaderSection = () => {

    const [currentDate, setCurrentDate] = useState(new Date());
    const formattedDate = <Moment format='DD MMM YYYY'>{currentDate}</Moment>;
    const formattedTime = <Moment format='HH:mm:ss'>{currentDate}</Moment>;
    const dispatch = useDispatch();


    const { t } = useTranslation();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000); // Update every 1 second
    
        return () => {
          clearInterval(intervalId); // Clean up the interval when the component unmounts
        };
    }, []);

    const handleNavbarClick = () => {
        dispatch(toggleNavbar())
    }

     const handleHelpClick = () => {
        window.open('/help.html', '_blank');
    };
    
    return (
        <div className='headerTab py-1'>
            <div className='d-flex ms-4'>
            <div className='pt-2'>
              <AiOutlineMenu onClick={() => handleNavbarClick()} fontSize={20} />
            </div>
            <div>
                <p className='mx-3 my-1 headerText'>{t('expandedName')}</p>
            </div>
            </div>
            <div className='d-flex pt-2 me-4'>
                <p className='helpIcon pe-3' onClick={handleHelpClick}>Help</p>
                <p className='headerDate pe-2 '>{formattedDate}</p>
                <p className='headerTime ms-2 '>{formattedTime}</p>
            </div>
        </div>
    )
}

export default HeaderSection