import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );


const listGradeType= async (page : number , pageSize : number) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/grade/list_activity_config?page=${page}&size=${pageSize}&sort=&order=desc&status=active,inactive&keyword=`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listGradeOptions= async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/grade/list?sort=&order=desc&status=active,inactive&keyword=`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


  const changeGradeStatus = async (id: string, status: string, interface_name : string) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/grade/change_status`, {
        id: id,
        status: status,
        interface_name: interface_name
      },
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const addGradeType = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/grade/add_activity_config`, 
       dataToSend,
       {
         headers: {
           'x-auth-token': authToken,
           'x-userid': authUserID
         }
       }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


  const getActivityList = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_all_activities?status=active`, {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


  const getUOMList = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_all_unit_of_measurement?status=active`, {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const getGradeById= async (id: any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/grade/list?sort=&order=desc&status=active,inactive&keyword=&id=${id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


  const gradeTypes = {
    listGradeType,
    changeGradeStatus,
    addGradeType,
    getActivityList,
    getUOMList,
    getGradeById,
    listGradeOptions
};
  
  
export { gradeTypes };