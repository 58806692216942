

//table columns for lab Report
export const columns: TableColumn[] = [
    {
        Header: "Sl.No",
        accessor: "slno",
    },
    {
        Header: "tableCommon.batch_code",
        accessor: "batch_id",
    },
    {
        Header: "tableCommon.seriel_number",
        accessor: "plate_serial_number",
    },
    {
        Header: "Colony Count",
        accessor: "colony_count",
    },
    {
        Header: "Plate Type",
        accessor: "plate_type",
    },
    {
        Header: "Lab Approved Date",
        accessor: "lab_approved_date",
    },
    {
        Header: "Lab Test Done By",
        accessor: "approved_by",
    },
    {
        Header: "Status",
        accessor: "status",
    },
  ];
  
  interface TableColumn {
    Header: string;
    accessor: string;
  }