import React, { useEffect, useState } from "react";
import { Link  } from "react-router-dom";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { dashboardServices } from "../../../../service/dashboard";
import translationData from '../../../../locales/dashboardTranslation.json'

interface ExposureDetailsLayoutProps {
  heading: string;
  selectedDate: any;
}

const columnStyle = {
  margin: "0 0 6px 0",
};

const lastColumnStyle = {
  margin: "0",
};

const ExposureDetailsLayout: React.FC<ExposureDetailsLayoutProps> = ({
  heading,
  selectedDate,
}) => {
  const navigate = useNavigate();

  interface ColumnData {
    id: number;
    apiName: string;
    displayName: string;
    count: number;
  }
  
  const [columnsData, setColumnsData] = useState<ColumnData[]>([]);
  const [scheduleDetails , setScheduleDetails] = useState<Array<any>>([]);
  const [isBlinking, setIsBlinking] = useState(true);
  const [highlightBlink, setHighlightBlink] = useState(false);
  const [currentColor, setCurrentColor] = useState('color1');
  const [nextScheduleTime, setNextScheduleTime] = useState("");


  const navigateToOtherPage = () => {
    navigate('/exposure-table'); 
  };
  
  //link to navigate inner tables 
  const switchToPage = (id: number) => {
    if(id == 1){
      navigate(`/exposure-table`);
    }else if (id >= 2 && id <= 5) {
      navigate(`/inner-view-exposure/${id}`);
    } else if (id === 7){
      navigate(`/missed-Schedule`)
    }else if (id === 6){
      navigate(`/upcoming-Schedule`)
    }
  }

 //get exposure stats to show details
  const getExposureStatus = async () => {
    try {
      const response = await dashboardServices.getExposureStatus(selectedDate);
      if (response.status && response.statusCode === 200) {
        try {
          const responseData = response.data;
          if (responseData) {
            setColumnsData(prevColumnsData => {
              return prevColumnsData.map(column => {
                const { apiName, displayName } = column;

                const apiCount = responseData[apiName];

                if (apiCount !== undefined) {
                  return { ...column, count: apiCount };
                } else {
                  console.warn(`API response for ${displayName} is undefined or missing.`);
                  return column;
                }
              });
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error('user response', error);
    }
  };

 //set blinking based on the current time and schedule api time 
  const getCurrentTime = () => new Date();
  const isWithin10Minutes = (startTime: Date) => {
    if (!(startTime instanceof Date) || isNaN(startTime.getTime())) {
      console.error('Invalid start time');
      return false;
    }
  
    const currentTime = getCurrentTime();
    const timeDifference = startTime.getTime() - currentTime.getTime();
    return timeDifference <= 5 * 60 * 1000 && timeDifference > 0;
  };
  
  // blinking functions
  const startBlinking = () => {
    const blinkInterval = setInterval(() => {
      setIsBlinking((prevIsBlinking) => !prevIsBlinking);
      setCurrentColor((prevColor) => (prevColor === 'color1' ? 'color2' : 'color1'));
    }, 1000); // Toggle every second
  
    // Clear interval when blinking is no longer needed
    setTimeout(() => {
      clearInterval(blinkInterval);
      setIsBlinking(false); 
      setHighlightBlink(false);
    }, 5 * 60 * 1000); // Stop blinking after 5 minutes
  };
  
  //api call to get schedule details 
  const getUpcomingScheduleDetails = async () => {
    try {
      const response = await dashboardServices.getScheduleDetails();
      if (Array.isArray(response.data) && response.data?.length > 0) {
        let hasUpcomingSchedules = false;
  
        const rowData = response.data.map((element: any, index: number) => {
          const startTimeString = element.start_time;
          const [hours, minutes, seconds] = startTimeString.split(':');
          const startTime = new Date();
          startTime.setHours(parseInt(hours, 10));
          startTime.setMinutes(parseInt(minutes, 10));
          startTime.setSeconds(parseInt(seconds, 10));
          const isWithin10MinutesValue = isWithin10Minutes(startTime);
          
          if (isWithin10MinutesValue) {
            hasUpcomingSchedules = true;
            setHighlightBlink(true)
          }
  
          return {
            slno: index + 1,
            start_time: startTimeString,
            end_time: element.end_time,
          };
        });
        setScheduleDetails(rowData);
  
        // Start blinking if there are upcoming schedules within 10 minutes
        if (hasUpcomingSchedules) {
          startBlinking();
          
        }
      }
    } catch (error) {
      console.error('exposure response', error);
    }
  };

  useEffect(() => {
    const exposureDataHead = translationData.ExposureDataHead;
    setColumnsData(exposureDataHead.map((item => ({ ...item, count: 0 }))));
    }, []); 

  useEffect(() => {
    getExposureStatus();
    getUpcomingScheduleDetails();
  }, [])

  useEffect(() => {
    const findNearestStartTime = () => {
      const currentTime : any = new Date();
      const nearestStartTime = scheduleDetails.reduce((nearest, item) => {
        const itemStartTime : any = new Date();
        const [hours, minutes, seconds] = item.start_time.split(":");
        itemStartTime.setHours(parseInt(hours, 10));
        itemStartTime.setMinutes(parseInt(minutes, 10));
        itemStartTime.setSeconds(parseInt(seconds, 10));

        const timeDifference = itemStartTime - currentTime;

        if (
          timeDifference > 0 &&
          (!nearest || timeDifference < nearest.timeDifference)
        ) {
          return { time: item.start_time, timeDifference };
        }

        return nearest;
      }, null);

      if (nearestStartTime) {
        setNextScheduleTime(nearestStartTime.time);

        // Compare the difference with 10 minutes
        if (nearestStartTime.timeDifference <= 10 * 60 * 1000) {
          setHighlightBlink(true)
        } else {
          setHighlightBlink(false)
        }
      }
    };

    findNearestStartTime();

    // Check for the nearest start time every minute
    const intervalId = setInterval(findNearestStartTime, 60000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [scheduleDetails]);


  //customise each field values 
  const getColumnNameStyle = (column: any) => {
    if (column.displayName.includes('Overdue plates for Collection') || column.displayName.includes('Missed Schedules')) {
      return 'column-style redText';
    } else if (column.displayName === 'Plates Ready For Collection') {
      return 'column-style yellowText';
    } else if (column.displayName === 'Upcoming Schedules' && highlightBlink) {
      return `highlight ${highlightBlink ? 'blink' : ''} ${currentColor} column-style yellowText`;
    } else if (column.displayName === 'Upcoming Schedules' && !highlightBlink) {
      return 'column-style';
    } else {
      return 'column-style';
    }
  };
return (

  <div className="container">
      <h2 className="head-style" title="Click here to View Exposure Details" onClick={navigateToOtherPage}>
        {heading}
      </h2>
      <div className="columns" style={{ padding: '10px' }}>
        {columnsData.map((column) => (
        <div key={column.id} className="column" style={column.id === columnsData.length ? lastColumnStyle : columnStyle}>
          <div className="linkStyle" onClick={() => switchToPage(column.id)}>
            <span className={getColumnNameStyle(column)}>
              {column.displayName}
              {column.displayName === 'Upcoming Schedules' && highlightBlink && (
                <span className={`highlight ${highlightBlink ? 'blink' : ''} ${currentColor} yellowText`}>
                  &nbsp; 
                </span>
              )}
            </span>
            <span className="countStyle">{column.count}</span>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default ExposureDetailsLayout;
