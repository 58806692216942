import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs';
import baseUrl from '../../../../service';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import ImageSlider from '../partials/imageSlider';
import { userService } from '../../../../service/plateServices';



interface LabReportViewModalProps {
    showView: boolean;
    setShowView: (value: boolean) => void;
    plateId: string;
}

const PlateDetailsViewModel: React.FC<LabReportViewModalProps> = ({
    showView,
    setShowView,
    plateId
}) => {

    type LabtestStatType = {
        pending_plates_for_lab_approval: number;
        lab_approved_plates: number;
        escalations: number;
      };

  const [tab, setTab] = useState("1");
//   const [showView, setShowView] = useState(true);
  const [historyView, setHistoryView] = useState(false);
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [plateDetailsHistory, setPlateDetailsHistory] = useState<Array<any>>([]);
  const [singlePlateDetails, setSinglePlateDetails] = useState<any>({});
  const [incubationDetails, setIncubationDetails] = useState<any>([]);
  const [exposureDetails, setExposureDetails] = useState<any>([]);
  const [fingureDabDetails, setFingureDabDetails] = useState<any>([]);
  const [plateSamplingDetails, setPlateSamplingDetails] = useState<any>([]);
  const [rawMaterialDetails, setRawMaterialDetails] = useState<any>([]);
  const [surfaceMonitoringDetails, setSurfaceMonitoringDetails] = useState<any>([]);

  const [labTestDetails, setLabTestDetails] = useState<any>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [plateBatches, setPlateBatches] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [keyWord, setkeyWord] = useState("");
  const [historyKeyWord, setHistoryKeyWord] = useState("");
  const [userId, setUserId] = useState<number>(0);
  const [showApproveAlert, setShowApproveAlert] = useState(false)
  const [showRejectAlert, setShowRejectAlert] = useState(false)
  const [currentExposureIndex, setCurrentExposureIndex] = useState(0);
  const [currentSamplingIndex, setCurrentSamplingIndex] = useState(0);
  const [currentRawmaterialTestIndex, setCurrentRawmaterialTestIndex] = useState(0);
  const [currentFingerDabIndex, setCurrentFingerDabIndex] = useState(0);
  const [currentIncubationIndex, setCurrentIncubationIndex] = useState(0);
  const [currentLabTestIndex, setCurrentLabTestIndex] = useState(0);
  const [currentSurfaceMonitoringIndex, setCurrentSurfaceMonitoringIndex] = useState(0);
  const [labtestStat, setLabTestStat] = useState<LabtestStatType | null>(null);
  const [selectedTab, setSelectedTab] = useState<number | null>(null);


  // pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  //history pagination
  const [historyPage, setHistoryPage] = useState(1);
  const [historyPageSize, setHistoryPageSize] = useState(5);
  const [historyTotalPages, setHistoryTotalPages] = useState(0);
  const [historyTotalElements, setHistoryTotalElements] = useState(0);

  const [showApproveRejectBtn , setShowApproveRejectBtn] = useState(false);

  const scanPlateById = async (id:any) => {
    const dataToSend = {
      "id" : id
    }
    const response = await userService.scanPlateById(dataToSend);
    if(response?.status && response?.statusCode === 200){
      console.log(response, "response--")


      const plateType = parseInt(response?.data.plate_type, 10); 
                    if (plateType == 1){
                      var plate_type = "Q A Plate"
                    }else if (plateType == 2){
                      var plate_type = "Exposure Plate"
                    }else if (plateType == 3){
                      var plate_type = "Finger DAB Plate"
                    }else if (plateType == 4){
                      var plate_type = "Sampling Plate"
                    }else if (plateType == 5){
                      var plate_type = "Material Test Plate"
                    }else if (plateType == 6){
                      var plate_type = "Surface Monitoring Plate"
                    }else if (plateType == 7){
                      var plate_type = "Surface Monitoring - SWAB Collection"
                    } else{
                      var plate_type = "-"
                    }

      const statusNumber = parseInt(response?.data.status, 10); 
                   if (statusNumber == 21){
                    var status = "pending"
                   }else{
                    var status = "unknown"
                   }

      const basicDetails = {
        "id": response.data.id,
        "serial_number": response.data.serial_number,
        "batch_number": response.data.batch_number,
        "plate_status": response.data.plate_status,
        "status": status,
        "expiry_date": response.data.expiry_date,
        "manufacturing_date": response.data.manufacturing_date,
        "current_location_id": response.data.current_location_id,
        "plate_type": plate_type,
      }

      let exposureDetails:any = response.data?.exposure_details?.map((element: any, index: number) => {
        return {
          exposedAreaName : element.exposure_area_name || "-",
          exposedBy : element.created_by_first_name + " " + element.created_by_last_name || "-",
          exposedDate : element.start_date_time,
          collectedBy : element.updated_by_first_name + " " + element.updated_by_last_name || "-",
          collectedDate : element.end_date_time || "-",
        }
      })

      let fingureDabDetails = response.data?.fingerdab_details?.map((element: any, index: number) => {
        return {
          fingerDabAreaName : element.location_name || "-",
          fingerDabbededBy : element.finger_dab_operator_name || "-",
          dabbedDate : element.completed_date_time || "-",
          fingerDabType: element.started_by_user_firstname + " " + element.started_by_user_lastname || "-",
          figerDabOption : element.finger_dab_option_name || "-",

        }
      })

      let plateSamplingDetails:any = response.data?.plate_sampling_details?.map((element: any, index: number) => {
        return {
          plateSamplingType : element.test_type_name || "-",
          sampledBy : element.started_by_user_firstname + " " + element.started_by_user_lastname || "-",
          sampledDate : element.start_date_time || "-",
        }
      })

      let rawMaterialDetails:any = response.data?.raw_material_test_details?.map((element: any, index: number) => {
        return {
          rawMaterialTypeName : element.material_type_name || "-",
          conductedBy : element.started_by_user_firstname + " " + element.started_by_user_lastname || "-",
          testDate : element.start_date_time || "-",
        }
      })

      let surfaceMonitoringDetails:any = response.data?.surface_monitoring_details?.map((element: any, index: number) => {
        return {
          surfaceMonitoringAreaName : element.location_name || "-",
          surfaceMonitoringStartDate : element.start_date_time || "-",
        }
      })


      let incubationDetails:any = response.data?.incubation_details?.map((element: any, index: number) => {
        return {
          incubationAreaName: element.incubator_name || "-",
          incubatedBy : element.created_by_first_name + " " + element.created_by_last_name || "-",
          incubationDateTime : element.start_date_time || "-",
          incubationOutBy : element.updated_by_first_name + " " + element.updated_by_last_name || "-",
          incubationoutDateTime : element.end_date_time || "-",
        }
      })

      let labTestDetails:any = response.data?.lab_test_details?.map((element: any, index: number) => {
        return {
          conductedBy: element.completed_by_user_firstname + " " + element.completed_by_user_lastname || "-",
          dateAndTime : element.completed_date_time || "-",
          colonyCount : element.colony_count || "-",
          imageId : element.plate_image_id || "",
          id: element.id || "-",
          species: element.species || "-",
          genus: element.genus || "-",
        }
      })


      setSinglePlateDetails(basicDetails)
      setExposureDetails(exposureDetails)
      setFingureDabDetails(fingureDabDetails)
      setPlateSamplingDetails(plateSamplingDetails)
      setRawMaterialDetails(rawMaterialDetails)
      setIncubationDetails(incubationDetails)
      setLabTestDetails(labTestDetails)
      setSurfaceMonitoringDetails(surfaceMonitoringDetails)
    }
  }


  const handleNextClick = (currentIndex : number) => {
    if (currentIndex === 1){
      if (currentExposureIndex < exposureDetails.length - 1) {
        setCurrentExposureIndex(currentExposureIndex + 1);
      }
    } else if (currentIndex === 2) {
      if (currentIncubationIndex < incubationDetails.length - 1) {
        setCurrentIncubationIndex(currentIncubationIndex + 1);
      }
    } else if (currentIndex === 3) {
      if (currentLabTestIndex < labTestDetails.length - 1) {
        setCurrentLabTestIndex(currentLabTestIndex + 1);
      }
    } else if (currentIndex === 4) {
      if (currentFingerDabIndex < fingureDabDetails.length - 1) {
        setCurrentFingerDabIndex(currentFingerDabIndex + 1);
      }
    } else if (currentIndex === 5) {
      if (currentSamplingIndex < plateSamplingDetails.length - 1) {
        setCurrentSamplingIndex(currentSamplingIndex + 1);
      }
    } else if (currentIndex === 6) {
      if (currentRawmaterialTestIndex < rawMaterialDetails.length - 1) {
        setCurrentRawmaterialTestIndex(currentRawmaterialTestIndex + 1);
      }
    } else if (currentIndex === 7) {
      if (currentSurfaceMonitoringIndex < surfaceMonitoringDetails.length - 1) {
        setCurrentSurfaceMonitoringIndex(currentSurfaceMonitoringIndex + 1);
      }
    }
  };

  const handlePreviousClick = (currentIndex : number) => {
    if (currentIndex === 1){
      if (currentExposureIndex > 0) {
        setCurrentExposureIndex(currentExposureIndex - 1);
      }
    } else if (currentIndex === 2) {
      if (currentIncubationIndex > 0) {
        setCurrentIncubationIndex(currentIncubationIndex - 1);
      }
    } else if (currentIndex === 3) {
      if (currentLabTestIndex > 0) {
        setCurrentLabTestIndex(currentLabTestIndex - 1);
      }
    } else if (currentIndex === 4) {
      if (currentFingerDabIndex > 0) {
        setCurrentFingerDabIndex(currentFingerDabIndex - 1);
      }
    } else if (currentIndex === 5) {
      if (currentSamplingIndex > 0) {
        setCurrentSamplingIndex(currentSamplingIndex - 1);
      }
    } else if (currentIndex === 6) {
      if (currentRawmaterialTestIndex > 0) {
        setCurrentRawmaterialTestIndex(currentRawmaterialTestIndex - 1);
      }
    } else if (currentIndex === 7) {
      if (currentSurfaceMonitoringIndex > 0) {
        setCurrentSurfaceMonitoringIndex(currentSurfaceMonitoringIndex - 1);
      }
    }
  };

  const handleClose = () => {
    setShowView(false)
    setSinglePlateDetails({})
    setExposureDetails([])
    setFingureDabDetails([])
    setPlateSamplingDetails([])
    setRawMaterialDetails([])
    setIncubationDetails([])
    setLabTestDetails([])
  };


  useEffect(() => {
    if (showView) {
        scanPlateById(plateId);
    }

  }, [showView, plateId]);

   
    

  return (
    <Modal className="view-modal" show={showView} size="lg">
        <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title className="view-header">View</Modal.Title>
        </Modal.Header>
        <Modal.Body className="view-body">
            <div className="view-body-content">
                <p className="plate-details">Plate Details</p>
                <div className="view-body-details">
                    <div className="row">
                        <div className="col-md-2">
                            <p className="plate-detail-key">Plate Serial No</p>
                            <p className="plate-detail-value">{singlePlateDetails?.serial_number}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="plate-detail-key">Batch Id</p>
                            <p className="plate-detail-value">{singlePlateDetails?.batch_number}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="plate-detail-key">Plate Manufacturing Date</p>
                            <p className="plate-detail-value">{singlePlateDetails?.manufacturing_date}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="plate-detail-key">Plate Expiry date</p>
                            <p className="plate-detail-value">{singlePlateDetails?.expiry_date}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="plate-detail-key">Activity Type</p>
                            <p className="plate-detail-value">{singlePlateDetails?.plate_type}</p>
                        </div>
                        {/* <div className="col-md-2">
                            <p className="plate-detail-key">Verified By</p>
                            <p className="plate-detail-value">QA1 & QA2</p>
                        </div>                            */}
                    </div>
                </div>
            </div>
            <div className="view-lab-details">
                <div className="view-lab-details-content">
                    <div className="row">
                        {exposureDetails && singlePlateDetails?.plate_type === "Exposure Plate" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Exposer Details</p>
                                        <div>
                                            <button 
                                            className={currentExposureIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(1)} 
                                            disabled={currentExposureIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentExposureIndex === exposureDetails?.length - 1 || exposureDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(1)} 
                                            disabled={currentExposureIndex === exposureDetails.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1">
                                                                
                                    <p className="plate-detail-key">Exposed Area Name</p>
                                    <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.exposedAreaName || "-"}</p>                             
                                
                                    <p className="plate-detail-key">Exposed By</p>
                                    <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.exposedBy || "-"}</p>
                                                            
                                    <p className="plate-detail-key">Exposed Date & Time</p>
                                    <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.exposedDate || "-"}</p>

                                    <p className="plate-detail-key">Collected By</p>
                                    <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.collectedBy || "-"}</p>

                                    <p className="plate-detail-key">Collected Date & Time</p>
                                    <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.collectedDate || "-"}</p>                                                     
                                </div>    
                            </div>
                        )}
                        {fingureDabDetails && singlePlateDetails?.plate_type === "Finger DAB Plate" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Finger Dab Details</p>
                                        <div>
                                            <button 
                                            className={currentFingerDabIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(4)} 
                                            disabled={currentFingerDabIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentFingerDabIndex === fingureDabDetails?.length - 1 || fingureDabDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(4)} 
                                            disabled={currentFingerDabIndex === fingureDabDetails.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1">
                                                                
                                    <p className="plate-detail-key">FingerDab Area Name</p>
                                    <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.fingerDabAreaName || "-"}</p>                             
                                
                                    <p className="plate-detail-key">FingerDabbed By</p>
                                    <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.fingerDabbededBy || "-"}</p>
                                                            
                                    <p className="plate-detail-key">FingerDabbed Date & Time</p>
                                    <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.dabbedDate || "-"}</p>

                                    <p className="plate-detail-key">FingerDab Started By</p>
                                    <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.fingerDabType || "-"}</p>

                                    <p className="plate-detail-key">FingerDab Option</p>
                                    <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.figerDabOption || "-"}</p>

                                
                                </div>    
                            </div>
                        )}
                        {plateSamplingDetails && singlePlateDetails?.plate_type === "Sampling Plate" &&(
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Sampling Details</p>
                                        <div>
                                            <button 
                                            className={currentSamplingIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(5)} 
                                            disabled={currentSamplingIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentSamplingIndex === plateSamplingDetails?.length - 1 || plateSamplingDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(5)} 
                                            disabled={currentSamplingIndex === plateSamplingDetails.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1">
                                                                
                                    <p className="plate-detail-key">Plate Sampling Type</p>
                                    <p className="plate-detail-value">{plateSamplingDetails[currentSamplingIndex]?.plateSamplingType || "-"}</p>                             
                                
                                    <p className="plate-detail-key">Plate Sampled By</p>
                                    <p className="plate-detail-value">{plateSamplingDetails[currentSamplingIndex]?.sampledBy || "-"}</p>
                                                            
                                    <p className="plate-detail-key">Plate Sampled Date & Time</p>
                                    <p className="plate-detail-value">{plateSamplingDetails[currentSamplingIndex]?.sampledDate || "-"}</p>

                                    {/* <p className="plate-detail-key">Collected By</p>
                                    <p className="plate-detail-value">{plateSamplingDetails[currentFingerDabIndex]?.collectedBy || "-"}</p> */}

                                    {/* <p className="plate-detail-key">Collected Date & Time</p>
                                    <p className="plate-detail-value">{plateSamplingDetails[currentFingerDabIndex]?.collectedDate || "-"}</p>                                                      */}
                                </div>    
                            </div>
                        )}
                        {rawMaterialDetails && singlePlateDetails?.plate_type === "Material Test Plate" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Material Test Details</p>
                                        <div>
                                            <button 
                                            className={currentRawmaterialTestIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(6)} 
                                            disabled={currentRawmaterialTestIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentRawmaterialTestIndex === rawMaterialDetails?.length - 1 || rawMaterialDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(6)} 
                                            disabled={currentRawmaterialTestIndex === rawMaterialDetails.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1">
                                                                
                                    <p className="plate-detail-key">Raw Material Name</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentRawmaterialTestIndex]?.rawMaterialTypeName || "-"}</p>                             
                                
                                    <p className="plate-detail-key">Raw Material Test Conducted By</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentRawmaterialTestIndex]?.conductedBy || "-"}</p>
                                                            
                                    <p className="plate-detail-key">Raw Material Test Date & Time</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentRawmaterialTestIndex]?.testDate || "-"}</p>

                                    {/* <p className="plate-detail-key">Collected By</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentFingerDabIndex]?.collectedBy || "-"}</p> */}

                                    {/* <p className="plate-detail-key">Collected Date & Time</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentFingerDabIndex]?.collectedDate || "-"}</p>                                                      */}
                                </div>    
                            </div>
                        )}
                         {surfaceMonitoringDetails && singlePlateDetails?.plate_type === "Surface Monitoring Plate" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Surface Monitoring</p>
                                        <div>
                                            <button 
                                            className={currentSurfaceMonitoringIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(7)} 
                                            disabled={currentSurfaceMonitoringIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentSurfaceMonitoringIndex === surfaceMonitoringDetails?.length - 1 || rawMaterialDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(7)} 
                                            disabled={currentSurfaceMonitoringIndex === surfaceMonitoringDetails.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1">
                                                                
                                    <p className="plate-detail-key">Surface Monitoring Area Name</p>
                                    <p className="plate-detail-value">{surfaceMonitoringDetails[currentSurfaceMonitoringIndex]?.surfaceMonitoringAreaName || "-"}</p>                             
                                
                                    <p className="plate-detail-key">Surface Monitoring Start Date</p>
                                    <p className="plate-detail-value">{surfaceMonitoringDetails[currentSurfaceMonitoringIndex]?.surfaceMonitoringStartDate || "-"}</p>
                                                            
                                    {/* <p className="plate-detail-key">Raw Material Test Date & Time</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentRawmaterialTestIndex]?.testDate || "-"}</p> */}

                                    {/* <p className="plate-detail-key">Collected By</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentFingerDabIndex]?.collectedBy || "-"}</p> */}

                                    {/* <p className="plate-detail-key">Collected Date & Time</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentFingerDabIndex]?.collectedDate || "-"}</p>                                                      */}
                                </div>    
                            </div>
                        )}
                        <div className="col-md-3">
                            <div className="d-flex justify-content-between">
                                    <p className="lab-detail-key">Incubation Details</p>
                                    <div>
                                        <button 
                                        className={currentIncubationIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                        onClick={() => handlePreviousClick(2)} 
                                        disabled={currentIncubationIndex === 0}>
                                            <BsChevronDoubleLeft />
                                        </button>
                                        <button 
                                        className={currentIncubationIndex === incubationDetails.length - 1 || incubationDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                        onClick={() => handleNextClick(2)} 
                                        disabled={currentIncubationIndex === incubationDetails.length - 1}>
                                            <BsChevronDoubleRight />
                                        </button>
                                    </div>
                                </div> 
                            <div className="detail-col-1">
                                <p className="plate-detail-key">Incubation Area Name</p>
                                <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubationAreaName || "-"}</p>                             
                            
                                <p className="plate-detail-key">Incubated By</p>
                                <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubatedBy || "-"}</p>
                                                        
                                <p className="plate-detail-key">Incubation Date & Time</p>
                                <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubationDateTime || "-"}</p>

                                <p className="plate-detail-key">Incubation Stop</p>
                                <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubationOutBy || "-"}</p>

                                <p className="plate-detail-key">Incubation out Date & Time</p>
                                <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubationoutDateTime || "-"}</p>             
                            </div>                           
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-between">
                                    <p className="lab-detail-key">Lab Details</p>
                                    <div>
                                        <button 
                                        className={currentLabTestIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                        title='Click here to view Details'
                                        onClick={() => handlePreviousClick(3)} 
                                        disabled={currentLabTestIndex === 0}>
                                            <BsChevronDoubleLeft />
                                        </button>
                                        <button 
                                        className={currentLabTestIndex === labTestDetails.length - 1 || labTestDetails.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'}
                                        title='Click here to view Details' 
                                        onClick={() => handleNextClick(3)} 
                                        disabled={currentLabTestIndex === labTestDetails.length - 1}>
                                            <BsChevronDoubleRight />
                                        </button>
                                    </div>
                                </div>
                            <div className="detail-col-3">
                            <div className="row">
                                <div className="col-md-4">
                                    <p className="plate-detail-key">Lab Test Conducted by</p>
                                    <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.conductedBy || "-"}</p>                             
                                </div>
                                <div className="col-md-4">
                                    <p className="plate-detail-key">Lab Test Date & Time</p>
                                    <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.dateAndTime || "-"}</p>                             
                                </div>
                                <div className="col-md-4">
                                    <p className="plate-detail-key">Colony Count</p>
                                    <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.colonyCount || "-"}</p>                             
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <p className="plate-detail-key">Species</p>
                                    <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.species || "-"}</p>                             
                                </div>
                                <div className="col-md-4">
                                    <p className="plate-detail-key">Genus</p>
                                    <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.genus || "-"}</p>                             
                                </div>
                            </div>
                            {labTestDetails[currentLabTestIndex]?.imageId && (
                                <>
                                <p className="plate-detail-key">Image</p>
                                <div className='view-image-count-wrap'>
                                    <ImageSlider baseUrl={baseUrl} imageIds={labTestDetails[currentLabTestIndex]?.imageId || ""} />
                                </div>
                                </>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer">
        <Button className="footerCancelBtn" variant="tertiory" onClick={handleClose}>
        Cancel
        </Button>              
        <div>
        {showApproveRejectBtn && (
            <>
                <Button className="footerApproveBtn" onClick={() => setShowApproveAlert && setShowApproveAlert(true)}>
                    Approve
                </Button>
                <Button className="footerRejectBtn" onClick={() => setShowRejectAlert && setShowRejectAlert(true)}>
                    Reject
                </Button>
            </>
        )}
        </div>
        </Modal.Footer>
    </Modal>
  )
}

export default PlateDetailsViewModel;