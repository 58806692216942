import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import translationData from '../../../../locales/dashboardTranslation.json'
import { historicalServices } from "../../../../service/historicalDashboard";

interface IncubationDetailsLayoutProps {
  heading: string;
  selectedDate: any;
}

const columnStyle = {
  margin: "0 0 6px 0",
};

const lastColumnStyle = {
  margin: "0",
};
const IncubationDetailsLayout: React.FC<IncubationDetailsLayoutProps> = ({
  heading,
  selectedDate
}) => {
  const navigate = useNavigate();

  interface ColumnData {
    id: number;
    apiName: string;
    displayName: string;
    count: number;
  }
  const [columnsData, setColumnsData] = useState<ColumnData[]>([]);

  const navigateToOtherPage = () => {
    navigate('/incubation-history-table'); 
  };

  const switchToPage = (id: number) => {
  }

  const getIncubationStatus = async () => {
    try {
      const response = await historicalServices.getIncubationStatus(selectedDate.startDate, selectedDate.endDate, selectedDate.building,selectedDate.plant,selectedDate.unit,selectedDate.parentArea);
      if (response.status && response.statusCode === 200) {
        try {
          const responseData = response.data;
          if (responseData) {
            setColumnsData(prevColumnsData => {
              return prevColumnsData.map(column => {
                const { apiName, displayName } = column;

                const apiCount = responseData[apiName];

                if (apiCount !== undefined) {
                  return { ...column, count: apiCount };
                } else {
                  console.warn(`API response for ${displayName} is undefined or missing.`);
                  return column;
                }
              });
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error('user response', error);
    }
  };

  useEffect(() => {
    const IncubationDataHead = translationData.HistoryIncubationHead;
    setColumnsData(IncubationDataHead.map((item => ({ ...item, count: 0 }))));
    }, []); 
    
  useEffect(() => {
    getIncubationStatus();
  }, [selectedDate])

  const getColumnNameStyle = (column: any) => {
    if (column.displayName.includes('Overdue plates for Collection')) {
      return 'column-style redText';
    } else if (column.displayName.includes('Plates Collected, but Overdue')) {
      return 'column-style yellowText';
    }else {
      return 'column-style';
    }
  }
  return (
    <div className="container">
      <h2 className="head-style" title="Click here to View Incubation Details" onClick={navigateToOtherPage}>
        {heading}
      </h2>
      <div className="columns" style={{ padding: "10px" }}>
         {columnsData.map((column) => (
          <div key={column.id} className="column" style={column.id === columnsData.length ? lastColumnStyle : columnStyle}>
          <div className="linkStyle" onClick={() => switchToPage(column.id)}>
          <span className={getColumnNameStyle(column)}>
            {column.displayName}
          </span>
          <span className="countStyle">{column.count}</span>
          </div>
          </div>
      ))}
      </div>
    </div>
  );
};

export default IncubationDetailsLayout;
